import gcIcon from './static/images/icons/game-coin.svg'
import scIcon from './static/images/icons/sweeps-coin.svg'
import h5cIcon from './static/images/icons/h-5-c-coin.svg'
import diamondIcon from './static/images/icons/diamond.svg'

class H5CurrencyElement extends HTMLElement {
  constructor(src) {
    super()
    this.attachShadow({ mode: 'open' })
    this.shadowRoot.innerHTML = `
      <style>
        :host {
          display: flex;
        }
      </style>
    `
    const image = document.createElement('img')
    this._size = this.getAttribute('size') || '24' // Set a default if not provided
    image.setAttribute('width', this._size)
    image.setAttribute('height', this._size)
    image.setAttribute('src', src)
    this.shadowRoot.append(image)
  }

  static get observedAttributes() {
    return ['size']
  }

  attributeChangedCallback(name, oldValue, newValue) {
    if (name === 'size') {
      this._size = newValue || '24' // Set a default if not provided
      const img = this.shadowRoot.querySelector('img')
      if (img) {
        img.setAttribute('width', this._size)
        img.setAttribute('height', this._size)
      }
    }
  }
}

class GcElement extends H5CurrencyElement {
  constructor() {
    super(gcIcon)
  }
}

class ScElement extends H5CurrencyElement {
  constructor() {
    super(scIcon)
  }
}

class DiaElement extends H5CurrencyElement {
  constructor() {
    super(diamondIcon)
  }
}

class H5cElement extends H5CurrencyElement {
  constructor() {
    super(h5cIcon)
  }
}

const defineCustomElements = () => {
  customElements.define('icon-gc', GcElement)
  customElements.define('icon-sc', ScElement)
  customElements.define('icon-dia', DiaElement)
  customElements.define('icon-h5c', H5cElement)
}

export default defineCustomElements
