import React, { createContext, useContext, useEffect, useMemo, useRef, useState } from 'react'
import { config } from '../../../components/Init/Init'
import HttpStatus from 'http-status-codes'

const ImageExistContext = createContext()

export const useImageExist = () => useContext(ImageExistContext)

export const ImageExistProvider = ({ showNonExistentImagesSrcDelayMs, showNonExistentImagesSrc, children }) => {
  /**
   * @desc verifiedImageSources is key-value store of verified images urls
   * @property {string} key - string path to image
   * @property {boolean} value - is image exit or not
   */
  const verifiedImageSources = useRef({})

  /**
   * @desc array of not verified images urls
   * @property {string} url - string path to image
   */
  const imagesErrorData = useRef([])

  /**
   * @desc flag indicating whether the error log was shown
   * @property {boolean} - string path to image
   */
  const isLogOnHold = useRef(true)

  const [isGameIconVariantsLoading, setIsGameIconVariantsLoading] = useState(true)
  const [gameIconVariants, setGameIconVariants] = useState(null)

  useEffect(() => {
    fetch(config.gameIconVariantsPath, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json'
      }
    })
      .then(response => {
        if (response.status !== HttpStatus.NOT_FOUND) {
          response.json().then(res => {
            setGameIconVariants(res)
          })
        }
      })
      .catch(e => {
        console.log('[Sweeps] Inside game icon variants catch', e)
      })
      .finally(() => {
        setIsGameIconVariantsLoading(false)
      })
  }, [])

  let timerId

  /**
   * @private _getImageBySource
   * @desc Return an image object
   * @param {Object} arguments - the method arguments
   * @param {string} arguments.imageSrc - string path to image
   * @param {function} [arguments.onLoad] - onload callback
   * @param {function} [arguments.onError] - onerror callback
   */
  const _getImageBySource = ({ imageSrc, onLoad, onError }) => {
    const handleOnLoad = (msg, imageSrc) => {
      onLoad && onLoad({ msg, imageSrc })
    }

    const handleOnError = (msg, imageSrc) => {
      onError && onError({ msg, imageSrc })
    }

    const img = new Image()
    img.src = imageSrc
    img.onload = msg => handleOnLoad(msg, imageSrc)
    img.onerror = msg => handleOnError(msg, imageSrc)

    return img
  }

  const _handleImageNonExistErrors = ({ imageSrc }) => {
    imagesErrorData.current.push(imageSrc)

    _showNonExistImageErrors()
  }

  const _showNonExistImageErrors = () => {
    if (showNonExistentImagesSrc && imagesErrorData.current.length !== 0 && isLogOnHold.current) {
      // to prevent enter into this method on each image checking
      isLogOnHold.current = false

      timerId = setTimeout(() => {
        console.warn('[ImageExistProvider] Images failed to load:', imagesErrorData.current)

        // reset errors data after show
        imagesErrorData.current = []
        isLogOnHold.current = true
        clearTimeout(timerId)
      }, showNonExistentImagesSrcDelayMs)
    }
  }

  const isImageExist = (imageSrc, gameId, gameIconVariantType = null) => {
    // remove " 2x" from end of image src
    const src = imageSrc.split(' ')[0]

    const gameIconSupportsType = useMemo(() => {
      if (!gameIconVariantType) {
        // this is default icon and always supported
        return true
      } else if (isGameIconVariantsLoading) {
        // we don't know yet, assume false for now
        return false
      } else if (!gameIconVariants) {
        // we don't have a variants json, just assume all are supported (we'll get lots of 404s but otherwise it's fine)
        return true
      } else {
        // check if the game ID in question includes the variant type specified
        if (gameIconVariants[gameId]) {
          return !!gameIconVariants[gameId][gameIconVariantType] // !! used to return boolean (from undefined)
        } else {
          return false
        }
      }
    }, [isGameIconVariantsLoading, gameIconVariants, gameId, gameIconVariantType])

    if (gameIconSupportsType && !verifiedImageSources.current.hasOwnProperty(src)) {
      verifiedImageSources.current[src] = _getImageBySource({
        imageSrc: src,
        onError: _handleImageNonExistErrors
      })
    }

    return verifiedImageSources.current[src] && verifiedImageSources.current[src].width !== 0
  }

  return (
    <ImageExistContext.Provider value={{ isImageExist, verifiedImageSources }}>{children}</ImageExistContext.Provider>
  )
}
