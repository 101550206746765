import _ from 'lodash'

/**
 * timerReducer
 *
 * @desc
 *  A reducer that computes timer state
 *
 */
const timerActions = {
    addTimer: function(state, action) {
      let now = Date.now()
      let timers = state.timers
      for (var key in action.timers) {
        let timer = action.timers[key]
        if (timer) {
          /**
           * initialize new timers
           * @prop {number} delta   - The offset from login to set the trigger
           * @prop {number} trigger - The time to perform the requested action
           */
          if (_.has(timer, 'delta') && !timer.initialized) {
            timer.trigger = now + timer.delta
            timer.initialized = true
          }
          if (!timers) {
            timers = {}
          }
          timers[timer.name] = timer
        }
      }
      return {
        ...state,
        timers: timers
      }
    },
    removeTimer: function(state, action) {
      let name = action.name
      let timers = state.timers
      if (_.has(timers, name)) {
        if (timers[name].verifyExpired) {
          // only delete this timer if it's expired (to protect against it
          // getting replaced by a new one with the same name while mid-delete)
          if (timers[name].trigger < Date.now()) {
            delete timers[name]
          }
        } else {
          delete timers[name]
        }
      }

      return {
        ...state,
        timers: timers
      }
    }
  },
  timerReducer = (state, action) => {
    if (_.has(timerActions, action.type)) {
      return timerActions[action.type](state, action)
    } else {
      return state
    }
  }

export default timerReducer
