import orderBy from 'lodash/orderBy'
import { isNotificationExpired, isNotificationLimit, isStatusNotification } from '../../services/utils'
import { getPlayerStatus } from '../../services/helpers/playerHelper'
import { config } from '../../components/Init/Init'
import * as Sentry from '@sentry/browser'
import { useStateValue } from '../../services/state/StateProvider'
import { useCallback } from 'react'

export const useShowNotification = removeNotification => {
  const [{ player }, dispatch] = useStateValue()

  const setActiveNotificationId = useCallback(
    activeNotificationId => {
      dispatch({
        type: 'setActiveNotificationId',
        casino: {
          activeNotificationId: activeNotificationId
        }
      })
    },
    [dispatch]
  )

  const showNotificationPopup = useCallback(
    queue => {
      if (!queue) {
        queue = []
      }

      dispatch({
        type: 'storeNotices',
        player: {
          notices: queue
        }
      })

      if (queue.length > 0) {
        try {
          const sortedQueue = orderBy(queue, ['priority'], ['desc'])
          let limitReached = isNotificationLimit(sortedQueue[0])

          if (getPlayerStatus(player) === 'logged-in' && !limitReached) {
            if (isNotificationExpired(sortedQueue[0])) {
              // Remove expired notification from queue
              if (config.enableRemoveNotice) {
                removeNotification(sortedQueue[0].id)
                setActiveNotificationId(null)
              }
            } else if (isStatusNotification(sortedQueue[0])) {
              setActiveNotificationId(sortedQueue[0].id)

              dispatch({
                type: 'setModal',
                modals: {
                  messagePopper: {
                    display: true,
                    data: sortedQueue[0]
                  }
                }
              })
            } else {
              setActiveNotificationId(sortedQueue[0].id)

              dispatch({
                type: 'setModal',
                modals: {
                  popup: {
                    display: true,
                    data: sortedQueue[0]
                  }
                }
              })
            }
          } else if (limitReached) {
            dispatch({
              type: 'triggerLimit',
              player: {
                limitReached: sortedQueue[0].context.limitType,
                limitNotice: sortedQueue[0]
              }
            })
            // delete skipped message
            // TODO: remove notice from local storage
            // eslint-disable-next-line no-unused-vars
            removeNotification(sortedQueue[0].id)
            setActiveNotificationId(null)
          }
        } catch (e) {
          console.log('[Casino] There was an error processing notification popup.', e, queue)
          if (process.env.REACT_APP_ENABLE_SENTRY === 'true') {
            Sentry.captureException(e)
          }
        }
      }
    },
    [dispatch, player, removeNotification, setActiveNotificationId]
  )

  return { showNotificationPopup }
}
