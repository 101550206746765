import { createContext, useContext, useMemo, useState } from 'react'

import {
  usePlayerPIIClearData,
  usePlayerPIIUpdateSmsState,
  usePlayerPIIUpdatePIIDataState,
  usePlayerPIIUpdateDataRequired,
  usePlayerPIIFetchAccountDetails,
  usePlayerPIIUpdateSmsVerificationIncentivized
} from './hooks'

const PlayerPIIContext = createContext()

export const usePlayerPII = () => useContext(PlayerPIIContext)

export const PlayerPIIProvider = ({ children }) => {
  const [piiDataRequired, setPiiDataRequired] = useState(false)
  const [piiDataRequiredChecked, setPiiDataRequiredChecked] = useState(false)
  const [smsVerificationIncentivized, setSmsVerificationIncentivized] = useState(false)

  usePlayerPIIClearData()

  const { playerPIIDataLoading } = usePlayerPIIFetchAccountDetails({ piiDataRequired, piiDataRequiredChecked })

  usePlayerPIIUpdateSmsState({ playerPIIDataLoading })

  usePlayerPIIUpdateSmsVerificationIncentivized({
    setSmsVerificationIncentivized
  })

  const { updatePiiDataRequired } = usePlayerPIIUpdateDataRequired({ setPiiDataRequired, setPiiDataRequiredChecked })

  const { piiDataPopupShow, smsVerificationShow, lockedSmsVerificationShow } = usePlayerPIIUpdatePIIDataState({
    piiDataRequired,
    piiDataRequiredChecked
  })

  const playerPIIProviderValues = useMemo(
    () => ({
      piiDataPopupShow,
      smsVerificationShow,
      updatePiiDataRequired,
      piiDataRequiredChecked,
      lockedSmsVerificationShow,
      smsVerificationIncentivized
    }),
    [
      piiDataPopupShow,
      smsVerificationShow,
      updatePiiDataRequired,
      piiDataRequiredChecked,
      lockedSmsVerificationShow,
      smsVerificationIncentivized
    ]
  )

  return <PlayerPIIContext.Provider value={playerPIIProviderValues}>{children}</PlayerPIIContext.Provider>
}
