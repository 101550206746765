import { useCallback, useEffect } from 'react'
import { useStateValue } from '../../../state/StateProvider'
import { useGet } from '../../../../hooks/apiHooks'
import { setSmsVerified } from '../../../../sweepstakes/messages'
import { useFirebase } from '../../FirebaseProvider'
import { config } from '../../../../components/Init/Init'
import { tokenizer } from '../../../utils'

export const usePlayerPIIUpdateSmsState = ({ playerPIIDataLoading }) => {
  const [{ player }, dispatch] = useStateValue()
  const { firebaseSmsInfo } = useFirebase()
  const { GET } = useGet()

  const updatePlayerSMSInfo = useCallback(
    ({ smsPlayerVerified, lockedBySmsVerification }) => {
      dispatch({
        type: 'smsVerification',
        player: {
          smsInfo: {
            smsVerified: smsPlayerVerified,
            lockedSmsVerified: lockedBySmsVerification,
            smsInfoChecked: true
          }
        }
      })

      if (smsPlayerVerified) {
        setSmsVerified()
      }

      console.log(
        '[Casino] SMS info checked settings: SUCCESS ' +
          JSON.stringify({
            smsVerified: smsPlayerVerified,
            lockedSmsVerified: lockedBySmsVerification,
            smsInfoChecked: true
          })
      )
    },
    [dispatch]
  )

  useEffect(() => {
    if (player && player.loginUser && player.loginUser.userId) {
      if (!playerPIIDataLoading) {
        if (firebaseSmsInfo) {
          console.log('[Casino] Set SMS verified from Firebase:', firebaseSmsInfo)

          if (typeof firebaseSmsInfo.smsVerified !== 'undefined') {
            updatePlayerSMSInfo({
              smsPlayerVerified: firebaseSmsInfo.smsVerified,
              lockedBySmsVerification: firebaseSmsInfo.lockedSmsVerified
            })
          }
        } else {
          const userInfoUrl =
            config.apiUrl +
            tokenizer(config.getUserInfoUrl, {
              '{userId}': player.loginUser.userId
            })

          GET(userInfoUrl)
            .then(data => {
              if (data) {
                console.log('[Casino] Set SMS verified from user info:', data)

                if (typeof data.smsVerified !== 'undefined') {
                  updatePlayerSMSInfo({
                    smsPlayerVerified: data.smsVerified,
                    lockedBySmsVerification: data.lockedBySmsVerification
                  })
                }
              }
            })
            .catch(error => console.log('error', error))
        }
      }
    } else {
      console.log('[Casino] SMS info checked settings: FAILED, player: ' + JSON.stringify(player) + ' not authorized')
    }
  }, [firebaseSmsInfo, updatePlayerSMSInfo, player.loginUser.userId, playerPIIDataLoading])
}
