import { config } from '../components/Init/Init'

const sendMessage = (msg, redirectToLoginIfApplicable = false, redirectParam = null) => {
  if (config.enableSweepstakes) {
    if (window.top !== window.self) {
      window.parent.postMessage(msg, config.socialOrigin)
    } else if (redirectToLoginIfApplicable) {
      let url = config.loginPageUrl
      if (redirectParam) {
        if (url.indexOf('?') > 0) {
          url = url + '&' + redirectParam
        } else {
          url = url + '?' + redirectParam
        }
      }
      window.location.replace(url)
    }
  }
}

// https://high5games.atlassian.net/wiki/spaces/GD/pages/5374969/Sweepstakes+Post+Message+Events
const login = () => sendMessage({ name: 'LogIn' }, true, 'openLoginOnInit=1')
const logout = () => sendMessage({ name: 'LogOut' })
const register = () => sendMessage({ name: 'Register' }, true, 'openRegOnInit=1')
const showStore = () => {
  // note: make sure to call verifyPiiAndSmsThenCallFunction before calling this method
  sendMessage({ name: 'ShowStore' })
}
const hideStore = () => {
  sendMessage({ name: 'HideStore' })
}
const showSocial = () => sendMessage({ name: 'ShowSocial' })
const ready = maintenanceMode => sendMessage({ name: 'SweepsReady', maintenance: maintenanceMode })
const pageLoaded = () => sendMessage({ name: 'SweepsLoaded' })
const loginResponse = error => sendMessage({ name: 'SweepsLoginResponse', error })
const cookiesAccepted = accepted =>
  sendMessage({ name: 'CookiesAccepted', value: accepted === true || accepted === 'true' || accepted === 1 })
const updateSocialBankroll = () => sendMessage({ name: 'UpdateSocialBankroll' })
const requestCameraPermission = () => sendMessage({ name: 'RequestCameraPermission' })
const refreshSocial = () => sendMessage({ name: 'RefreshSocial' })
const disconnect = () => sendMessage({ name: 'Disconnect' })
const redirectSweeps = url => sendMessage({ name: 'Redirect', path: url })
const setMerchantId = () => sendMessage({ name: 'SetSweepsMerchantId', mid: config.devcode.merchantId })
const setSmsVerified = () => sendMessage({ name: 'SetSmsVerified' })
const setPiiCountryCode = countryCode => sendMessage({ name: 'SetPiiCountryCode', countryCode: countryCode })
const openDirectPurchase = uuid => sendMessage({ name: 'OpenDirectPurchase', uuid: uuid })
const refreshStore = () => sendMessage({ name: 'RefreshStore' })
const reloadPage = () => sendMessage({ name: 'ReloadPage' })

export {
  login,
  logout,
  register,
  showStore,
  hideStore,
  showSocial,
  ready,
  pageLoaded,
  loginResponse,
  cookiesAccepted,
  updateSocialBankroll,
  requestCameraPermission,
  refreshSocial,
  disconnect,
  redirectSweeps,
  setMerchantId,
  setSmsVerified,
  setPiiCountryCode,
  refreshStore,
  openDirectPurchase,
  reloadPage
}
