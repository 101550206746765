import { useEffect } from 'react'
import { useStateValue } from '../../../state/StateProvider'

export const usePlayerPIIClearData = () => {
  const [{ player }, dispatch] = useStateValue()

  useEffect(() => {
    // we want to clear our smsInfoChecked state on page load
    if (player.smsInfo) {
      console.log('[Casino] Clear SMS info checked')
      dispatch({
        type: 'smsVerification',
        player: {
          smsInfo: {
            smsInfoChecked: false
          }
        }
      })
      console.log('[Casino] SMS info clear sent')

      // we want to clear our smsVerifiedFunc on page load, if we had one pending
      if (Boolean(player.smsInfo.smsVerifiedFunc)) {
        console.log('[Casino] Clear SMS verified function')
        dispatch({
          type: 'smsVerificationFunc',
          player: {
            smsInfo: {
              smsVerifiedFunc: null
            }
          }
        })
      }
    }
  }, [])
}
