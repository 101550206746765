import HttpStatus from 'http-status-codes'
import _ from 'lodash'
import { config } from '../components/Init/Init'
import firebase from 'firebase/compat/app'
import * as Sentry from '@sentry/browser'

import { logFirebaseRewardCollected } from './FirebaseEvents'

// Since we need to claim the reward when popup is closed with standard close button,
// redeemRewardNotification can be called twice.
// To avoid multiple requests to server, last claimed reward Id will be checked to skip claiming.
let lastRewardId = undefined

const redeemRewardNotification = (
  player,
  notificationId,
  rewardId,
  callback = null,
  claimCenter = false,
  inbox = false
) => {
  const removeNotification = (notificationId, moveToFailed) => {
      console.log('[Sweeps] Removing notification with notification ID ' + notificationId)
      let removeNotification = firebase.functions().httpsCallable('removeNotification')
      return removeNotification({
        playerId: player.loginUser.userId,
        notificationId: notificationId,
        firebaseDb: config.firebaseDb,
        moveToFailed: moveToFailed
      }).catch(err => {
        console.log('[Sweeps] Error while removing notification: ', err)
        if (process.env.REACT_APP_ENABLE_SENTRY === 'true') {
          Sentry.captureException(err)
        }
      })
    },
    removeClaimCenter = notificationId => {
      console.log('[Sweeps] Removing claim center item with notification ID ' + notificationId)
      let removeClaimCenter = firebase.functions().httpsCallable('removeClaimCenter')
      return removeClaimCenter({
        playerId: player.loginUser.userId,
        notificationId: notificationId,
        firebaseDb: config.firebaseDb
      }).catch(err => {
        console.log('[Sweeps] Error while removing claim center notification: ', err)
        if (process.env.REACT_APP_ENABLE_SENTRY === 'true') {
          Sentry.captureException(err)
        }
      })
    }

  let claimed = false
  const callCallback = () => {
    // if there's a callback, call it
    if (callback !== null) {
      callback(claimed)

      // set it to null, so it can't be called multiple times
      callback = null
    }
  }

  if (
    config.enableSweepstakes &&
    player.status === config.status.registered &&
    !_.isEmpty(player.socialData) &&
    (lastRewardId !== rewardId || claimCenter || inbox)
  ) {
    console.log('[Sweeps] Requesting for reward ' + rewardId + ' with notification ID ' + notificationId)

    let removed = false
    let canRemove =
      player.status === config.status.registered && config.enableRemoveNotice && notificationId !== undefined
    // Set lastRewardId before fetching as it will take time to fetch
    lastRewardId = rewardId

    if (process.env.REACT_APP_ENABLE_SENTRY === 'true') {
      const appToken = _.get(player, 'socialData.applicationToken')
      Sentry.addBreadcrumb({
        message: 'Reward redemption request',
        level: 'info',
        data: {
          socialElectroWsBaseUrl: config.socialElectroWsBaseUrl,
          socialElectroRedeemNotificationUrl: config.socialElectroRedeemNotificationUrl,
          rewardId,

          // applicationToken is filtered by Sentry on purpose. So check if it's a valid value.
          isAppTokenValid: appToken && typeof appToken === 'string' && appToken.length > 0,

          ...player.socialData
        }
      })
    }

    fetch(config.socialElectroWsBaseUrl + config.socialElectroRedeemNotificationUrl.replace('{rewardId}', rewardId), {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        ApplicationToken: player.socialData.applicationToken
      }
    })
      .then(response => {
        if (process.env.REACT_APP_ENABLE_SENTRY === 'true') {
          Sentry.addBreadcrumb({
            message: 'Reward redemption status ' + response.status,
            level: 'info'
          })
          Sentry.addBreadcrumb({
            message: 'Reward redemption canRemove ' + canRemove,
            level: 'info'
          })
        }

        if (response.status === HttpStatus.OK || response.status === HttpStatus.NO_CONTENT) {
          console.log(
            '[Sweeps] Successfully claimed reward notification: ' + rewardId + ' with notification ID ' + notificationId
          )
          claimed = true

          if (canRemove) {
            if (claimCenter) {
              removed = true
              removeClaimCenter(notificationId)
            } else if (!inbox) {
              removed = true
              removeNotification(notificationId)
            }
          }
          logFirebaseRewardCollected(player)
        } else {
          console.log('[Sweeps] Error while claiming Reward Notification:', response.status)

          // There is an error. Reset lastRewardId to retry later
          lastRewardId = undefined
          return response.json().catch(() => {
            if (process.env.REACT_APP_ENABLE_SENTRY === 'true') {
              Sentry.addBreadcrumb({
                message: 'Failed parsing error response',
                level: 'info'
              })
            }
          })
        }
      })
      .then(errorResponse => {
        if (errorResponse) {
          console.log('[Sweeps] Error response while claiming Reward Notification:', errorResponse)

          // Per https://high5games.atlassian.net/browse/SWEEP-870 the errorCode 20409 = already claimed, so treat as claimed
          // eslint-disable-next-line
          if (errorResponse.errorCode === 20409) {
            claimed = true
          }

          // Any errors other than 20410 and 20253 should remove the notification.
          // eslint-disable-next-line
          if (canRemove && errorResponse.errorCode !== 20410 && errorResponse.errorCode !== 20503) {
            if (claimCenter) {
              removed = true
              removeClaimCenter(notificationId)
            } else if (!inbox) {
              removed = true
              removeNotification(notificationId)
            }

            if (process.env.REACT_APP_ENABLE_SENTRY === 'true') {
              Sentry.addBreadcrumb({
                message: 'Notification removed',
                level: 'info'
              })
            }
          } else if (process.env.REACT_APP_ENABLE_SENTRY === 'true') {
            Sentry.addBreadcrumb({
              message: 'Notification NOT removed! Reason: ' + errorResponse.errorCode + ' ' + canRemove,
              level: 'info'
            })
          }
        }
      })
      .catch(err => {
        console.log(`[Sweeps] Failed to claim reward notification - `, err)
        if (process.env.REACT_APP_ENABLE_SENTRY === 'true') {
          Sentry.captureException(err)
        }
      })
      .finally(() => {
        // claim center notifications just stay, if we failed to claim
        if (!removed && notificationId && !claimCenter && !inbox) {
          console.log('[Sweeps] Forcefully removing reward notification')
          removeNotification(notificationId, true)
        }

        // if there's a callback, call it
        callCallback()
      })
  } else if (lastRewardId === rewardId) {
    console.log('[Sweeps] Reward has been requested: ' + rewardId + ' with notification ID ' + notificationId)

    // if there's a callback, call it
    callCallback()
  } else {
    console.log('[Sweeps] Failed claim reward notification: ' + rewardId + ' with notification ID ' + notificationId)

    // if there's a callback, call it
    callCallback()
  }
}

export default redeemRewardNotification
