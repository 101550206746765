import { useCallback, useEffect } from 'react'
import { useStateValue } from '../../../state/StateProvider'
import { useGet } from '../../../../hooks/apiHooks'
import { tokenizer } from '../../../utils'
import { config } from '../../../../components/Init/Init'

export const usePlayerPIIUpdateDataRequired = ({ setPiiDataRequired, setPiiDataRequiredChecked }) => {
  const [{ player }] = useStateValue()
  const { GET } = useGet()

  const updatePiiDataRequired = useCallback(() => {
    if (player.status === config.status.registered || player.status === config.status.partial) {
      const userInfoUrl =
        config.apiUrl +
        tokenizer(config.piiStatusUrl, {
          '{userId}': player.loginUser.userId
        })

      GET(userInfoUrl)
        .then(data => {
          if (data) {
            console.log('[Casino] Set PII data required: ' + data.isPIIDataRequired)
            setPiiDataRequired(data.isPIIDataRequired)
            setPiiDataRequiredChecked(true)
            console.log('[Casino] PII data required checked set')
          } else {
            setPiiDataRequiredChecked(true)
            console.log('[Casino] PII data required checked set')
          }
        })
        .catch(error => console.log('error', error))
    }
  }, [GET, player.loginUser.userId, player.status, setPiiDataRequired, setPiiDataRequiredChecked])

  useEffect(() => {
    updatePiiDataRequired()
  }, [player.status])

  return { updatePiiDataRequired }
}
