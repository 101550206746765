import _ from 'lodash'
import { config } from '../../../components/Init/Init'
import HttpStatus from 'http-status-codes'
import { getPlayerStatus } from '../../helpers/playerHelper'

/**
 * walletReducer
 *
 * @desc
 *  A reducer that computes wallet state.
 *
 */

let setWalletBalanceState
setWalletBalanceState = (state, balance) => {
  _.merge(state.player, {
    wallet: balance
  })
}

const walletActions = {
    getWallet: function(state) {
      let walletBalance = state.player.wallet

      if (getPlayerStatus(state.player.status) === 'logged-in') {
        const getWalletsUrl =
          config.apiUrl +
          config.getWalletUrl
            .replace('{appId}', config.applicationId)
            .replace('{userId}', state.player.loginUser.userId)

        // get wallet balance
        fetch(getWalletsUrl, {
          method: 'GET',
          headers: {
            ApplicationToken: state.player.accessToken.encryptedToken
          }
        })
          .then(response => {
            if (response.status === HttpStatus.OK) {
              response.json().then(data => {
                walletBalance = data.wallets[0].balance
                setWalletBalanceState(state, walletBalance)
              })
            } else {
              response.json().then(err => {
                console.log('[DepositButton] - 400s Bad Request?', response.status)
                walletBalance = err.errorReason
              })
            }
          })
          .catch(err => {
            console.log('[DepositButton] - Fetch URL Bad?', err.errorReason)
            walletBalance = err.errorReason
          })
      }

      return {
        ...state
      }
    }
  },
  walletReducer = (state, action) => {
    if (_.has(walletActions, action.type)) {
      return walletActions[action.type](state, action)
    } else {
      return state
    }
  }

export default walletReducer
