import firebase from 'firebase/compat/app'
import { useStateValue } from '../services/state/StateProvider'
import { config } from '../components/Init/Init'
import { useCallback, useEffect, useState } from 'react'
import { getPlayerStatus } from '../services/helpers/playerHelper'
import { handleCasinoMode } from '../services/utils'

export const useActiveBrowserTab = callback => {
  const [{ player }, dispatch] = useStateValue()
  const playerStatus = getPlayerStatus(player)
  const [isActiveTabRegistered, setIsActiveTabRegistered] = useState(false)
  const { prevCasinoMode, currentCasinoMode } = handleCasinoMode()

  useEffect(() => {
    if (isActiveTabRegistered && !config.allowTabDuplication) {
      const db = window.rmgBase.database()

      db.ref(`users/${player.loginUser.userId}/sessionData/activeTabId`).on('value', snapshot => {
        const snapshotVal = snapshot.val()

        if (snapshotVal && snapshotVal !== player.activeTabId) {
          dispatch({
            type: 'logout',
            player: { refresh: true }
          })

          callback && callback()
        }
      })
    }
  }, [isActiveTabRegistered, player.activeTabId, player.loginUser.userId])

  const registerActiveTabId = useCallback(() => {
    // this condition checks if player change casino to casinoLite mode (open game on iOs)
    // if modes are not equal, there is no needs to set activeTabId (from sessionStorage)
    // without this, an error occurs with re-authorization on the logged out page
    // (when moved because of tabs duplication from iOs game back to lobby)
    //TODO: delete after remove CasinoLite.js
    if (prevCasinoMode !== currentCasinoMode) {
      return setIsActiveTabRegistered(true)
    }

    if (player.activeTabId && !config.allowTabDuplication && playerStatus === 'logged-in') {
      const registerActiveTabId = firebase.functions().httpsCallable('registerActiveTabId')

      registerActiveTabId({
        activeTabId: player.activeTabId,
        firebaseDb: config.firebaseDb
      })
        .then(() => {
          console.log('[Active tab] Registering id:' + player.activeTabId)
          setIsActiveTabRegistered(true)
        })
        .catch(err => console.log('Active tab registering error:', err))
    }
  }, [player.accountSessionId, player.activeTabId, playerStatus])

  return { registerActiveTabId, isActiveTabRegistered }
}
