import { useStateValue } from '../../services/state/StateProvider'
import { useCallback, useEffect } from 'react'

export const useSetPendingNotifications = showNotificationPopup => {
  const [{ casino, player }, dispatch] = useStateValue()

  const setPendingNotifications = useCallback(
    notifications => {
      dispatch({
        type: 'setPendingNotifications',
        player: {
          pendingNotifications: notifications
        }
      })
    },
    [dispatch]
  )

  useEffect(() => {
    if (
      player.pendingNotifications !== undefined &&
      player.pendingNotifications.length > 0 &&
      !casino.holdNotifications
    ) {
      showNotificationPopup(player.pendingNotifications)
      setPendingNotifications([])
    }
  }, [casino.holdNotifications, player.pendingNotifications, setPendingNotifications, showNotificationPopup])

  return { setPendingNotifications }
}
