import { useStateValue } from '../../../state/StateProvider'
import { useCallback, useEffect } from 'react'
import { useGet } from '../../../../hooks/apiHooks'
import { config } from '../../../../components/Init/Init'
import { tokenizer } from '../../../utils'

export const usePlayerPIIUpdateSmsVerificationIncentivized = ({ setSmsVerificationIncentivized }) => {
  const [{ player }] = useStateValue()
  const { GET } = useGet()

  const updateSmsVerificationIncentivized = useCallback(() => {
    if (player.status === config.status.registered || player.status === config.status.partial) {
      const verificationHistoryUrl =
        config.apiUrl +
        tokenizer(config.verificationHistoryUrl, {
          '{userId}': player.loginUser.userId
        }) +
        '?type=sms'

      GET(verificationHistoryUrl)
        .then(data => {
          if (data) {
            setSmsVerificationIncentivized(!data.hasBeenEverVerifiedBySms)
          } else {
            // assume false, worst case they get a reward they weren't expecting
            setSmsVerificationIncentivized(false)
          }
        })
        .catch(error => console.log('error', error))
    }
  }, [GET, player.loginUser.userId, player.status, setSmsVerificationIncentivized])

  useEffect(() => {
    if (config.enableSmsIncentiveDisplay) {
      updateSmsVerificationIncentivized()
    } else {
      // it's probably already this, but just in case...
      setSmsVerificationIncentivized(false)
    }
  }, [player.status, player.smsInfo.smsVerified])
}
