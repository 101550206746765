import { useEffect, useState } from 'react'
import { useStateValue } from '../../../state/StateProvider'
import { getPlayerStatus } from '../../../helpers/playerHelper'
import { config } from '../../../../components/Init/Init'

export const usePlayerPIIUpdatePIIDataState = ({ piiDataRequired, piiDataRequiredChecked }) => {
  const [{ player }, dispatch] = useStateValue()

  const [piiDataPopupShow, setPiiDataPopupShow] = useState(false)
  const [smsVerificationShow, setSmsVerificationShow] = useState(false)
  const [lockedSmsVerificationShow, setLockedSmsVerificationShow] = useState(false)

  useEffect(() => {
    // make sure we're logged in and have all the required data before handling these
    if (
      getPlayerStatus(player) !== 'logged-in' ||
      !piiDataRequiredChecked ||
      !player.smsInfo ||
      !player.smsInfo.smsInfoChecked
    ) {
      // don't log this for logged out...
      if (getPlayerStatus(player) === 'logged-in') {
        console.log(
          '[Casino] No PII/SMS popups yet! PII checked = ' +
            piiDataRequiredChecked +
            ', SMS checked = ' +
            player.smsInfo
            ? player.smsInfo.smsInfoChecked
            : undefined
        )
      }
      return
    }
    // it's time to show request popups a) any time if new user, or b) before a specified function otherwise
    let timeToShow =
      player.status === config.status.partial ||
      config.forceEarlyPiiAndSms ||
      (player.smsInfo !== undefined && Boolean(player.smsInfo.smsVerifiedFunc))

    // only log if a popup might open, not every time we set the smsVerifiedFunc...
    if (
      piiDataRequired ||
      (player.smsInfo !== undefined && (player.smsInfo.lockedSmsVerified || !player.smsInfo.smsVerified))
    ) {
      console.log(
        '[Casino] PII/SMS popup checks: time to show = ' +
          timeToShow +
          ', PII required = ' +
          piiDataRequired +
          ', SMS info = ' +
          (player.smsInfo ? JSON.stringify(player.smsInfo) : null)
      )
    }

    // check which pii/sms popup should show, if any...
    if (player.smsInfo !== undefined && player.smsInfo.lockedSmsVerified) {
      // first priority: locked popup
      console.log('[Casino] Show SMS locked')
      setPiiDataPopupShow(false)
      setSmsVerificationShow(false)
      setLockedSmsVerificationShow(true)
    } else if (piiDataRequired && timeToShow && !config.disablePiiEntry) {
      // second priority: pii data popup
      console.log('[Casino] Show PII data popup')
      setPiiDataPopupShow(true)
      setSmsVerificationShow(false)
      setLockedSmsVerificationShow(false)
    } else if (player.smsInfo !== undefined && !player.smsInfo.smsVerified && !config.disableSmsVerification) {
      // third priority: sms verification
      if (timeToShow) {
        console.log('[Casino] Show SMS verification')
      }
      setPiiDataPopupShow(false)
      setSmsVerificationShow(timeToShow)
      setLockedSmsVerificationShow(false)
    } else if (config.disableSmsVerification || (player.smsInfo !== undefined && player.smsInfo.smsVerified)) {
      // otherwise, if sms is verified, no popups should be showing
      setPiiDataPopupShow(false)
      setSmsVerificationShow(false)
      setLockedSmsVerificationShow(false)

      // if we're verified (or if verification is disabled), handle any function call that was waiting on it
      // eslint-disable-next-line no-extra-boolean-cast
      if (Boolean(player.smsInfo && player.smsInfo.smsVerifiedFunc)) {
        let func = player.smsInfo.smsVerifiedFunc
        dispatch({
          type: 'smsVerificationFunc',
          player: {
            smsInfo: {
              smsVerifiedFunc: null
            }
          }
        })
        func()
      }
    } else {
      // if we somehow get here (no sms info?), just default all the popups to hidden
      setPiiDataPopupShow(false)
      setSmsVerificationShow(false)
      setLockedSmsVerificationShow(false)
    }
  }, [player.status, player.smsInfo, piiDataRequired, piiDataRequiredChecked])

  return {
    piiDataPopupShow,
    smsVerificationShow,
    lockedSmsVerificationShow
  }
}
