import firebase from 'firebase/compat/app'
import { config } from '../../components/Init/Init'
import { useStateValue } from '../../services/state/StateProvider'
import { useCallback } from 'react'

export const useRemoveNotification = ({ purchaseNotifications, setPurchaseNotifications }) => {
  const [{ player }] = useStateValue()

  const removeNotification = useCallback(
    notificationId => {
      // Remove notification from purchaseNotifications
      const searchIndex = purchaseNotifications.findIndex(noti => noti.id === notificationId)

      if (searchIndex > -1) {
        let newPurchaseNotifications = [...purchaseNotifications]
        newPurchaseNotifications.splice(searchIndex, 1)
        setPurchaseNotifications(newPurchaseNotifications)
      }

      let removeNotification = firebase.functions().httpsCallable('removeNotification')
      return removeNotification({
        playerId: player.loginUser.userId,
        notificationId: notificationId,
        firebaseDb: config.firebaseDb
      })
    },
    [player.loginUser.userId, purchaseNotifications, setPurchaseNotifications]
  )

  return { removeNotification }
}
