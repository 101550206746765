import _ from 'lodash'
import HttpStatus from 'http-status-codes'
import { config } from '../components/Init/Init'
import l10n from '../components/i18n/I18N'
import moment from 'moment'
import { multiply, round, floor } from 'mathjs'
import 'moment/locale/es'
import 'moment/locale/pt'
import 'moment/locale/de'
import 'moment/locale/fi'
import 'moment/locale/tr'
import 'moment/locale/ru'
import { isAndroid, isMobile, isIOS, osVersion } from 'react-device-detect'
import { getPlayerStatusFromStatus } from './helpers/playerHelper'
import { getPlayerStatus } from './helpers/playerHelper'
import { showStore } from '../sweepstakes/messages'

/**
 * @name tokenizer
 *
 * @param {string} str - A string containing tokens to replace.
 * @param {object} tokens - An object of tokens and their replacements where
 *  the key is the token and the value is the replacement.
 *
 * @desc A filter that replaces tokens with the specified text. Useful for RESTful
 *  urls and localization.
 *
 */
const tokenizer = function(str, tokens) {
  _.forEach(_.toPairs(tokens), function(token) {
    if (typeof str === 'string') {
      str = str.replace(new RegExp(token[0], 'g'), token[1])
    } else {
      str = ''
    }
  })

  return str
}

const toCents = function(amount) {
  return Math.trunc(amount * config.centsMultiplier)
}

const convertToDollar = function(totals, locale, currencyCode) {
  let totalFinal
  let totalConverted
  let cents
  let negativeSign
  if (Math.sign(totals) !== -1) {
    if (totals.toString().length >= 2) {
      totalConverted = totals.toString().slice(0, -2)
      cents = '.' + totals.toString().slice(-2)
      totalFinal = formatValue(locale, currencyCode, 2, '', totalConverted, cents)
    } else {
      totalConverted = totals.toString().slice(0, -1)
      cents = '0.0' + totals.toString().slice(-1)
      totalFinal = formatValue(locale, currencyCode, 0, '', totalConverted, cents)
    }
    return totalFinal
  } else {
    negativeSign = totals.toString().slice(0, 1)
    if (totals.toString().length > 2) {
      totalConverted = totals.toString().slice(1, -2)
      cents = '.' + totals.toString().slice(-2)
      totalFinal = formatValue(locale, currencyCode, 2, negativeSign, totalConverted, cents)
    } else {
      cents = '0.0' + totals.toString().slice(-1)
      totalFinal = formatValue(locale, currencyCode, 0, negativeSign, '', cents)
    }
    return totalFinal
  }
}

const localizedTruncate = function(number, isTruncated) {
  if (typeof number === 'string') {
    // Unfortunately we can't rely on parseInt to always give us a number
    // '1,000' will return 1 instead of NaN for instance. Especially since
    // we're using non-US numbers, ',' and '.' are interchangeable and
    // shouldn't be assumed to be one or the other.
    // If we encounter any character not in the validChars below, we treat
    // this as a string and return the "number" back.
    const validChars = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9', '.', ',']
    number.split('').forEach(function(chr) {
      if (!validChars.includes(chr)) return number
    })
    number = parseInt(number, 10)
  }

  if (isTruncated && number >= config.oneMillion) {
    const n = Math.floor(number).toString()
    let numString

    if (number >= config.oneBillion) {
      numString = n.slice(0, n.length - 8) + '.' + n.slice(n.length - 8, n.length - 7)
    } else if (number >= config.oneMillion) {
      numString = n.slice(0, n.length - 5) + '.' + n.slice(n.length - 5, n.length - 4)
    }

    const numRounded = (Math.round(parseFloat(numString)) / 10).toFixed(1)
    const numLocal = numRounded.toLocaleString()

    if (number >= config.oneBillion) {
      return numLocal + l10n.t['account.abbreviationBillions']
    } else if (number >= config.oneMillion) {
      return numLocal + l10n.t['account.abbreviationMillions']
    }
  } else {
    return number.toLocaleString()
  }
}

// compare function for tout builder, used with .find()s or .findIndex()s looping through toutArrays
// tests if given id exists within an object at its first breakpoint
const equalsFirstKey = (currentObject, idToTest) => {
  if (currentObject.slot[Object.keys(currentObject.slot)[0]].id === idToTest) {
    return true
  } else {
    return false
  }
}

function formatValue(locale, currencyCode, num, mathSign, value1, value2) {
  return new Intl.NumberFormat(l10n.browserDateLocale(), {
    style: 'currency',
    currency: currencyCode,
    minimumFractionDigits: num
  }).format(mathSign + value1 + value2)
}

const humanReadableTime = function(time, language) {
  // set locale for humanized times
  moment.locale(language)

  return typeof time === 'number' ? moment.duration(time, 'minutes').humanize() : time
}

/**
 * reUpAuthToken
 *
 * @param {object} expired - The player object with the expired user token
 *
 * @desc
 *  A function that sets a new user auth token when the supplied interval
 *  elapses.
 *
 */
const reUpAuthToken = function(expired) {
  // request new token
  if (_.has(expired, 'loginUser')) {
    return fetch(config.apiUrl + config.authUrl, {
      method: 'POST',
      mode: 'cors',
      body: JSON.stringify({
        platform: 1,
        localeTag: l10n.langCode,
        loginType: config.reUpType,
        identifier: expired.loginUser.email,
        context: expired.refreshToken.encryptedToken,
        timezoneOffset: config.timezone,
        analyticsData: {
          browser: window.navigator.appVersion,
          userIp: '1.1.1.1',
          playVersion: config.version,
          clientApplicationId: config.clientApplicationId,
          device: 'browser',
          applicationId: config.applicationId,
          originId: config.originId
        }
      }),
      headers: {
        'Content-Type': 'application/json',
        ApplicationToken: config.appToken
      }
    }).catch(err => {
      console.log('[reUpAuthToken] - ERROR!', err)
    })
  } else {
    return expired
  }
}

const sliceTitle = title => {
  /**
   * sliceTitle
   *
   * @param {string} title      - The game title to be sliced.
   */

  // TODO: set title based on words not characters
  let words

  if (title.length > config.sliceTitleLength) {
    words =
      title
        .split('')
        .slice(0, title.lastIndexOf(' ', config.sliceTitleIndexOf))
        .join('') + '...'
  } else {
    words = title
  }

  return words
}

const getAllowDemoGames = status => {
  const allowDemoGamesWhileLoggedIn = _.defaultTo(config.allowDemoGamesWhileLoggedIn, true)
  if (!allowDemoGamesWhileLoggedIn && getPlayerStatusFromStatus(status) === 'logged-in') {
    return false
  }
  return true
}

const getShowPlayButtons = status => {
  if (isMobile) {
    const mobileShowPlayButtonIfSolo = _.defaultTo(config.mobileShowPlayButtonIfSolo, true)
    if (!mobileShowPlayButtonIfSolo && !getAllowDemoGames(status)) {
      return false
    }
  }
  return true
}

const getUseRoundPlayButton = status => {
  const useRoundPlayButtonIfSolo = _.defaultTo(config.useRoundPlayButtonIfSolo, false)
  if (useRoundPlayButtonIfSolo && !getAllowDemoGames(status)) {
    return true
  }
  return false
}

const gameIsDemoEnabled = gameId => {
  return config.demoGameIds && config.demoGameIds.indexOf(gameId) > -1
}

const demoGamesAvailable = () => {
  return config.demoGameIds && config.demoGameIds.length > 0
}

const getWallet = (wallets, walletId) => {
  if (wallets === undefined || wallets === null) {
    return 0
  }
  const wallet = wallets[walletId]
  return wallet ? parseFloat(wallet.amount) : 0
}

const getPageTitle = pageId => {
  let title = l10n.t['page.' + pageId + '.title']
  if (config.enableSweepstakes) {
    title = l10n.t['page.sweeps.title']
  } else {
    if (!title || title.length === 0) {
      // fallback to site title if unset
      title = process.env.REACT_APP_SITE_TITLE
    }
  }
  return title
}

const getPageDesc = pageId => {
  return l10n.t['page.' + pageId + '.description']
}

const convertUrlToWebp = url => {
  let newUrl = _.replace(url, '.jpg', '.webp')
  newUrl = _.replace(newUrl, '.jpeg', '.webp')
  newUrl = _.replace(newUrl, '.png', '.webp')
  newUrl = _.replace(newUrl, '.svg', '.webp')
  return newUrl
}

const getImageMimeType = url => {
  if (url.indexOf('.jpg') > -1 || url.indexOf('.jpeg') > -1) {
    return 'image/jpeg'
  }
  if (url.indexOf('.png') > -1) {
    return 'image/png'
  }
  if (url.indexOf('.webp') > -1) {
    return 'image/webp'
  }
  if (url.indexOf('.svg') > -1) {
    return 'image/svg+xml'
  }
}

/**
 * @name getFeatureByLocale
 * @description Handles the feature by locale and default locale
 * @param {string} Locale - The locale eg. "en-row", "en-us"
 * @param {string} feature - The feature info requested eg. "paymentMethods", "lowBalance"
 *
 * @return {object} - The locale feature or default locale feature
 */
const getFeatureByLocale = (locale, feature) => {
  let localeFeature = _.get(config.featuresByLocale, `${locale}`)
  const { defaultLocale } = config.featuresByLocale

  // If the locale is not specified on i18n-cfg.json we load the defaultLocale
  if (!localeFeature) {
    localeFeature = _.get(config.featuresByLocale, defaultLocale)
  }

  let featureData = _.get(localeFeature, `${feature}`)

  // If the feature is not defined we load default
  if (!featureData) {
    featureData = _.get(config.featuresByLocale, `${defaultLocale}.${feature}`)
  }

  return featureData
}

/**
 * @name getFeatureByLocaleMerged
 * @description Handles the feature by locale merged with default
 * @param {string} Locale
 * @param {string} feature
 *
 * @return {object} - The locale feature merged with default locale feature
 */
const getFeatureByLocaleMerged = (locale, feature) => {
  let localeFeature = _.get(config.featuresByLocale, `${locale}`)
  const { defaultLocale } = config.featuresByLocale

  // If the locale is not specified on i18n-cfg.json we load the defaultLocale
  if (!localeFeature) {
    localeFeature = _.get(config.featuresByLocale, defaultLocale)
  }

  let featureData = _.get(localeFeature, `${feature}`)

  if (featureData) {
    const defaultData = (featureData = _.get(config.featuresByLocale, `${defaultLocale}.${feature}`))
    const result = _.merge(defaultData, featureData)

    return result
  } else {
    featureData = _.get(config.featuresByLocale, `${defaultLocale}.${feature}`)
  }

  return featureData
}

/**
 * @name playerPermissions
 * @description Handle player permissions
 * @param {object} Player
 *
 * @return {object}
 */
const playerPermissions = player => {
  const permissions = {
    kycVerified: true
  }

  if (player.status === config.status.registered) {
    const iovation =
      player.iovation === 'review' &&
      (player.kycInfo.kycStatus === config.kycStatuses.NotVerified ||
        player.kycStatus === config.kycStatuses.Expired) &&
      config.enableIovation

    if (config.enableKyc && (iovation || _.get(player.kycInfo, 'requested'))) {
      permissions.kycVerified = false
    }
  }

  return permissions
}

// TODO: please document this function
const elementSupportsAttribute = (element, attribute) => {
  var test = document.createElement(element)
  if (attribute in test) {
    return true
  } else {
    return false
  }
}

/**
 * @name makeCMSSiteName
 *
 * @param {string} casino - The casino name.
 * @param {string} locale - The ISO locale the casino is operating in.
 *
 * @desc A function that generates the CMS site name used for GraphQL queries.
 */
const makeCMSSiteName = function(casino, locale) {
  return `${casino}_${_.replace(locale, /-/g, '_')}`
}

/**
 * @desc Convert the crypto amount based on exchange rate.
 * @param {number} amount - The amount in crypto.
 * @param {number} rate - The exchange rate.
 * @returns {number} - The amount in fiat.
 */
const convertCryptoToFiat = (amount, rate) => {
  const amountInFiat = multiply(amount, rate)
  return round(amountInFiat, 2).toFixed(2)
}

/**
 * @desc Formats the currency amount based on config.
 * @param {number} amount - The wallet amount.
 * @param {string} currencyId - The wallet currency ID.
 * @returns {string} - The truncated currency string.
 */
const formatCurrencyAmount = (amount, currencyId) => {
  let mainConfig = _.find(config.currencyDisplay, { currencyId })
  const currencyConfig = mainConfig ? mainConfig : _.find(config.additionalCurrencyParsing, { currencyId })
  if (!currencyConfig) {
    return amount
  }

  let autoFillDecimals = currencyConfig.autoFillDecimals
  if (currencyConfig.autoFillDecimalsIfNonInteger) {
    if (Math.round(amount) !== amount) {
      autoFillDecimals = true
    }
  }
  let maxDecimals = currencyConfig.maxDecimals

  if (currencyConfig.hasOwnProperty('autoDivide')) {
    amount /= currencyConfig.autoDivide
  }

  if (currencyConfig.hasOwnProperty('decimalsThreshold')) {
    if (amount >= currencyConfig.decimalsThreshold) {
      autoFillDecimals = false
      maxDecimals = 0
    }
  }

  if (currencyConfig.alwaysRoundDown) {
    if (maxDecimals === 0) {
      // the Math.js floor method isn't working correctly for large numbers, just use Math.floor if there's nothing unique
      amount = Math.floor(amount)
    } else {
      amount = floor(amount, maxDecimals)
    }
  }

  if (Number.isInteger(amount)) {
    if (autoFillDecimals) {
      if (currencyConfig.localize) {
        return amount.toLocaleString(undefined, {
          minimumFractionDigits: maxDecimals,
          maximumFractionDigits: maxDecimals
        })
      } else {
        return amount.toFixed(maxDecimals)
      }
    } else {
      if (currencyConfig.localize) {
        return amount.toLocaleString()
      } else {
        return amount
      }
    }
  } else {
    if (currencyConfig.localize) {
      if (autoFillDecimals) {
        return amount.toLocaleString(undefined, {
          minimumFractionDigits: maxDecimals,
          maximumFractionDigits: maxDecimals
        })
      } else {
        return amount.toLocaleString(undefined, {
          maximumFractionDigits: maxDecimals
        })
      }
    } else {
      const amountString = amount.toString()
      const number = amountString.slice(0, amountString.indexOf('.') + 1)
      const decimals = amountString.slice(amountString.indexOf('.') + 1, amountString.length)

      if (decimals.length > maxDecimals) {
        return number + decimals.slice(0, maxDecimals)
      } else {
        if (autoFillDecimals) {
          return number + decimals + '0'.repeat(maxDecimals - decimals.length)
        } else {
          return amountString
        }
      }
    }
  }
}

/**
 * @desc Orders the wallets based on config.
 * @param {object} wallets - The wallets to order.
 * @returns {object} - The ordered wallets.
 */
const orderWallets = wallets => {
  const orderedWallets = []
  _.forEach(config.currencyDisplay, (walletConfig, i) => {
    orderedWallets[i] = _.find(wallets, { currencyId: walletConfig.currencyId })
  })
  return orderedWallets
}

/**
 * @desc Check if img loading attribute is supported
 * @returns {boolean} - if the attribute is supported
 */
const isImgLoadingAttributeSupported = () => {
  const version16 = 16
  const isIOSSmallerThan16 = isIOS && !isNaN(parseFloat(osVersion)) && parseFloat(osVersion) < version16

  if (elementSupportsAttribute('img', 'loading') && !isIOSSmallerThan16) {
    return true
  }
  return false
}

/**
 * @desc Checks if category have custom template
 * @returns {boolean} - does category have custom template
 */
const isCustomCategory = template => {
  return !config.regularCategoriesTemplates.includes(template)
}

/**
 * @desc Make sure PII entry and SMS verification occurs prior to calling method
 */
const verifyPiiAndSmsThenCallFunction = (player, dispatch, func) => {
  dispatch({
    type: 'smsVerificationFunc',
    player: {
      smsInfo: {
        smsVerifiedFunc: func
      }
    }
  })
}

/**
 * @desc Make sure freeze verification occurs prior to opening any requests for those specific features
 * Currently used for purchases, redemptions, and game loads
 *
 */
const verifyNotFrozen = (player, type) => {
  // If no type specified, default to "allFrozen"
  if (type == null) {
    type = config.freezeEvents.allFrozen
  }

  // Check freeze status in Firebase
  if (player.freezeInfo && player.freezeInfo[type]) {
    return false
  }

  // Nothing says we're frozen, huzzah!
  return true
}

/**
 * @desc Make the checks before opening coin store
 *
 */
const openCoinStore = (player, dispatch) => {
  if (verifyNotFrozen(player, config.freezeEvents.depositFrozen)) {
    verifyPiiAndSmsThenCallFunction(player, dispatch, showStore)
  } else {
    let kycPopupReason =
      player.kycStatus === config.kycStatuses.Verified
        ? config.purchaseLimitKycStatus.PostKyc
        : config.purchaseLimitKycStatus.PreKyc

    dispatch({
      type: 'setModal',
      modals: {
        purchaseLimitKyc: { display: true, data: { reason: kycPopupReason } }
      }
    })
  }
}

/**
 * @desc Checks to see if game id, provider, and/or secondary provider is restricted for testing.
 * The config for this:
 * The form is - "providersRestrictedToTestAccounts": {"ProviderName":["Secondary Provider"]}
 * The secondary provider is specific to providers that are game aggregators.
 * If the provider is not an aggregator, then leave array empty.
 * Example to restrict games by id: "gameIdsRestrictedToTestAccounts":[1010, 1230, 13004].
 * Example to restrict provider: { "vivo": ["", "redrake"], "pragmatic": [] }.
 * This would restrict all pragmatic games, vivo live table games, and vivo games that have redrake as a secondary provider.
 * @returns {boolean} - true if game id, provider, and/or secondary provider are restricted for testing.
 */
const isGameRestrictedForTesting = (gameId, provider, secondaryProvider) => {
  // Is this game id restricted to test accounts
  if (config.gameIdsRestrictedToTestAccounts.indexOf(gameId) > -1) {
    return true
  }

  let restrictedObj = config.providersRestrictedToTestAccounts
  if (restrictedObj[provider]) {
    // Does game provider have secondary provider restrictions?
    if (restrictedObj[provider].length > 0) {
      return restrictedObj[provider].indexOf(secondaryProvider) > -1
    } else {
      // No secondary provider defined, restrict all
      return true
    }
  }

  return false
}

const isStatusNotification = notification => {
  return notification.type === config.popupType.depositStatus || notification.type === config.popupType.withdrawalStatus
}

const isNotificationLimit = notification => {
  return notification.type === config.popupType.limitReached
}

const isNotificationExpired = notification => {
  if (notification && notification.context && notification.context.popupEndDate) {
    let expirationDate = new Date(notification.context.popupEndDate)
    let currentDate = new Date()
    return currentDate > expirationDate
  } else {
    return false
  }
}

const isNotificationTimePending = notification => {
  if (notification && notification.context && notification.context.popupStartDate) {
    const startDate = new Date(notification.context.popupStartDate)
    const currentDate = new Date()
    return currentDate < startDate
  } else {
    return false
  }
}

const isNotificationPurchaseType = notification => {
  if (notification && notification.context && notification.context.directPurchaseUUID) {
    return true
  }
  return false
}

/**
 * @desc Return the timezone offset as a string (like '-04:00' as we see on the social login)
 * @returns {string}
 */
const getTimezoneOffsetString = () => {
  // int value for timezone
  let offset = new Date().getTimezoneOffset()
  if (offset !== 0) {
    offset = -offset
  }

  // convert to desired string format
  let sign = '+'
  if (offset < 0) {
    sign = '-'
    offset = -offset
  }
  let hours = Math.floor(offset / 60)
  let minutes = Math.floor(offset % 60)
  let output = sign + ('0' + hours).slice(-2) + ':' + ('0' + minutes).slice(-2)

  // return formatted timezone string
  return output
}

/**
 * @desc Return the timezone offset as a string (like '-04:00' as we see on the social login).
 *       If the player social data has a timezone offset, use that, otherwise use the timezone from JS.
 * @returns {string}
 */
const getTimezoneOffsetStringFromPlayer = player => {
  if (player && player.socialData && player.socialData.timezoneOffset && player.socialData.timezoneOffset.length > 0) {
    return player.socialData.timezoneOffset
  } else {
    return getTimezoneOffsetString()
  }
}

/**
 * @desc Convert the claim center nextTriggerTime to a ms timestamp, converting to local timezone if applicable
 * @returns {number}
 */
const convertNextTriggerTimeToMs = (nextTriggerTime, player) => {
  let date
  if (nextTriggerTime.indexOf('Z') > 0) {
    // utc timezone, use string as is
    date = new Date(nextTriggerTime)
  } else {
    // otherwise, append local timezone
    date = new Date(nextTriggerTime + getTimezoneOffsetStringFromPlayer(player))
  }
  return date.getTime()
}

/**
 * @desc Return launch path for game.
 * @param {string} gameId - numeric id for game
 * @param {string} mode - Values for mode are 'F' for free and 'R' for real. 'H' for history is not implemented at this time.
 * @returns {string}
 */
const getPathToGame = (gameId, mode) => {
  return `/${l10n.locale}/game/${mode}/${gameId}`
}

/**
 * @desc Given the location (from useLocation), checks if the current path is in a game
 * @returns {boolean} - true if we're in a game
 */
const isInGame = location => {
  return _.includes(location.pathname, 'game/R') || _.includes(location.pathname, 'game/F')
}

/**
 * @desc Given the location (from useLocation), checks if the current path is in the lobby.
 *       Any lobby location (including promotions page, for example) should set this true.
 * @returns {boolean} - true if we're in the lobby
 */
const isInLobby = location => {
  return _.includes(location.pathname, '/casino/lobby')
}

/**
 * @desc Check if the player is a test user, according to their login 'testUser' status
 * @returns {boolean} - true if the player is a test user
 */
const isPlayerTestAccount = player => {
  return player && getPlayerStatus(player) !== 'logged-out' && player.testUser === true
}

/**
 * @desc Check if we can bypass maintenance, according to bypass param and account test user status.
 * @returns {boolean} - true if we can bypass maintenance
 */
const canBypassMaintenance = (bypassMaintenanceParam, player, checkForTestUser = true) => {
  if (bypassMaintenanceParam === config.bypassMaintenanceParam) {
    if (checkForTestUser) {
      // make sure the player is a test user, otherwise they still get maintenance mode
      if (isPlayerTestAccount(player)) {
        return true
      }
    } else {
      // if we're not checking the test user status, then this is enough to bypass
      return true
    }
  }
  return false
}

/**
 * @desc Retrieves gdms game data by a game id.
 * @param {string} gameId - numeric id for game
 * @param {Function} successCallback - callback function if succeeds fails
 * @param {Function} failCallback - callback function if retrieval fails
 */
const getGameObj = (gameId, successCallback, failCallback) => {
  fetch(
    tokenizer(config.apiUrl + config.gameObjectUrl, {
      '{ugid}': gameId
    }),
    {
      method: 'GET',
      mode: 'cors',
      headers: {
        'Content-Type': 'application/json',
        ApplicationToken: config.appToken
      }
    }
  )
    .then(response => {
      if (response.status === HttpStatus.OK) {
        response.json().then(gameObj => {
          successCallback(gameObj)
        })
      } else {
        response.json().then(err => {
          failCallback(err)
        })
      }
    })
    .catch(err => {
      failCallback(err)
    })
}

// trying to account for some mistakes that might be made in the CMS...
const cleanUpEntryTags = entryTags => {
  if (entryTags) {
    // clear any start/end whitespace
    entryTags = entryTags.trim()

    if (entryTags.length > 0) {
      // if there are multiple pairs of square brackets then it's invalid, just use what we started with
      if (entryTags.split('[').length > 2 || entryTags.split(']').length > 2) {
        return entryTags
      }

      // replace any curly quotation marks with straight quotation marks
      entryTags = entryTags.replace(/[\u2018\u2019]/g, "'").replace(/[\u201C\u201D]/g, '"')

      // if there are no quotation marks of either sort, add them
      if (entryTags.indexOf('"') < 0 && entryTags.indexOf("'") < 0) {
        // clear square brackets if we have them
        if (entryTags.indexOf('[') === 0 && entryTags.indexOf(']') === entryTags.length - 1) {
          entryTags = entryTags.substring(1, entryTags.length - 1)
        }

        // split based on commas
        let entryTagsSplit = entryTags.split(',')
        let quotedEntryTagsSplit = _.map(entryTagsSplit, tag => {
          return '"' + tag.trim() + '"'
        })
        entryTags = quotedEntryTagsSplit.join(',')
      }

      // if there are no square brackets, add them
      if (entryTags.indexOf('[') < 0 && entryTags.indexOf(']') < 0) {
        entryTags = '[' + entryTags + ']'
      }
    }
  }
  return entryTags
}

const filterByTags = (entries, entryTagsProperty, tags) => {
  try {
    // tags will have single quotes instead of double quotes for JSON string array
    // For example: ['sale', 'special-feature', 'referral']
    // This is mainly to avoid escape characters in these player profile data
    // Therefore, these single quotes should be replaced to double quotes before parsing
    const playerTags = _.map(JSON.parse(tags.replace(/'/g, '"')), tag => {
      // tags are case-insensitive, convert to lowercase
      return tag.toLowerCase()
    })
    return entries.filter(entry => {
      let entryTags = entry ? cleanUpEntryTags(entry[entryTagsProperty]) : null
      if (!entryTags) return true
      try {
        return JSON.parse(entryTags).some(tag => playerTags.includes(tag.toLowerCase()))
      } catch (e) {
        console.warn(`[Utils] Error parsing '${entry.slug}' '${entryTagsProperty}' tags:`, entryTags, e)
        return false
      }
    })
  } catch (e) {
    console.warn(`[Utils] Error parsing '${entryTagsProperty}' tags... Empty or invalid JSON`, e)
    return entries.filter(entry => {
      let entryTags = entry ? cleanUpEntryTags(entry[entryTagsProperty]) : null
      return !entryTags
    })
  }
}

/**
 * @desc Return origin location or origin location + path.
 * @param {string} slug - string path of url
 */
const getLocationOrigin = slug => {
  if (slug && typeof slug === 'string') {
    return window.location.origin + slug
  }

  return window.location.origin
}

/**
 * @desc Return if the feature is enabled for this player ID
 * @param {boolean} Feature enabled flag
 * @param {integer} Feature rollout percentage flag
 * @param {string} Player id
 */
const getFeatureEnabledForPlayer = (featureEnabled, featureEnabledRolloutPercentage, playerId) => {
  if (featureEnabled) {
    if (featureEnabledRolloutPercentage <= 0) {
      return false
    } else if (featureEnabledRolloutPercentage >= 100) {
      return true
    } else if (playerId) {
      let playerIdLength = playerId.length
      let playerIdLastThreeChars = playerIdLength > 3 ? playerId.substring(playerIdLength - 3) : playerId
      let playerIdPercent = parseInt(playerIdLastThreeChars) % 100
      return playerIdPercent < featureEnabledRolloutPercentage
    }
  }
  return false
}

/**
 * @desc Enables or disables scrolling the body element
 * @param {boolean} scrollingAllowed
 */
function setBodyCanScroll(scrollingAllowed) {
  if (scrollingAllowed) {
    document.body.classList.remove('body-no-scroll')
  } else {
    document.body.classList.add('body-no-scroll')
  }
}

/**
 * @desc Scrolls to subcategory on page.
 * @param {string} name - subcategory name
 * @param {boolean} isSubcategory - is category or subcategory
 */
const scrollToTopOfCategoryName = (name, isSubcategory) => {
  function getOffsetTop(element) {
    return element ? element.offsetTop + getOffsetTop(element.offsetParent) : 0
  }

  const prefixElementId = isSubcategory ? 'subcategory-' : 'category-'
  let category = document.getElementById(prefixElementId + name),
    navHeight = document.getElementsByTagName('nav')[0].clientHeight,
    categoryMenu = document.getElementById('category-menu'),
    subMenu = document.getElementById('sub-category-container'),
    lobby = document.getElementById('game-lobby'),
    diamondsMenu = null

  if (lobby && document.getElementById('diamond-wallet')) {
    diamondsMenu = document.getElementById('diamond-wallet')
  }

  if (!category || !category.style || category.style.display === 'none' || !lobby) {
    return false
  }

  let categoryOffsetTop = getOffsetTop(category)
  let categoryHeight = categoryMenu && categoryMenu.offsetHeight !== 'undefined' ? categoryMenu.offsetHeight : 0
  let subMenuHeight =
    subMenu && subMenu.offsetHeight !== 'undefined'
      ? subMenu.offsetHeight
      : config.defaultCategoryTopMarginWithoutSubcategories
  let diamondsMenuHeight = diamondsMenu && diamondsMenu.offsetHeight !== 'undefined' ? diamondsMenu.offsetHeight : 0
  if (!isSubcategory) {
    // if we're using a category, their parent menus don't scroll with the rest of the page...
    // use hacky logic to determine their original (non-scrolled) position, relative to the 'games-section'
    let games = lobby.getElementsByClassName('games-section')[0]
    if (!games || !games.style || games.style.display === 'none' || !categoryMenu.contains(category)) {
      return false
    }
    categoryOffsetTop = getOffsetTop(games) - subMenuHeight
    // note: this isn't *quite* right if the diamonds are showing, but adjusting to them is proving finicky, so calling it good enough...
  }
  let scrollValue = categoryOffsetTop - navHeight - diamondsMenuHeight - subMenuHeight - categoryHeight

  window.scroll({ top: scrollValue, behavior: 'smooth' })
  return true
}

/**
 * @desc Scrolls to category on page.
 * @param {string} categoryName - category name
 */
const scrollToCategory = categoryName => {
  let scrolled = scrollToTopOfCategoryName(categoryName, false)
  if (!scrolled) {
    const elementToScrollTo = document.getElementById(`category-${categoryName}`)
    if (elementToScrollTo) {
      elementToScrollTo.scrollIntoView({
        behavior: 'smooth',
        block: 'start',
        inline: 'center'
      })
      return true
    }
    return false
  }
  return true
}

/**
 * @desc Hides the Zendesk chat window. Used to use window.$zopim.livechat.window.hide()
 *  but that stopped working as expected and now must use window.zE('webWidget', ‘close')
 *  instead. Adding a utils method for shared code, just in case they change it again...
 */
const hideChatWindow = () => {
  console.log('[utils] hideChatWindow')
  if (window.zE) {
    try {
      window.zE(config.zendesk && config.zendesk.widget ? config.zendesk.widget : 'webWidget', 'close')
    } catch (e) {
      // do nothing
    }
  }
}

/**
 * @desc Shows the Zendesk chat window. Added to utils, to coordinate with above hideChatWindow method.
 *  Used to use window.$zopim.livechat.window.show(), which still seems to function hide, but swapped
 *  to coordinating method for consistency.
 */
const showChatWindow = () => {
  console.log('[utils] showChatWindow')
  if (window.zE) {
    try {
      window.zE(config.zendesk && config.zendesk.widget ? config.zendesk.widget : 'webWidget', 'open')
    } catch (e) {
      // do nothing
    }
  }
}

/**
 * @desc method set session data with casino mode - lite or default
 * return object that contains init function, previous and current casino mode state
 * e.g. if prev and current not equal it is mean
 * that player moved Casino -> CasinoLite, or CasinoLite -> Casino
 * Note: delete after remove CasinoLite component
 */
const handleCasinoMode = function() {
  const currentCasinoMode = window.location.pathname.includes('game/R/') && isIOS ? 'lite' : 'default'
  const prevCasinoMode = sessionStorage.getItem('casinoMode') || 'default'

  function initCasinoMode() {
    if (prevCasinoMode !== currentCasinoMode) {
      // hack: this time offset needs because 'casinoMode' override in sessionStorage before reads
      setTimeout(() => {
        sessionStorage.setItem('casinoMode', currentCasinoMode)
      }, config.sessionCasinoModeUpdateDelay)
    }
  }

  return {
    initCasinoMode,
    prevCasinoMode,
    currentCasinoMode
  }
}

/**
 * @param {Object} state - the react state
 * @returns {Object} - the react state without the content
 */
const omitContentState = state => {
  if (_.has(state, 'casino.lobby')) {
    const newState = _.cloneDeep(state)
    newState.casino.lobby = { id: 0 }
    return newState
  }
  return state
}

/**
 * @desc Return true if player has valid tags for this game
 * @param {boolean}
 * @param {Object} tags an array of strings to compare to the game tags
 * @param {Object} gameObject game object with a gameTags field
 */
function areTagsValidForThisGame(tags, gameObject) {
  if (gameObject) {
    let filterResult = filterByTags([gameObject], 'gameTags', tags)
    return filterResult.length > 0
  } else {
    // treat this game as if it has no tags
    return true
  }
}

/**
 * @des this method check browser tab navigation type for duplication and return boolean
 * see for more https://developer.mozilla.org/en-US/docs/Web/API/PerformanceNavigationTiming/type
 * @returns {boolean} - tab was duplicated or not
 */
const isBrowserTabDuplicated = () => {
  const navigationType = window.performance.getEntriesByType('navigation')[0].type

  if (navigator.userAgent.indexOf('Firefox') !== -1) {
    return navigationType === 'back_forward' || navigationType === 'navigate'
  }

  return navigationType === 'back_forward'
}

/**
 * @des this method check player and config accesses for tax id feature
 * @param {string} playerCountry - player country code
 * @returns {boolean} - is for tax id feature allowed or not
 */
const isTaxIdAllowed = playerCountry => {
  return (
    playerCountry &&
    config.supportedTaxIdCountriesCodes.includes(playerCountry.toUpperCase()) &&
    config.enableMyAccountSsnInfo
  )
}

const storeProductIndex = (player, uuid) => {
  return storeIsInitialized() && !storeIsBlocked(player)
    ? player.store.data.products.findIndex(product => product.uuid.value === uuid)
    : -1
}

const storeHasProduct = (player, uuid) => {
  return storeProductIndex(player, uuid) !== -1
}

const storeGetProduct = (player, uuid) => {
  let index = storeProductIndex(player, uuid)
  if (index === -1) {
    return null
  } else {
    return player.store.data.products[index]
  }
}

const storeIsBlocked = player => {
  return !player.store || !player.store.data || player.store.data.blocked
}

const getCurrencySymbol = currencyCode => {
  if (config.currencySymbolMap && config.currencySymbolMap[currencyCode]) {
    return config.currencySymbolMap[currencyCode]
  }
  return currencyCode
}

const isBannerPurchaseType = banner => {
  return banner.ctaButtonAction && banner.ctaButtonAction.includes('OpenDirectPurchase')
}

const storeIsInitialized = () => {
  if (window.storeInitialized) {
    return true
  }
  return false
}

const getCurrencyIdByName = currency => {
  let desiredWalletId
  switch (currency) {
    case 'GC':
      desiredWalletId = config.gcWalletId
      break
    case 'SC':
      desiredWalletId = config.swcWalletId
      break
    default:
      break
  }
  return desiredWalletId
}

const replaceTokensWithReplacements = (text, replacements) => {
  let replacedText = text
  if (replacedText.includes('{value')) {
    let values = replacedText.match(/{value1\d}/g)
    _.forEach(values, tag => {
      replacements[tag] = localizedTruncate(replacements[tag], false)
    })
  }
  if (replacedText.includes('{player')) {
    let values = replacedText.match(/{player\d}/g)
    _.forEach(values, tag => {
      replacements[tag] = localizedTruncate(replacements[tag], false)
    })
  }
  replacedText = tokenizer(replacedText, replacements)
  return replacedText
}

/**
 * @des this is used to determine if we are using the sweepstakes webview
 * @param {object} casino - casino object
 * @returns {boolean} - true if in sweeps webview
 */
const isSweepsWebView = casino => {
  return casino && casino.sweepstakesOrigin && casino.sweepstakesOrigin.page === 'webview'
}

/**
 * @des Provides top level url to sweepstakes lobby
 * @param {object} casino - casino object
 * @returns {string} - to top navigation level link to sweepstakes lobby
 */
const getTopLevelReturnToLobbyURL = casino => {
  const isWebview = isSweepsWebView(casino)
  const urlPath = isWebview ? (isMobile ? 'gc/reloadSweeps.php' : 'Social/') : 'gc/'
  let lobbyUrl = new URL(`${casino.sweepstakesOriginUrl}/${urlPath}`)

  if (isWebview) {
    if (isMobile) {
      lobbyUrl.searchParams.append('deviceType', isAndroid ? '1' : '2')
    } else {
      // ems social app
      lobbyUrl.searchParams.append('sweepstakes', '1')
    }
  }

  return lobbyUrl.toString()
}

/**
 * @des Provides top level url to sweepstakes store
 * @param {object} casino - casino object
 * @returns {string} - to top navigation level link to sweepstakes store
 */
const getTopLevelReturnToStoreURL = casino => {
  const isWebview = isSweepsWebView(casino)

  let storeUrl = new URL(getTopLevelReturnToLobbyURL(casino))

  if (isWebview && isMobile) {
    storeUrl.searchParams.append('openStore', 'true')
  } else {
    storeUrl.searchParams.append('SweepsCoinStore', '1')
  }

  return storeUrl.toString()
}

const isValueInProviderRegionBlock = (providerConfig, value) => {
  return value && providerConfig.regionBlock.indexOf(value.toLowerCase()) > -1
}

const isValueInProviderAcceptedRegions = (providerConfig, value) => {
  return value && providerConfig.acceptedRegions.indexOf(value.toLowerCase()) > -1
}

/**
 * @desc Return true if provider is region blocked for player
 * @param {Object} player the player object with subdivision field
 * @param {Object} gameObject game object with a provider field
 * @returns {boolean}
 */
const isGameProviderRegionBlocked = (player, gameObject) => {
  const providerConfig = !_.isEmpty(gameObject) ? config.providers[gameObject.provider] : null

  if (providerConfig) {
    let playerIsInAcceptedRegion = true
    if (providerConfig.acceptedRegions && providerConfig.acceptedRegions.length > 0) {
      playerIsInAcceptedRegion =
        isValueInProviderAcceptedRegions(providerConfig, player.subdivision) ||
        isValueInProviderAcceptedRegions(providerConfig, player.country)
    }

    if (playerIsInAcceptedRegion) {
      return providerConfig.regionBlock
        ? isValueInProviderRegionBlock(providerConfig, player.subdivision) ||
            isValueInProviderRegionBlock(providerConfig, player.country)
        : false
    }
    return true
  }

  return false
}

/**
 * @desc Return the boolean value to check if pii data required or not
 * @param {string} playerStatus the player status
 * @returns {boolean}
 */
const forcePiiAndSms = playerStatus => {
  if (config.forceEarlyPiiAndSms) {
    // if we're globally forcing it early then it can't ever be closed
    return true
  } else {
    // if we're not globally forcing it early, then only partially registered accounts are forced
    return playerStatus === config.status.partial
  }
}

/**
 * @desc Optional parameter, for converting markdown links to "_blank" target if external
 *       Common usage: parse(md.render(text), parseOptionsExternalLinksHaveTargetBlank)
 */
const parseOptionsExternalLinksHaveTargetBlank = {
  replace: domNode => {
    if (
      domNode &&
      domNode.name === 'a' &&
      domNode.attribs &&
      domNode.attribs.href &&
      (domNode.attribs.href.indexOf('http') === 0 || domNode.attribs.href.indexOf('//') === 0)
    ) {
      // if it's an external link, make the target = '_blank'
      domNode.attribs.target = '_blank'
    }
  }
}

/**
 * @desc Return true if provider value is numeric type
 * @param {string | number} value value to check
 * @returns {boolean}
 */
const isNumeric = value =>
  (typeof value === 'number' || (typeof value === 'string' && value.trim() !== '')) && !isNaN(value)

export {
  tokenizer,
  convertToDollar,
  localizedTruncate,
  equalsFirstKey,
  reUpAuthToken,
  sliceTitle,
  humanReadableTime,
  convertUrlToWebp,
  getImageMimeType,
  getAllowDemoGames,
  getShowPlayButtons,
  getUseRoundPlayButton,
  getWallet,
  getPageTitle,
  getPageDesc,
  getFeatureByLocale,
  getFeatureByLocaleMerged,
  getTopLevelReturnToLobbyURL,
  getTopLevelReturnToStoreURL,
  playerPermissions,
  elementSupportsAttribute,
  makeCMSSiteName,
  formatCurrencyAmount,
  orderWallets,
  convertCryptoToFiat,
  toCents,
  isImgLoadingAttributeSupported,
  verifyPiiAndSmsThenCallFunction,
  verifyNotFrozen,
  openCoinStore,
  isStatusNotification,
  isNotificationLimit,
  isNotificationExpired,
  isNotificationTimePending,
  getTimezoneOffsetString,
  getTimezoneOffsetStringFromPlayer,
  convertNextTriggerTimeToMs,
  getPathToGame,
  isInGame,
  isInLobby,
  gameIsDemoEnabled,
  demoGamesAvailable,
  isPlayerTestAccount,
  isGameRestrictedForTesting,
  getGameObj,
  canBypassMaintenance,
  filterByTags,
  getLocationOrigin,
  getFeatureEnabledForPlayer,
  setBodyCanScroll,
  scrollToTopOfCategoryName,
  hideChatWindow,
  showChatWindow,
  handleCasinoMode,
  omitContentState,
  areTagsValidForThisGame,
  isBrowserTabDuplicated,
  isTaxIdAllowed,
  storeHasProduct,
  storeGetProduct,
  storeIsBlocked,
  getCurrencySymbol,
  isNotificationPurchaseType,
  isBannerPurchaseType,
  isSweepsWebView,
  storeIsInitialized,
  getCurrencyIdByName,
  replaceTokensWithReplacements,
  scrollToCategory,
  isCustomCategory,
  isGameProviderRegionBlocked,
  forcePiiAndSms,
  isNumeric,
  parseOptionsExternalLinksHaveTargetBlank
}
