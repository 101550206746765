import _ from 'lodash'
import * as Sentry from '@sentry/browser'

import { config } from '../../../components/Init/Init'
import l10n from '../../../components/i18n/I18N'
import loadingTout from '../../../static/ui/loading.json'
import { replaceTokensWithReplacements, tokenizer } from '../../utils'
import { setAppTokenLocalStorage } from '../utils'

function replaceTokensWithAssetProperties(text, asset) {
  let replacedText = text

  // convert "gameid" to "g0"/"g1"/etc. in image urls
  // "gameid" is an object with child objects, and campaign does not support this.
  _.forEach(asset.gameid, (id, idx) => {
    replacedText = tokenizer(replacedText, {
      [`{g${idx}}`]: id
    })
  })

  // support "ugid" to convert "g0"/"g1"/etc. for popups from campaign
  // "ugid" is a string in JSON array format with numbers.
  // e.g. "[1234, 5678]"
  if (asset.ugid) {
    try {
      const ugid = JSON.parse(asset.ugid)
      _.forEach(ugid, (id, idx) => {
        replacedText = tokenizer(replacedText, {
          [`{g${idx}}`]: id
        })
      })
    } catch (e) {
      console.log('Invalid JSON for ugid')
    }
  }

  // Convert additional variables, either textlines or image urls to support dyanmic assets
  // Each variable is a string in JSON array format with texts.
  // Single quotes can be used to avoid escape sequences in campaign.
  // e.g. "['sc', 'gc', 'dia']"

  // convert "rewardtype" to "r0"/"r1"/etc.
  if (asset.rewardtype) {
    try {
      const rewardtype = JSON.parse(asset.rewardtype.replace(/'/g, '"'))
      _.forEach(rewardtype, (id, idx) => {
        replacedText = tokenizer(replacedText, {
          [`{r${idx}}`]: id
        })
      })
    } catch (e) {
      console.log('Invalid JSON for rewardtype')
    }
  }
  // convert "salepercent" to "s0"/"s1"/etc.
  if (asset.salepercent) {
    try {
      const salepercent = JSON.parse(asset.salepercent.replace(/'/g, '"'))
      _.forEach(salepercent, (id, idx) => {
        replacedText = tokenizer(replacedText, {
          [`{s${idx}}`]: id
        })
      })
    } catch (e) {
      console.log('Invalid JSON for salepercent')
    }
  }
  // convert "boosttype" to "b0"/"b1"/etc.
  if (asset.boosttype) {
    try {
      const boosttype = JSON.parse(asset.boosttype.replace(/'/g, '"'))
      _.forEach(boosttype, (id, idx) => {
        replacedText = tokenizer(replacedText, {
          [`{b${idx}}`]: id
        })
      })
    } catch (e) {
      console.log('Invalid JSON for boosttype')
    }
  }

  return replacedText
}

function preprocessModalAction(state, action) {
  if (_.has(action, 'modals.popup.data.context')) {
    const asset = action.modals.popup.data.context

    let template = _.cloneDeep(l10n.promoTemplates.types[action.modals.popup.data.type]),
      // TODO: give replacement tokens semantic names amd make them enumerable
      replacements = {
        '{value1}': asset.value1,
        '{value2}': asset.value2,
        '{value3}': asset.value3,
        '{value4}': asset.value4,
        '{value5}': asset.value5,
        '{value6}': asset.value6,
        '{value7}': asset.value7,
        '{CURRENCY_SYMBOL}': l10n.currency.symbol,
        '{player}': state.player.username
      }

    // clear existing template
    action.modals.popup.template = {}

    let suppressDiamondPopup = false
    if (action.modals.popup.data.type.startsWith('diamonds_awarded')) {
      suppressDiamondPopup = _.get(
        config,
        `providers.${state.casino.activeGame.provider || 'noProvider'}.suppressDiamondMessages`,
        false
      )
    }

    if (action.modals.popup.forceShowNotification) {
      suppressDiamondPopup = false
    }

    // don't parse diamond popups if suppressed by an active game
    if (!suppressDiamondPopup) {
      _.forEach(template, block => {
        _.forEach(block.slot, breakpoint => {
          if (_.has(breakpoint, 'content.textlines')) {
            // eslint-disable-next-line array-callback-return
            breakpoint.content.textlines.map(line => {
              line.text = replaceTokensWithReplacements(line.text, replacements)
              line.text = replaceTokensWithAssetProperties(line.text, asset)
            })
          } else if (breakpoint.content.type === 'image') {
            breakpoint.content.url = replaceTokensWithAssetProperties(breakpoint.content.url, asset)
          } else if (breakpoint.content.type === 'link' || breakpoint.content.type === 'video-link') {
            breakpoint.content.text = replaceTokensWithReplacements(breakpoint.content.text, replacements)
            if (asset.cta.url === '') {
              breakpoint.content.url = '#' // suppress navigation
            } else if (asset.cta.url.indexOf('http') === 0) {
              breakpoint.content.url = asset.cta.url
            } else {
              breakpoint.content.url = l10n.localizeUrl(asset.cta.url)
            }
          } else if (breakpoint.content.type === 'button') {
            breakpoint.content.text = replaceTokensWithReplacements(breakpoint.content.text, replacements)
          } else if (breakpoint.content.type === 'close') {
            // TODO: abstract 'close' into a tout action handler when new actions are requested
            // prevents navigation to homepage
            breakpoint.content.text = replaceTokensWithReplacements(breakpoint.content.text, replacements)
            breakpoint.content.url = '#'
          }
        })
      })
    } else {
      // suppress modal
      action.modals.popup.display = false
    }

    action.modals.popup.template = template
  }
  return action
}

/**
 * casinoReducer
 *
 * @desc
 *  A reducer that computes casino state
 *
 */
const casinoActions = {
    setSellSheet: function(state, action) {
      return {
        ...state,
        casino: {
          ...state.casino,
          sellSheet: action.casino.sellSheet
        }
      }
    },
    setPromoInfo: function(state, action) {
      return {
        ...state,
        casino: {
          ...state.casino,
          promoInfo: action.casino.promoInfo
        }
      }
    },
    setLobby: function(state, action) {
      return {
        ...state,
        casino: {
          ...state.casino,
          lobby: action.casino.lobby
        }
      }
    },
    storeAnimations: function(state, action) {
      let animations = _.merge(state.casino.animations, action.casino.animations)

      return {
        ...state,
        casino: {
          ...state.casino,
          animations: animations
        }
      }
    },
    setGame: function(state, action) {
      return {
        ...state,
        casino: {
          ...state.casino,
          activeGame: action.casino.activeGame
        }
      }
    },
    callCallback: function(state, action) {
      if (action.casino.callback) {
        action.casino.callback()
      }
      return state
    },
    setModal: function(state, action) {
      let newState = _.clone(state)

      try {
        preprocessModalAction(state, action)

        newState = {
          ...state,
          modals: {
            ...state.modals,
            ...action.modals
          }
        }
      } catch (e) {
        console.log('[setModal] There was an error processing the modal action', e)
        if (process.env.REACT_APP_ENABLE_SENTRY === 'true') {
          Sentry.captureException(e)
        }
      }

      setAppTokenLocalStorage(newState)

      return newState
    },
    setActiveNotificationId: function(state, action) {
      return {
        ...state,
        casino: {
          ...state.casino,
          activeNotificationId: action.casino.activeNotificationId
        }
      }
    },
    setSingleModal: function(state, action) {
      let newState = _.clone(state)

      try {
        preprocessModalAction(state, action)

        newState = {
          ...state,
          modals: _.merge(state.modals, action.modals)
        }
      } catch (e) {
        console.log('[setSingleModal] There was an error processing the modal action', e)
        if (process.env.REACT_APP_ENABLE_SENTRY === 'true') {
          Sentry.captureException(e)
        }
      }

      // if we're setting claim center notifications or inbox message status, make sure we're not merging with old ones... we want a full replace
      if (action.modals.claimCenter && action.modals.claimCenter.data) {
        if (action.modals.claimCenter.data.notifications) {
          newState.modals.claimCenter.data.notifications = action.modals.claimCenter.data.notifications
        }
        if (action.modals.claimCenter.data.inboxMessageStatus) {
          newState.modals.claimCenter.data.inboxMessageStatus = action.modals.claimCenter.data.inboxMessageStatus
        }
      }

      setAppTokenLocalStorage(newState)

      return newState
    },
    addGames: function(state, action) {
      let newGameList = state.casino.games || {},
        newTouts = _.merge(state.casino.touts, action.casino.touts)

      _.forEach(action.casino.games, game => {
        if (!newGameList[game.id] || game.gameTags !== newGameList[game.id].gameTags) {
          newGameList[game.id] = game
        }
      })

      return {
        ...state,
        casino: {
          ...state.casino,
          games: newGameList,
          touts: newTouts
        }
      }
    },
    resetLobby: function(state) {
      return {
        ...state,
        casino: {
          ...state.casino,
          lobby: {
            id: 0
          },
          sellSheet: loadingTout
        }
      }
    },
    setSweepstakesOpen: function(state, action) {
      return {
        ...state,
        casino: {
          ...state.casino,
          sweepstakesOpen: action.casino.sweepstakesOpen
        }
      }
    },
    setSweepstakesOrigin: function(state, action) {
      return {
        ...state,
        casino: {
          ...state.casino,
          sweepstakesOrigin: action.casino.sweepstakesOrigin
        }
      }
    },
    setSweepstakesOriginUrl: function(state, action) {
      return {
        ...state,
        casino: {
          ...state.casino,
          sweepstakesOriginUrl: action.casino.sweepstakesOriginUrl
        }
      }
    },
    setGameRoundPending: function(state, action) {
      return {
        ...state,
        casino: {
          ...state.casino,
          gameRoundPending: action.casino.gameRoundPending
        }
      }
    },
    setDisabledElements: function(state, action) {
      return {
        ...state,
        casino: {
          ...state.casino,
          disabledElements: action.casino.disabledElements
        }
      }
    },
    setGameCabinetStaticUi: function(state, action) {
      return {
        ...state,
        casino: {
          ...state.casino,
          gameCabinetStaticUi: action.casino.gameCabinetStaticUi
        }
      }
    },
    setGameCabinetKey: function(state, action) {
      return {
        ...state,
        casino: {
          ...state.casino,
          gameCabinetKey: action.casino.gameCabinetKey
        }
      }
    },
    setChatBotOpen: function(state, action) {
      return {
        ...state,
        casino: {
          ...state.casino,
          chatBotOpen: action.casino.chatBotOpen
        }
      }
    },
    setReconcileGameBalanceAtEndOfRound: function(state, action) {
      return {
        ...state,
        casino: {
          ...state.casino,
          reconcileGameBalanceAtEndOfRound: action.casino.reconcileGameBalanceAtEndOfRound
        }
      }
    },
    setIsPurchase: function(state, action) {
      return {
        ...state,
        casino: {
          ...state.casino,
          isPurchase: action.casino.isPurchase
        }
      }
    },
    setHoldNotifications: function(state, action) {
      return {
        ...state,
        casino: {
          ...state.casino,
          holdNotifications: action.casino.holdNotifications
        }
      }
    },
    setReloadGameBalance: function(state, action) {
      return {
        ...state,
        casino: {
          ...state.casino,
          reloadGameBalance: action.casino.reloadGameBalance
        }
      }
    },
    setLogMxParameters: function(state, action) {
      return {
        ...state,
        casino: {
          ...state.casino,
          logMxParam: action.casino.logMxParam,
          logMxPrefixOverride: action.casino.logMxPrefixOverride ? action.casino.logMxPrefixOverride : ''
        }
      }
    },
    setBypassMaintenanceParameter: function(state, action) {
      return {
        ...state,
        casino: {
          ...state.casino,
          bypassMaintenanceParam: action.casino.bypassMaintenanceParam
        }
      }
    },
    setCustomCategoriesAssets: function(state, action) {
      return {
        ...state,
        casino: {
          ...state.casino,
          customCategoriesAssets: action.casino.customCategoriesAssets
        }
      }
    },
    setIsBoostOverlayShown: function(state, action) {
      return {
        ...state,
        casino: {
          ...state.casino,
          isBoostOverlayShown: action.casino.isBoostOverlayShown
        }
      }
    },
    setFlashPrizes: function(state, action) {
      return {
        ...state,
        casino: {
          ...state.casino,
          flashPrizes: action.casino.flashPrizes
        }
      }
    },
    setFlashPrizeGames: function(state, action) {
      return {
        ...state,
        casino: {
          ...state.casino,
          flashPrizeGames: action.casino.flashPrizeGames
        }
      }
    },
    setSweepsRealityCheckInfo: function(state, action) {
      return {
        ...state,
        casino: {
          ...state.casino,
          sweepsRealityCheckInfo: action.casino.sweepsRealityCheckInfo,
          updateRealityCheckOnAllTabs: action.casino.updateRealityCheckOnAllTabs
        }
      }
    },
    setLobbyDynamicOverlays: function(state, action) {
      return {
        ...state,
        casino: {
          ...state.casino,
          dynamicOverlays: action.casino.dynamicOverlays
        }
      }
    },
    setIsDesktop: function(state, action) {
      return {
        ...state,
        casino: {
          ...state.casino,
          isDesktop: action.casino.isDesktop
        }
      }
    },
    setIsPhone: function(state, action) {
      return {
        ...state,
        casino: {
          ...state.casino,
          isPhone: action.casino.isPhone
        }
      }
    },
    setIsNarrowPhone: function(state, action) {
      return {
        ...state,
        casino: {
          ...state.casino,
          isNarrowPhone: action.casino.isNarrowPhone
        }
      }
    },
    setIsEffectiveAreaPhone: function(state, action) {
      return {
        ...state,
        casino: {
          ...state.casino,
          isEffectiveAreaPhone: action.casino.isEffectiveAreaPhone
        }
      }
    },
    setFeaturedDisplay: function(state, action) {
      return {
        ...state,
        casino: {
          ...state.casino,
          featuredDisplay: action.casino.featuredDisplay
        }
      }
    },
    setGameCounts: function(state, action) {
      return {
        ...state,
        casino: {
          ...state.casino,
          gameCounts: action.casino.gameCounts
        }
      }
    }
  },
  casinoReducer = (state, action) => {
    if (_.has(casinoActions, action.type)) {
      return casinoActions[action.type](state, action)
    } else {
      return state
    }
  }

export default casinoReducer
