import _ from 'lodash'
import { setAppTokenLocalStorage } from '../utils'

/**
 * formReducer
 *
 * @desc
 *  A reducer that stores form state for calculations
 *
 */
const formActions = {
    setDatePicker: function(state, action) {
      return {
        ...state,
        forms: {
          ...state.forms,
          step3: {
            ...state.forms.step3,
            currentYear: action.forms.currentYear,
            currentMonth: action.forms.currentMonth,
            currentDay: action.forms.currentDay,
            days: action.forms.days,
            months: action.forms.months
          }
        }
      }
    },
    saveFormsProgress: function(state) {
      setAppTokenLocalStorage(state)

      return state
    }
  },
  formReducer = (state, action) => {
    if (_.has(formActions, action.type)) {
      return formActions[action.type](state, action)
    } else {
      return state
    }
  }

export default formReducer
