import { useStateValue } from '../../services/state/StateProvider'
import { useEffect } from 'react'
import { convertNextTriggerTimeToMs, isNotificationExpired } from '../../services/utils'
import { config } from '../../components/Init/Init'

export const useTimePendingNotifications = showNotificationPopup => {
  const [{ casino, player }, dispatch] = useStateValue()

  const getFirstTimePendingNotification = timePendingNotifications => {
    const timePendingNotificationsCopy = [...timePendingNotifications]
    const filteredTimePendingNotifications = timePendingNotificationsCopy
      .filter(notification => !isNotificationExpired(notification))
      .sort((a, b) => new Date(a.context.popupStartDate) - new Date(b.context.popupStartDate))

    return {
      notification: filteredTimePendingNotifications[0],
      triggerTime: convertNextTriggerTimeToMs(filteredTimePendingNotifications[0].context.popupStartDate)
    }
  }

  useEffect(() => {
    if (player.timePendingNotifications && player.timePendingNotifications.length > 0) {
      // prevent display of already added "firebasePendingNotifications" timers
      if (casino.holdNotifications) {
        dispatch({
          type: 'removeTimer',
          timers: [],
          name: config.timerNames.firebasePendingNotifications
        })
      } else {
        const { triggerTime, notification } = getFirstTimePendingNotification(player.timePendingNotifications)

        if (notification) {
          dispatch({
            type: 'addTimer',
            timers: [
              {
                name: config.timerNames.firebasePendingNotifications,
                trigger: triggerTime,
                verifyExpired: true,
                repeat: false,
                actions: [
                  {
                    type: 'callCallback',
                    casino: {
                      callback: () => {
                        showNotificationPopup([notification])
                      }
                    }
                  },
                  {
                    type: 'callCallback',
                    casino: {
                      callback: () => {
                        removeTimePendingNotificationById([...player.timePendingNotifications], notification.id)
                      }
                    }
                  }
                ]
              }
            ]
          })
        }
      }
    }
  }, [casino.holdNotifications, player.timePendingNotifications])

  // this method (remove particular notification) need, because app global state initialized from localStore(?)
  // and after successfully showing and deleting in fireBase it still exist in app store
  const removeTimePendingNotificationById = (notificationArr, notificationId) => {
    const timePendingNotificationArr = notificationArr.filter(elem => elem.id !== notificationId)

    dispatch({
      type: 'setTimePendingNotifications',
      player: {
        timePendingNotifications: timePendingNotificationArr
      }
    })
  }

  const setTimePendingNotifications = timePendingNotificationArr => {
    dispatch({
      type: 'setTimePendingNotifications',
      player: {
        timePendingNotifications: timePendingNotificationArr
      }
    })
  }

  return { setTimePendingNotifications }
}
