import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import isEqual from 'lodash/isEqual'
import { useStateValue } from '../../../state/StateProvider'
import { useConfigValueByCurrentDevice } from '../../../../hooks/useConfigValueByCurrentDevice'
import { config } from '../../../../components/Init/Init'

export const useUpdateFlashPrizes = () => {
  const [{ casino, player }, dispatch] = useStateValue()
  const [flashPrizesIntervalListening, setFlashPrizesIntervalListening] = useState(false)
  const flashPrizesQueryInterval = useRef(null)

  const flashPrizesQueryIntervalMs = useConfigValueByCurrentDevice(config.flashPrizesQueryIntervalMs)
  const enableFlashPrizesFirebaseListening = useConfigValueByCurrentDevice(config.enableFlashPrizesFirebaseListening)
  const flashPrizesQueryDelayMs = useConfigValueByCurrentDevice(config.flashPrizesQueryDelayMs)

  const isFlashPrizesFirebaseListeningAvailable = useMemo(
    () =>
      config.enableSweepstakes &&
      player.loginUser &&
      player.loginUser.userId &&
      window.rmgBase &&
      window.rmgBase.auth().currentUser &&
      enableFlashPrizesFirebaseListening,
    [enableFlashPrizesFirebaseListening, window.rmgBase, window.rmgBase?.auth().currentUser, player.loginUser.userId]
  )

  const updateFlashPrizes = useCallback(() => {
    const db = window.rmgBase.database()

    if (db) {
      db.ref(`flashPrizes`).once('value', snapshot => {
        const flashPrizes = snapshot.val()

        if (!isEqual(casino.flashPrizes, flashPrizes)) {
          dispatch({
            type: 'setFlashPrizes',
            casino: {
              flashPrizes: flashPrizes
            }
          })
        }

        const flashPrizeGames = {}

        flashPrizes &&
          flashPrizes.forEach(flashPrize => {
            const startDate = flashPrize.startDate
            const endDate = flashPrize.endDate
            const currencies = flashPrize.currencies ? flashPrize.currencies : []
            const nowMs = new Date().getTime()
            const endDateMS = new Date(endDate).getTime()

            flashPrize.campaignGames &&
              flashPrize.campaignGames.forEach(({ gameId, hasDiamondReward }) => {
                flashPrizeGames[gameId] = {
                  gameId,
                  endDate,
                  startDate,
                  currencies,
                  hasDiamondReward,
                  isExpired: endDateMS < nowMs
                }
              })
          })

        if (!isEqual(casino.flashPrizeGames, flashPrizeGames)) {
          dispatch({
            type: 'setFlashPrizeGames',
            casino: {
              flashPrizeGames
            }
          })
        }
      })
    }
  }, [casino.flashPrizeGames, casino.flashPrizes, dispatch])

  useEffect(() => {
    const initFlashPrizesQueryInterval = () => {
      if (
        isFlashPrizesFirebaseListeningAvailable &&
        flashPrizesIntervalListening &&
        !flashPrizesQueryInterval.current
      ) {
        flashPrizesQueryInterval.current = setInterval(updateFlashPrizes, flashPrizesQueryIntervalMs)
      }
    }

    if (flashPrizesQueryInterval.current) {
      clearInterval(flashPrizesQueryInterval.current)
      flashPrizesQueryInterval.current = null

      initFlashPrizesQueryInterval()
    } else {
      setTimeout(() => {
        initFlashPrizesQueryInterval()
      }, flashPrizesQueryDelayMs || 0)
    }

    return () => clearInterval(flashPrizesQueryInterval.current)
  }, [
    updateFlashPrizes,
    flashPrizesQueryDelayMs,
    flashPrizesQueryIntervalMs,
    flashPrizesIntervalListening,
    isFlashPrizesFirebaseListeningAvailable
  ])

  const initFlashPrizesFirebaseListening = useCallback(() => {
    if (isFlashPrizesFirebaseListeningAvailable && !flashPrizesQueryInterval.current) {
      const timer = setTimeout(() => {
        updateFlashPrizes()
        setFlashPrizesIntervalListening(true)
        clearTimeout(timer)
      }, flashPrizesQueryDelayMs || 0)
    }
  }, [flashPrizesQueryDelayMs, isFlashPrizesFirebaseListeningAvailable, updateFlashPrizes])

  return { initFlashPrizesFirebaseListening, flashPrizesQueryInterval }
}
