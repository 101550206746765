import countries from 'countries-list'
import getSymbolFromCurrency from 'currency-symbol-map'
import _ from 'lodash'
import * as Sentry from '@sentry/browser'
import { makeCMSSiteName } from '../../services/utils'

const countryCodesMap = _.pick(
  countries.countries,
  _.map(
    [
      'ad',
      'ae',
      'ar',
      'bo',
      'br',
      'ca',
      'ch',
      'cl',
      'cr',
      'de',
      'ec',
      'eg',
      'fi',
      'fj',
      'gb',
      'hn',
      'ie',
      'in',
      'is',
      'kr',
      'la',
      'li',
      'lu',
      'jm',
      'jo',
      'jp',
      'ma',
      'mc',
      'mg',
      'mk',
      'mn',
      'mv',
      'mw',
      'mx',
      'my',
      'ng',
      'ni',
      'no',
      'nz',
      'om',
      'pa',
      'pe',
      'pt',
      'py',
      'qa',
      'rs',
      'ru',
      'sa',
      'sm',
      'sv',
      'th',
      'tr',
      'tw',
      'ue',
      'ug',
      'us',
      'uy',
      've',
      'vn',
      'ye',
      'za'
    ],
    country => country.toUpperCase()
  )
)

let l10n = {
  t: {},
  sessionOptions: [
    {
      id: 0,
      time: 30 // 30 minutes
    },
    {
      id: 1,
      time: 60 // 1 hour
    },
    {
      id: 2,
      time: 120 // 2 hours
    },
    {
      id: 3,
      time: 240 // 4 hours
    },
    {
      id: 4,
      time: 360 // 6 hours
    }
  ],
  realityOptions: [
    {
      id: 0,
      time: 30, // 30 minutes
      value: 'realityCheck.checkTimeList1'
    },
    {
      id: 1,
      time: 60, // 1 hour
      value: 'realityCheck.checkTimeList2'
    },
    {
      id: 2,
      time: 120, // 2 hours
      value: 'realityCheck.checkTimeList3'
    },
    {
      id: 3,
      time: 180, // 3 hours
      value: 'realityCheck.checkTimeList4'
    }
  ],
  currency: {
    symbol: '$',
    code: 'USD',
    defaultSymbol: '$',
    defaultCode: 'USD'
  },
  monthsDict: {
    1: 'months.jan',
    2: 'months.feb',
    3: 'months.mar',
    4: 'months.apr',
    5: 'months.may',
    6: 'months.jun',
    7: 'months.jul',
    8: 'months.aug',
    9: 'months.sep',
    10: 'months.oct',
    11: 'months.nov',
    12: 'months.dec'
  },
  setCountries: countries => {
    l10n.country = _.map(countries.country_list, country => {
      return country
    })
  },
  countryCodes: countryCodesMap,
  locale: '',
  beginBalance: 0,
  langCode: 'en',
  countryCode: '',
  phonePattern: 13,
  minUsernameLength: 4,
  minPasswordLength: 8,
  minPostalCodeLength: 4,
  minAccountValidationLength: 4,
  taxIdLength: 11,
  taxIdLengthMsg: 9,
  timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
  dateFormat: 'l - hh:mm:ss',
  errorPopOverDistance: 40,
  toolTipDistance: 50,
  popoverfadeinduration: 500,
  pageIndicatorSpacing: 40,
  enterKeyNumber: 13,
  limitsdelaytime: 1250,
  /**
   * setCurrency
   *
   * @param {string} currencyCode - The currency code to use.
   *
   * @desc
   *  A function that sets the currency code and symbol of the l10n object.
   *
   */
  setCurrency: currencyCode => {
    l10n.currency.code = currencyCode || l10n.currency.defaultCode
    l10n.currency.symbol = getSymbolFromCurrency(currencyCode) || l10n.currency.defaultSymbol
  },
  /**
   * setCountry
   *
   * @param {string} countryCode - The currency code to use.
   *
   * @desc
   *  A function that sets the currency code and symbol of the l10n object.
   *
   */
  setCountry: countryCode => {
    l10n.countryCode = countryCode.toLowerCase()
  },
  setTranslations: translations => {
    l10n.t = translations || l10n.t
    l10n.t.locale = l10n.locale
  },
  setPromoTemplates: templates => {
    l10n.promoTemplates = templates || l10n.promoTemplates
  },
  setLocale: lob => {
    const localeTest = _.intersection([lob.isoLocale.toLowerCase()], lob.locales).length > 0,
      countryCode = lob.isoLocale.substr(3, 3).toLowerCase(),
      countryTest = cc => {
        const countryCodes = _.map(lob.locales, locale => {
          return locale.length > 4 ? locale.substr(3, 3) : ''
        })

        return cc.length > 0 ? _.intersection([cc.toLowerCase()], countryCodes).length > 0 : false
      }
    let localeCandidate = lob.isoLocale,
      lang = lob.isoLocale.substr(0, 2)

    // check if locale is supported
    if (localeTest) {
      localeCandidate = lob.isoLocale.toLowerCase()
    } else if (countryTest(lob.isoLocale.substr(3, 3))) {
      // use first available country locale
      localeCandidate = _.find(lob.locales, l => l.substr(3, 3) === countryCode)
    } else if (lob.generalLanguages[lang]) {
      // use general language if provided
      localeCandidate = lob.generalLanguages[lang]
    } else {
      // only set the locale if all values of localeObj are defined
      localeCandidate = process.env.REACT_APP_DEFAULT_LOCALE
    }

    l10n.locale = localeCandidate
    l10n.site = makeCMSSiteName(process.env.REACT_APP_CASINO_NAME, localeCandidate)
    l10n.langCode = localeCandidate.substr(0, 2)
    l10n.countryCode = localeCandidate.substr(3).toLowerCase()

    // set chat window language
    if (_.has(window, '$zopim.livechat.setLanguage')) {
      if (lob.isLoggedIn) {
        // zendesk expects lang as "xx" or "xx_XX" - force to match
        // https://develop.zendesk.com/hc/en-us/articles/360002037107-Changing-the-widget-language
        window.$zopim.livechat.setLanguage(`${l10n.langCode.toLowerCase()}_${l10n.countryCode.toUpperCase()}`)
      } else {
        window.$zopim.livechat.clearAll()
      }
    }

    // if we're using sentry, set sentry locale
    if (process.env.REACT_APP_ENABLE_SENTRY === 'true') {
      Sentry.setTag('page_locale', l10n.locale)
    }

    return l10n.locale
  },

  /**
   * localizeUrl
   *
   * @param {string} url - The url to localize.
   *
   * @desc
   *  A function that prefixes relative urls with the current locale.
   *
   */
  localizeUrl: url => {
    // make sure the url doesn't already have a locale

    if (!url.match(/\/[a-z]{2}-[a-z]{2,3}\//)) {
      return url && url !== '#' ? `/${l10n.locale}${url}` : url
    } else {
      return url
    }
  },

  browserDateLocale: () => {
    return l10n.locale !== 'en-row' ? l10n.locale : 'en-us'
  },

  /**
   * getLanguageCountryCode
   *
   * @desc
   *  A function to return combinations of language code (ISO 639-1) and country code (ISO 3166)
   *  e.g. - {language_COUNTRY}
   *
   */
  getLanguageCountryCode: () => {
    const locale = l10n.browserDateLocale()

    if (locale.length !== 0) {
      const [language, country] = locale.split('-')

      if (language && country) {
        return `${language}_${country.toUpperCase()}`
      }
    }

    return locale
  },

  /**
   * getCountryAttrByISO
   *
   * @param {string} isoCode - The two digit ISO abbreviation of the country.
   * @param {string} attribute - The country attribute to return.
   *
   * @desc
   *  A function to return the named attribute from the given country by 2-digit
   *  ISO code. Returns undefined if not found.
   */
  getCountryAttrByISO: (isoCode, attribute) => {
    let country = _.find(l10n.country, country => {
      return country.ISO.alpha2 === isoCode
    })
    if (country) {
      return country[attribute] || undefined
    }
  }
}

export default l10n
