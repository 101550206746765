import { closeOnboardingPopup } from '../../services/onboarding/onboarding'
import { config } from '../../components/Init/Init'
import has from 'lodash/has'
import redeemRewardNotification from '../../sweepstakes/redeemRewardNotification'
import { getPlayerStatus } from '../../services/helpers/playerHelper'
import { useCallback } from 'react'
import { useStateValue } from '../../services/state/StateProvider'
import { useRemoveClaimCenter } from './useRemoveClaimCenter'

export const useCloseNotification = removeNotification => {
  const [{ player, modals }, dispatch] = useStateValue()
  const { removeClaimCenter } = useRemoveClaimCenter()

  const closeNotification = useCallback(() => {
    dispatch({
      type: 'setModal',
      modals: {
        popup: {
          display: false,
          data: {}
        }
      }
    })

    closeOnboardingPopup(modals.popup.data.type, player.userId)

    if (config.enableSweepstakes && config.enableRedeemNotification && has(modals, 'popup.data.context.cta.rewardId')) {
      // Try to redeem when popup is getting closed (ie. via Close button)
      // redeemRewardNotification function will check if rewardId was already used last
      redeemRewardNotification(player, modals.popup.data.id, modals.popup.data.context.cta.rewardId)

      // When we first release the claim center, it may be possible for popups to be open but then
      // moved to the claim center in Firebase... make sure we remove from pending in claim center
      if (
        config.enableClaimCenter &&
        Object.values(config.claimCenterNotificationTypes).indexOf(modals.popup.data.type) >= 0
      ) {
        removeClaimCenter(modals.popup.data.id)
      }
    } else if (getPlayerStatus(player) === 'logged-in' && config.enableRemoveNotice) {
      // remove current notice
      let notificationId = modals.popup.data.id
      if (notificationId !== undefined) {
        removeNotification(notificationId)
      }
    }
  }, [dispatch, modals, player, removeClaimCenter, removeNotification])

  return { closeNotification }
}
