import _ from 'lodash'

export default function parseTranslations(result) {
  const translations = _.chain(result.data.entry.translations)
    .map(t => t.translationKeyValuePairs)
    .flatten()
    .keyBy('translationKey')
    .mapValues('translationValue')
    .value()
  return translations
}
