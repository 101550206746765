import { useEffect, useState } from 'react'
import { useStateValue } from '../../../state/StateProvider'
import { fetchAccountDetails } from '../../../../components/MyAccount/AccountUtil'
import { config } from '../../../../components/Init/Init'

export const usePlayerPIIFetchAccountDetails = ({ piiDataRequired, piiDataRequiredChecked }) => {
  const [{ player }, dispatch] = useStateValue()
  const [playerPIIDataLoading, setPlayerPIIDataLoading] = useState(false)

  useEffect(() => {
    if (config.enableSweepstakes && piiDataRequiredChecked && !piiDataRequired) {
      setPlayerPIIDataLoading(true)
      // we need to grab the country from the server, as submitting the pii doesn't store it on the client immediately
      fetchAccountDetails(
        player,
        dispatch,
        () => {
          setPlayerPIIDataLoading(false)
        },
        errorReason => {
          setPlayerPIIDataLoading(false)
          
          console.log('[Casino] Account Details - ERROR!', errorReason)
        }
      )
    }
  }, [piiDataRequired, piiDataRequiredChecked])

  return { playerPIIDataLoading }
}
