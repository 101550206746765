import unset from 'lodash/unset'
import { config } from '../../../components/Init/Init'
import { omitContentState } from '../../utils'

/**
 * @des this method omit 'casino.lobby' and 'player.activeTabId' from income state object and set it to localStorage
 * @param {object} state - player data object
 * @return void
 */
export const setAppTokenLocalStorage = state => {
  // to reduce localStorage memory usage
  const cloneState = omitContentState(state)

  // since activeTabId value should not be shared among another tabs, it is not set to localStorage
  if (state.player && state.player.activeTabId) {
    unset(cloneState, 'player.activeTabId')
  }

  // no need to store tax data (showMyAccountDrawer and taxIdRequired) in storage since on reload it became actual
  if (state.player && state.player.showMyAccountDrawer) {
    unset(cloneState, 'player.showMyAccountDrawer')
  }

  if (state.modals && state.modals.taxIdRequired) {
    unset(cloneState, 'modals.taxIdRequired')
  }
  
  localStorage.setItem(config.appToken, JSON.stringify(cloneState))
}
