import loadingTout from '../../static/ui/loading.json'
import { config } from '../../components/Init/Init'

function getInitLanguage() {
  let isoLang = 'en' // default to 'en' if window or navigator is not available

  if (typeof window !== 'undefined' && typeof navigator !== 'undefined') {
    const candidate = window.location.pathname.substring(1, 6)
    isoLang = navigator.language.substr(0, 2)

    // check url to set country
    if (candidate.match(/[a-z]{2}-[a-z]{2,3}/)) {
      isoLang = candidate.substr(0, 2)
    }
  }

  return isoLang
}

const anonymousPlayer = {
  casino: {
    lobby: {
      id: 0,
      banners: [],
      categories: []
    },
    sellSheet: loadingTout,
    promoInfo: loadingTout,
    animations: {},
    activeGame: {},
    chatBotOpen: false,
    gameRoundPending: false,
    reconcileGameBalanceAtEndOfRound: false,
    isPurchase: false,
    sweepstakesOrigin: {},
    sweepstakesOriginUrl: {},
    reloadGameBalance: false,
    sweepstakesOpen: false,
    logMxParam: '',
    logMxPrefixOverride: '',
    bypassMaintenanceParam: '',
    activeSubcategory: '',
    gameCabinetKey: '',
    customCategoriesAssets: [],
    isBoostOverlayShown: false,
    gameCabinetStaticUi: false,
    flashPrizes: [],
    flashPrizeGames: {},
    sweepsRealityCheckInfo: {},
    updateRealityCheckOnAllTabs: false,
    holdNotifications: false,
    activeNotificationId: null,
    disabledElements: {},
    dynamicOverlays: [],
    isDesktop: true,
    isPhone: false,
    isNarrowPhone: false,
    isEffectiveAreaPhone: false,
    featuredDisplay: {},
    gameCounts: {}
  },
  modals: {
    login: {
      display: false,
      data: {}
    },
    logout: {
      display: false,
      data: {}
    },
    session: {
      display: false,
      data: {}
    },
    sessionSet: {
      display: false,
      data: {}
    },
    reality: {
      display: false,
      data: {}
    },
    search: {
      display: false,
      data: {}
    },
    popup: {
      display: false,
      data: {}
    },
    messagePopper: {
      display: false,
      data: {}
    },
    lastLogin: {
      display: false,
      data: {}
    },
    providerRegionBlock: {
      display: false
    },
    walletDropdown: {
      display: false,
      data: {}
    },
    claimCenter: {
      display: false,
      data: {
        pending: {
          dailyBonus: null,
          bonusHarvest: null
        },
        expired: {
          dailyBonus: null,
          bonusHarvest: null
        },
        inboxMessageStatusSet: false,
        inboxMessageStatus: {},
        notificationsSet: false
      }
    },
    purchaseLimitKyc: {
      display: false,
      data: {
        reason: ''
      }
    },
    taxIdRequired: {
      display: false
    }
  },
  player: {
    country: '',
    preferredLobby: '',
    activeTabId: '',
    allowAutomaticLogin: true,
    tagsBanner: '[]',
    tagsInbox: '[]',
    tagsGames: '[]',
    firstTime: true,
    loginUser: {
      preferredLanguageCode: getInitLanguage()
    },
    loved: {},
    notices: [],
    promos: '',
    status: 'anonymous',
    accessToken: {
      encryptedToken: ''
    },
    refreshToken: {
      encryptedToken: ''
    },
    kycStatusInfo: {
      status: ''
    },
    taxInfoStatus: 'None',
    validateMethod: 'email',
    smsInfo: {
      smsVerified: false,
      lockedSmsVerified: false,
      smsVerifiedFunc: null,
      smsInfoChecked: false
    },
    purchaseLimitKycStatus: '',
    socialData: {},
    socialTimeToLive: 0,
    socialBankroll: 0,
    sweepsShowRedeemable: false,
    pendingNotifications: [],
    timePendingNotifications: [],
    lastGameLoaded: null,
    showMyAccountDrawer: false,
    store: {
      data: {
        products: [], // product jsons in the store
        blocked: false
      },
      missingBannerProducts: [] // uuids of the banner purchase products that are not found in the store
    }
  },
  timers: {},
  forms: {
    step2: {},
    step3: {
      currentDay: '',
      currentMonth: '',
      currentYear: '',
      days: [],
      months: []
    }
  },
  pamTrigger: {},
  rates: [],
  wallet: 0,
  previousGames: [],
  refreshCount: 0,
  timestampRefreshCount: 0
}

export { anonymousPlayer }
