import _ from 'lodash'
import { config } from '../../../components/Init/Init'
import { tokenizer } from '../../utils'
import HttpStatus from 'http-status-codes'
import loadingTout from '../../../static/ui/loading'
import { anonymousPlayer } from '../initialStates'
import l10n from '../../../components/i18n/I18N'
import { refreshSocial } from '../../../sweepstakes/messages'
import firebase from 'firebase/compat/app'
import * as Sentry from '@sentry/browser'
import { getPlayerStatus } from '../../helpers/playerHelper'
import { setAppTokenLocalStorage } from '../utils'

/**
 * playerReducer
 *
 * @desc
 *  A reducer that computes player state.
 *
 */

// while player is partially registered, set new registration flag so we don't
// load a pop up until the next log in
function setNewRegistration(current, isRegistering) {
  if (current.player.status === config.status.partial && config.suppressRegisterPopup) {
    current.casino.newRegistration = isRegistering
  }

  return current
}

function saveState(state, action, path) {
  const act = _.at(action, [path])

  // don't store content in local storage
  if (!(Object.keys(act).length === 1 && act.hasOwnProperty('validateMethod'))) {
    state.casino.sellSheet = loadingTout
    state.casino.promoInfo = loadingTout
  }

  setAppTokenLocalStorage({})

  // clear firebase app
  let toStore = _.clone(state)

  setAppTokenLocalStorage(toStore)
}

const playerActions = {
    login: function(state, action) {
      const registered = {
        ...setNewRegistration(state, true),
        player: {
          ...action.player,
          activeTabId: state.player.activeTabId
        },
        modals: {
          ...state.modals,
          login: {
            display: false
          }
        }
      }

      // don't store content in local storage
      registered.casino.sellSheet = loadingTout
      registered.casino.promoInfo = loadingTout

      // set cookie
      if (!action.skipStorage) {
        const toStore = _.clone(registered)

        setAppTokenLocalStorage(toStore)
      }

      // if we're using sentry, update sentry user
      if (process.env.REACT_APP_ENABLE_SENTRY === 'true') {
        Sentry.setUser({ id: registered.player.userId })
      }

      l10n.setLocale({
        isoLocale: `${registered.player.loginUser.preferredLanguageCode}-${registered.player.country}`,
        locales: config.locales,
        isLoggedIn: registered.player.status === config.status.registered,
        generalLanguages: config.generalLanguages
      })
      return registered
    },
    checkSessionTimeout: function(state, action) {
      if (action.player.verifySessionTimerCallback) {
        action.player.verifySessionTimerCallback()
      }
      return state
    },
    logout: function(state, action) {
      if (window.rmgBaseSubscriptions) {
        let db = window.rmgBase.database()
        window.rmgBaseSubscriptions.forEach(subscriberPath => db.ref(subscriberPath).off())
        window.rmgBaseSubscriptions = []
        window.sessionEventInitialResponseCalled = false
        window.sessionKickedInitialResponseCalled = false
      }
      if (window.casinoDBSubscriptions) {
        let casinoDB = window.casinoDB.database()
        window.casinoDBSubscriptions.forEach(subscriberPath => casinoDB.ref(subscriberPath).off())
        window.casinoDBSubscriptions = []
      }

      if (!config.allowTabDuplication) {
        sessionStorage.removeItem('activeTabId')
      }

      const logoutFromServer = refresh => {
        if (state.player.loginUser.userId === undefined) {
          return
        }

        try {
          fetch(
            config.apiUrl +
              tokenizer(config.logoutUrl, {
                '{userId}': state.player.loginUser.userId
              }),
            {
              method: 'DELETE',
              headers: {
                Accept: 'application/json',
                Authorization: `Bearer ${state.player.accessToken.encryptedToken}`
              }
            }
          )
            .then(response => {
              if (response.status === HttpStatus.OK) {
                if (refresh) {
                  // TODO: take rmgBase out of window
                  // remove firebase
                  firebase
                    .app()
                    .delete()
                    .then(() => {
                      window.rmgBase = undefined
                    })
                    .catch(e => console.log(['[logout] - Error deleting default Firebase app. ', e]))

                  firebase
                    .app('casinoDB')
                    .delete()
                    .then(() => {
                      window.casinoDB = undefined
                    })
                    .catch(e => console.log(['[logout] - Error deleting casinoDB Firebase app. ', e]))
                }
              }
            })
            .catch(err => {
              console.log('[logout] ERROR!', err)
            })
        } catch (e) {
          console.log('[player actions] error:', e)
        }
      }

      window.rmgBase
        .auth()
        .signOut()
        .then(() => {
          // if refresh is requested there's no need to return an anonymous
          // (logged out) player.
          if (action.player.refresh) {
            logoutFromServer(true)
          } else {
            logoutFromServer(false)
          }
        })
        .catch(err => {
          console.log('[logout] ERROR! Signing out of Firebase', err)
        })

      // if we're using sentry, update sentry user
      if (process.env.REACT_APP_ENABLE_SENTRY === 'true') {
        Sentry.setUser({})
      }
      l10n.setLocale({
        isoLocale: `${anonymousPlayer.player.loginUser.preferredLanguageCode}-${anonymousPlayer.player.country}`,
        locales: config.locales,
        isLoggedIn: false,
        generalLanguages: config.generalLanguages
      })

      const newState = {
        ...anonymousPlayer,
        player: {
          ...anonymousPlayer.player,
          allowAutomaticLogin: action.player.allowAutomaticLogin || anonymousPlayer.player.allowAutomaticLogin,
          activeTabId: state.player.activeTabId
        },
        casino: {
          ...anonymousPlayer.casino,
          sweepstakesOrigin: {
            ...state.casino.sweepstakesOrigin // we want to keep any context surrounding our sweepstakes origin
          }
        }
      }

      setAppTokenLocalStorage(newState)

      return _.cloneDeep(newState)
    },
    updatePlayer: function(state, action) {
      const updated = _.merge(
          _.has(action, 'player') && action.player ? action.player : state.player,
          action.newValues
        ),
        newState = {
          ...setNewRegistration(state, true),
          player: {
            ...updated,
            activeTabId: state.player.activeTabId
          }
        }

      saveState(newState, action, 'newValues')

      return newState
    },
    // NOTE: This update will not cause components to rerender and assume rerendering will happen at some point of time.
    // Workaround to avoid player seeing a loading screen.
    updateDetails: function(state, action) {
      state.player.username = action.newValues.username
      if (action.newValues.phoneNumber) {
        state.player.phoneNumber = action.newValues.phoneNumber
      }

      setAppTokenLocalStorage(state)

      return {
        ...state
      }
    },
    // NOTE: This update will not cause components to rerender and assume rerendering will happen at some point of time.
    // Workaround to avoid player seeing a loading screen.
    updateTokens: function(state, action) {
      state.player.accessToken = action.player.accessToken
      state.player.refreshToken = action.player.refreshToken

      setAppTokenLocalStorage(state)

      return {
        ...state
      }
    },
    // NOTE: This update will not cause components to rerender and assume rerendering will happen at some point of time.
    // Workaround to avoid player seeing a loading screen.
    updateActiveCurrency: function(state, action) {
      state.player.activeCurrencyId = action.player.activeCurrencyId
      state.player.loginUser.activeCurrencyId = action.player.activeCurrencyId

      setAppTokenLocalStorage(state)

      return {
        ...state
      }
    },
    // NOTE: This update will not cause components to rerender and assume rerendering will happen at some point of time.
    // Workaround to avoid player seeing a loading screen.
    updateKyc: function(state, action) {
      state.player.kycStatus = action.player.kycStatus
      state.player.loginUser.kycStatus = action.player.kycStatus
      state.player.taxInfoStatus = action.player.taxInfoStatus

      setAppTokenLocalStorage(state)

      return {
        ...state
      }
    },
    updateFreezeInfo: function(state, action) {
      state.player.freezeInfo = action.player.freezeInfo

      setAppTokenLocalStorage(state)

      return {
        ...state
      }
    },
    // NOTE: This update will not cause components to rerender and assume rerendering will happen at some point of time.
    // Workaround to avoid player seeing a loading screen.
    updateTcAccepted: function(state, action) {
      state.player.tcAccepted = action.player.tcAccepted
      state.player.loginUser.tcAccepted = action.player.tcAccepted

      setAppTokenLocalStorage(state)

      return {
        ...state
      }
    },
    smsVerification: function(state, action) {
      let newState = {
        ...state,
        player: {
          ...state.player,
          smsInfo: {
            ...state.player.smsInfo,
            ...action.player.smsInfo
          }
        }
      }

      setAppTokenLocalStorage(newState)

      return newState
    },
    smsVerificationFunc: function(state, action) {
      let newState = {
        ...state,
        player: {
          ...state.player,
          smsInfo: {
            ...state.player.smsInfo,
            smsVerifiedFunc: action.player.smsInfo.smsVerifiedFunc
          }
        }
      }

      setAppTokenLocalStorage(newState)

      return newState
    },
    updateSession: function(state, action) {
      let newState = {
        ...state,
        player: {
          ...state.player,
          Session: action.player.Session
        }
      }

      setAppTokenLocalStorage(newState)

      return newState
    },
    loveGame: function(state, action) {
      let newLoves = state.player

      // add loved player games if missing
      newLoves.loved[action.player.gameId] = !newLoves.loved[action.player.gameId]

      return {
        ...state,
        player: newLoves
      }
    },
    noteVisit: function(state, action) {
      return {
        ...state,
        player: {
          ...state.player,
          firstTime: action.player.firstTime
        }
      }
    },
    updateWallets: function(state, action) {
      const newState = {
        ...state,
        player: {
          ...state.player,
          wallets: action.player.wallets
        }
      }

      setAppTokenLocalStorage(newState)

      return newState
    },
    storeFirebaseToken: function(state, action) {
      const newState = {
        ...state,
        player: {
          ...state.player,
          firebaseToken: action.player.firebaseToken
        }
      }

      setAppTokenLocalStorage(newState)

      return newState
    },
    storeNotices: function(state, action) {
      let newState = {
        ...state,
        player: {
          ...state.player,
          notices: action.player.notices
        }
      }

      setAppTokenLocalStorage(newState)

      return newState
    },
    triggerLimit: function(state, action) {
      const limitMessages = {
          Wager: ['WagerLimitProcessor'],
          Loss: ['LossLimitsProcessor']
        },
        limit = limitMessages[action.player.limitReached]

      console.log('[Player action: triggerLimit] limit', limit, action.player.limitNotice)
      return {
        ...state,
        player: {
          ...state.player,
          limitTriggered: limit,
          limitNotice: action.player.limitNotice
        }
      }
    },
    refreshPlayerToken: function(state) {
      if (getPlayerStatus(state.player) === 'logged-in') {
        fetch(config.apiUrl + config.tokenRefreshUrl, {
          method: 'POST',
          mode: 'cors',
          body: JSON.stringify({
            platform: 1,
            localeTag: l10n.langCode,
            loginType: config.reUpType,
            identifier: state.player.loginUser.emailAddress
              ? state.player.loginUser.emailAddress
              : state.player.loginUser.email,
            context: state.player.refreshToken.encryptedToken,
            timezoneOffset: config.timezone,
            analyticsData: {
              browser: window.navigator.appVersion,
              userIp: '1.1.1.1',
              playVersion: config.version,
              clientApplicationId: config.clientApplicationId,
              device: 'browser',
              applicationId: config.applicationId,
              originId: config.originId
            }
          }),
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${state.player.accessToken.encryptedToken}`
          }
        })
          .then(response => {
            if (response.status === HttpStatus.OK) {
              response.json().then(newToken => {
                // TODO: do this using the state machine
                // update player tokens
                state.player.accessToken = newToken.accessToken
                state.player.refreshToken = newToken.refreshToken
                state.player.timestamp = Date.now()

                setAppTokenLocalStorage(state)

                console.log(`[refreshPlayerToken] - SUCCESS!`)
              })
            } else {
              console.log(`[refreshPlayerToken - ${state.refreshCount}] - ERROR!`, response)
            }
          })
          .catch(err => {
            console.log('[reUpAuthToken]', err)
          })
      }

      return {
        ...state,
        refreshCount: state.refreshCount + 1
      }
    },
    refreshSocialSession: function(state) {
      if (getPlayerStatus(state.player) === 'logged-in') {
        refreshSocial()
      }
      return { ...state }
    },
    refreshPlayerTimestamp: function(state) {
      if (getPlayerStatus(state.player) === 'logged-in') {
        state.player.timestamp = Date.now()

        setAppTokenLocalStorage(state)
      }
      return {
        ...state,
        timestampRefreshCount: state.timestampRefreshCount + 1
      }
    },
    updatePreviousGames: function(state, action) {
      const newState = {
        ...state,
        player: {
          ...state.player,
          previousGames: action.player.previousGames
        }
      }

      setAppTokenLocalStorage(newState)

      return newState
    },
    updateLastGameLoaded: function(state, action) {
      const newState = {
        ...state,
        player: {
          ...state.player,
          lastGameLoaded: action.player.lastGameLoaded
        }
      }

      setAppTokenLocalStorage(newState)

      return newState
    },
    setSocialData: function(state, action) {
      const newState = {
        ...state,
        player: {
          ...state.player,
          socialData: action.player.socialData
        }
      }

      setAppTokenLocalStorage(newState)

      return newState
    },
    setSocialBankroll: function(state, action) {
      const newState = {
        ...state,
        player: {
          ...state.player,
          socialBankroll: action.player.socialBankroll
        }
      }

      setAppTokenLocalStorage(newState)

      return newState
    },
    updateSweepsShowRedeemable: function(state, action) {
      state.player.sweepsShowRedeemable = action.player.sweepsShowRedeemable

      setAppTokenLocalStorage(state)

      return {
        ...state
      }
    },
    setPendingNotifications: function(state, action) {
      return {
        ...state,
        player: {
          ...state.player,
          pendingNotifications: action.player.pendingNotifications
        }
      }
    },
    setTimePendingNotifications: function(state, action) {
      return {
        ...state,
        player: {
          ...state.player,
          timePendingNotifications: action.player.timePendingNotifications
        }
      }
    },
    setStoreData: function(state, action) {
      const newState = {
        ...state,
        player: {
          ...state.player,
          store: {
            ...state.player.store,
            data: action.player.storeData
          }
        }
      }

      setAppTokenLocalStorage(newState)

      return newState
    },
    setMissingBannerProducts: function(state, action) {
      const newState = {
        ...state,
        player: {
          ...state.player,
          store: {
            ...state.player.store,
            missingBannerProducts: action.player.store.missingBannerProducts
          }
        }
      }

      setAppTokenLocalStorage(newState)

      return newState
    },
    setShowMyAccountDrawer: function(state, action) {
      return {
        ...state,
        player: {
          ...state.player,
          showMyAccountDrawer: action.player.showMyAccountDrawer
        }
      }
    }
  },
  playerReducer = (state, action) => {
    if (_.has(playerActions, action.type)) {
      return playerActions[action.type](state, action)
    } else {
      return state
    }
  }

export default playerReducer
