import React, { Suspense } from 'react'
import { createRoot } from 'react-dom/client'
import * as Sentry from '@sentry/browser'
import { Init } from './components/Init/Init'
import * as serviceWorker from './serviceWorker'
import LoadingPage from './components/LoadingPage/LoadingPage'
import { isIOS } from 'react-device-detect'
import defineCustomElements from './customElements'

// Import conflicting CSS files in root to avoid build issues with mini-css-extract-plugin.
import './components/Casino/Casino.scss'
import './components/GameCabinet/GameCabinet.scss'
import './components/Leaderboard/Leaderboard.scss'
import './components/H5Modal/H5Modal.scss'
import './sweepstakes/SweepsWalletDropdown/SweepsWalletDropdown.scss'
import './sweepstakes/SweepsWalletToggle/SweepsWalletToggle.scss'

defineCustomElements()

if (process.env.REACT_APP_ENABLE_SENTRY === 'true') {
  Sentry.init({
    dsn: process.env.REACT_APP_SENTRY_DSN,
    environment: 'undefined',
    ignoreErrors: ['ResizeObserver', 'chrome-extension', 'safari-web-extension', 'moz-extension']
  })
}

class ErrorBoundary extends React.Component {
  state = { error: null }
  static getDerivedStateFromError(error) {
    return { error }
  }
  render() {
    const { error } = this.state
    if (error) {
      return <this.props.FallbackComponent error={error} />
    }
    return this.props.children
  }
}
const ErrorFallback = ({ error }) => {
  let message = ''
  if (error) {
    if (error.message) {
      message = error.message
    } else {
      message = JSON.stringify(error)
    }
  }
  return <div>ERROR MESSAGE: {message}</div>
}

const isLite = window.location.pathname.includes('game/R/') && isIOS
const isLoading = window.location.pathname.indexOf('/loading') === 0
const H5G = React.lazy(() => import('./H5G'))
const SweepsWrapper = React.lazy(() => import('./components/CasinoLite/SweepsWrapper'))

const root = createRoot(document.getElementById('root'))

root.render(
  isLoading ? (
    <LoadingPage />
  ) : (
    <ErrorBoundary FallbackComponent={ErrorFallback}>
      <Init ready={() => <Suspense fallback={<LoadingPage />}>{isLite ? <SweepsWrapper /> : <H5G />}</Suspense>} />
    </ErrorBoundary>
  )
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
