import { useEffect, useState } from 'react'
import { useQuery } from '@apollo/client'
import { GET_GAME_OBJECTS } from '../../services/queries'
import l10n from '../../components/i18n/I18N'

const useGetGames = () => {
  const [fullGameList, setFullGameList] = useState({})
  const { data, error } = useQuery(GET_GAME_OBJECTS, {
    variables: {
      section: 'games',
      site: l10n.site,
      gameId: null
    }
  })

  useEffect(() => {
    if (data && !error) {
      setFullGameList(data.entries)
    } else if (error) {
      console.log('[useGetGames]', error)
    }
  }, [data, error])

  return { fullGameList }
}

export { useGetGames }
