import { useCallback } from 'react'
import _ from 'lodash'
import { useApolloClient } from '@apollo/client'
import { useStateValue } from '../../services/state/StateProvider'
import { getGameObjectsBatch } from '../../services/queries'

export const useLoadMissingGames = (componentName = 'Lobby') => {
  const [{ casino }, dispatch] = useStateValue()
  const client = useApolloClient()

  const loadMissingGames = useCallback(
    async gameIds => {
      const missingGames = _.difference(gameIds, _.keys(casino.games))

      if (missingGames.length) {
        const result = await client
          .query({
            query: getGameObjectsBatch(missingGames)
          })
          .catch(e => {
            console.log(`[${componentName}] Failed fetching missing games!`, e)
          })

        if (result) {
          dispatch({
            type: 'addGames',
            casino: {
              games: _.map(result.data, g => _.omit(g, '__typename'))
            }
          })
        }
      }
    },
    [casino.games, dispatch, client, componentName]
  )

  return { loadMissingGames }
}
