import { gql } from '@apollo/client'
import _ from 'lodash'

const GET_FORM_DATA = gql`
  query GetFormData($section: [String], $locale: [String], $slug: [String]) {
    entry(section: $section, site: $locale, slug: $slug) {
      title
      slug
      siteId
      id
      ... on forms_simpleForm_Entry {
        id
        formCtaText
        formDescription
        formDisplayTitle
        formFields {
          ... on formFields_formDropdown_BlockType {
            id
            formDropdownDescription
            formDropdownRequired
            formDropdownTitle
            formDropdownName
            formDropdownOptions {
              option
            }
            typeHandle
          }
          ... on formFields_formTextarea_BlockType {
            id
            formTextareaTitle
            formTextareaName
            formTextareaRequired
            formTextareaDescription
            typeHandle
          }
          ... on formFields_formCheckbox_BlockType {
            id
            formCheckboxName
            formCheckboxTitle
            formCheckboxRequired
            formCheckboxDescription
            typeHandle
          }
        }
      }
      typeHandle
    }
  }
`

const GET_TRANSLATIONS = gql`
  query GetTranslations($section: [String], $locale: [String], $slug: [String]) {
    entry(section: $section, site: $locale, slug: $slug) {
      ... on translationBundles_translationBundle_Entry {
        translations {
          ... on translations_translation_Entry {
            translationKeyValuePairs {
              translationKey
              translationValue
            }
          }
        }
      }
    }
  }
`

const GET_LOBBY = bannersVersion => gql`
  query GetLobby(
    $section: [String]
    $locale: [String]
    $slug: [String]
    $limit: Int
    $offset: Int
    $categoryId: [QueryArgument]
    $withBanners: Boolean!
    $withCategoriesMetadata: Boolean!
    $withJackpots: Boolean!
  ) {
    entry(section: $section, site: $locale, slug: $slug) {
      ... on lobbies_lobby_Entry {
        lobbyId
        siteId
        slug
        typeHandle
        sectionHandle
        ${
          !bannersVersion || bannersVersion === 1
            ? `banners @include(if: $withBanners) {
                ... on banners_banner_BlockType {
                  siteId
                  typeHandle
                  id
                  tout
                }
              }`
            : `bannersv2 @include(if: $withBanners) {
                ... on banners_banner_Entry {
                  title
                  alignment
                  bannerTextColor
                  bgImageDesktopJpg {
                    url
                  }
                  bgImageDesktopWebp {
                    url
                  }
                  bgImageMobileLandscapeJpg {
                    url
                  }
                  bgImageMobileLandscapeWebp {
                    url
                  }
                  bgImageMobilePortraitJpg {
                    url
                  }
                  bgImageMobilePortraitWebp {
                    url
                  }
                  bannerTextLine1
                  bannerTextLine2
                  bannerTextLine3
                  bannerTextLine4
                  bannerTextLine5
                  bannerTextLine6
                  bannerTextLine7
                  bannerTextLine8
                  bannerTextLine9
                  bannerTextLine10
                  bannerTextLine11
                  bannerTextLine12
                  ctaButtonText
                  ctaButtonAction
                  ctaUrl
                  bannerCtaColor
                  bannerTags
                }
                ... on banners_jsonBanner_Entry {
                  siteId
                  typeHandle
                  id
                  tout
                  bannerTags
                }
                ... on banners_timerBanner_Entry {
                  title
                  bannerTextColor
                  deadline
                  timerText
                  expiredText
                  bgImageDesktopJpg {
                    url
                  }
                  bgImageDesktopWebp {
                    url
                  }
                  bgImageMobileLandscapeJpg {
                    url
                  }
                  bgImageMobileLandscapeWebp {
                    url
                  }
                  bgImageMobilePortraitJpg {
                    url
                  }
                  bgImageMobilePortraitWebp {
                    url
                  }
                  bannerTextLine1
                  bannerTextLine2
                  bannerTextLine3
                  bannerTextLine4
                  bannerTextLine5
                  bannerTextLine6
                  bannerTextLine7
                  bannerTextLine8
                  bannerTextLine9
                  bannerTextLine10
                  bannerTextLine11
                  bannerTextLine12
                  ctaButtonText
                  ctaButtonAction
                  ctaUrl
                  bannerTags
                }
              }`
        }
        lobbyCategoryAssortments(id: $categoryId) {
          ... on categoryAssortments_categoryAssortment_Entry {
            id
            siteId
            slug
            typeHandle
            sectionHandle
            lobbyGameCategory @include(if: $withCategoriesMetadata) {
              ... on gameCategories_Category {
                siteId
                slug
                groupHandle
                title
                gameCategoryDisplayType
                gameCategoryId
                gameCategoryName
                gameCategoryTemplate
                gameCategoryCustomLayout
                gameCategorySubcategories {
                  subcategoryId
                }
                gameCategoryDescriptionTitle
                gameCategoryDescription
              }
            }
            lobbyJackpot @include(if: $withJackpots) {
              ... on jackpots_jackpot_Entry {
                title
                jackpotGames {
                  ... on games_game_Entry {
                    siteId
                    slug
                    typeHandle
                    sectionHandle
                    allowDemo
                    gameId
                    title
                    provider
                    live
                    gameTags
                  }
                }
                jackpotBackgroundImageMobile {
                  url
                }
                jackpotBackgroundImage {
                  url
                }
              }
            }
            lobbyGameTouts(limit: $limit, offset: $offset) {
              ... on lobbyGameTouts_lobbyGameTout_BlockType {
                siteId
                typeHandle
                id
                game {
                  ... on games_game_Entry {
                    siteId
                    slug
                    typeHandle
                    sectionHandle
                    allowDemo
                    gameId
                    title
                    provider
                    live
                    gameTags
                  }
                }
                gameTemplate
              }
            }
          }
          _count(field: "lobbyGameTouts")
        }
      }
    }
  }
`

const GET_LOBBY_OVERLAYS = gql`
  query GetLobbyOverlays($locale: [String]) {
    entries(section: "dynamicOverlays", site: $locale) {
      slug
      title
      ... on dynamicOverlays_dynamicOverlay_Entry {
        overlayIncludedGames {
          ... on games_game_Entry {
            gameId
          }
        }
        overlayExcludedCategoryAssortments {
          ... on categoryAssortments_categoryAssortment_Entry {
            lobbyGameCategory {
              ... on gameCategories_Category {
                gameCategoryName
              }
            }
          }
        }
        overlayImageMobile {
          url
        }
        overlayImageDesktopLarge {
          url
        }
        overlayImageDesktop {
          url
        }
      }
    }
  }
`

const GET_CUSTOM_CATEGORIES_ASSETS = gql`
  query GetCustomCategoriesAssets($locale: [String], $slug: [String]) {
    entries(section: "customCategories", site: $locale, slug: $slug) {
      ... on customCategories_customCategory_Entry {
        id
        slug
        customCategoryTextColor
        desktopBackgroundImage {
          url
        }
        desktopLogo {
          url
        }
        mobileBackgroundImage {
          url
        }
        smallMobileBackgroundImage {
          url
        }
        mobileLogo {
          url
        }
        tabletBackgroundImage {
          url
        }
        tabletLogo {
          url
        }
      }
    }
  }
`

const GET_SLUGS = gql`
  query GetLobbiesSlugs($type: [String]) {
    entries(type: $type) {
      slug
    }
  }
`

const GET_PAGE = gql`
  query GetPage($slug: [String], $site: [String]) {
    entries(section: "pages", slug: $slug, site: $site) {
      title
      ... on pages_page_Entry {
        contentBlock {
          ... on contentBlock_asset_BlockType {
            typeHandle
            contentKey
            asset {
              url
            }
          }
          ... on contentBlock_text_BlockType {
            typeHandle
            contentKey
            text
          }
          ... on contentBlock_formattedText_BlockType {
            typeHandle
            contentKey
            formattedText
          }
        }
      }
    }
  }
`

const GET_MESSAGES = gql`
  query GetMessages($section: [String], $locale: [String]) {
    entries(section: $section, site: $locale, orderBy: "postDate DESC") {
      ... on messages_textMessage_Entry {
        id
        slug
        postDate
        expiryDate
        title
        inboxText
        inboxImage {
          url
        }
        timerWidget {
          ... on timerWidget_timer_BlockType {
            timerOption
            timerTextExpired
            timerTextUnexpired
          }
        }
        messageSubject
        messageSubjectAlignment
        messageBody
        messageImage {
          url
        }
        linkWidget {
          ... on linkWidget_link_BlockType {
            linkUrl
            linkAction
          }
        }
        messageFooterText
        messageTags
      }
      ... on messages_deeplinkMessage_Entry {
        id
        slug
        postDate
        expiryDate
        title
        inboxText
        inboxImage {
          url
        }
        timerWidget {
          ... on timerWidget_timer_BlockType {
            timerOption
            timerTextExpired
            timerTextUnexpired
          }
        }
        messageSubject
        messageSubjectAlignment
        messageBody
        messageImage {
          url
        }
        linkWidget {
          ... on linkWidget_link_BlockType {
            linkUrl
            linkAction
          }
        }
        messageFooterText
        messageTags
        imageLink1Image {
          url
        }
        imageLink1Label
        imageLink1ButtonLabel
        imageLink1Url
        imageLink1Action
        imageLink2Image {
          url
        }
        imageLink2Label
        imageLink2ButtonLabel
        imageLink2Url
        imageLink2Action
        textLink1Label
        textLink1AsButton
        textLink1Url
        textLink1Action
      }
    }
  }
`

const GET_MESSAGES_MINIMAL = gql`
  query GetMessages($section: [String], $locale: [String]) {
    entries(section: $section, site: $locale, orderBy: "postDate DESC") {
      ... on messages_textMessage_Entry {
        slug
        id
        expiryDate
        messageTags
      }
      ... on messages_deeplinkMessage_Entry {
        slug
        id
        expiryDate
        messageTags
      }
    }
  }
`

const GET_MESSAGE_TEMPLATES = gql`
  query GetMessageTemplates($section: [String], $locale: [String]) {
    entries(section: $section, site: $locale) {
      ... on messageTemplates_textMessageTemplate_Entry {
        id
        slug
        postDate
        expiryDate
        title
        inboxText
        inboxImageTemplate {
          url
        }
        inboxImagePathTemplate
        timerWidget {
          ... on timerWidget_timer_BlockType {
            timerOption
            timerTextExpired
            timerTextUnexpired
          }
        }
        messageSubject
        messageSubjectAlignment
        messageBody
        messageImageTemplate {
          url
        }
        messageImagePathTemplate
        linkWidget {
          ... on linkWidget_link_BlockType {
            linkUrl
            linkAction
          }
        }
        messageFooterText
        messageTags
        rewardScTextTemplate
        rewardGcTextTemplate
        rewardDiaTextTemplate
      }
      ... on messageTemplates_deeplinkMessageTemplate_Entry {
        id
        slug
        postDate
        expiryDate
        title
        inboxText
        inboxImageTemplate {
          url
        }
        inboxImagePathTemplate
        timerWidget {
          ... on timerWidget_timer_BlockType {
            timerOption
            timerTextExpired
            timerTextUnexpired
          }
        }
        messageSubject
        messageSubjectAlignment
        messageBody
        messageImageTemplate {
          url
        }
        messageImagePathTemplate
        linkWidget {
          ... on linkWidget_link_BlockType {
            linkUrl
            linkAction
          }
        }
        messageFooterText
        messageTags
        rewardScTextTemplate
        rewardGcTextTemplate
        rewardDiaTextTemplate
        imageLink1ImageTemplate {
          url
        }
        imageLink1PathTemplate
        imageLink1Label
        imageLink1ButtonLabel
        imageLink1Url
        imageLink1Action
        imageLink2ImageTemplate {
          url
        }
        imageLink2PathTemplate
        imageLink2Label
        imageLink2ButtonLabel
        imageLink2Url
        imageLink2Action
        textLink1Label
        textLink1AsButton
        textLink1Url
        textLink1Action
      }
    }
  }
`

const GET_GAME_OBJECTS = gql`
  query GetGameTitles($section: [String], $site: [String], $gameId: [QueryArgument]) {
    entries(section: $section, site: $site, gameId: $gameId) {
      ... on games_game_Entry {
        id: gameId
        siteId
        slug
        typeHandle
        sectionHandle
        allowDemo
        title
        provider
        live
        gameId
        gameTags
      }
    }
  }
`

const getGameObjectsBatch = gameIds => gql`
  fragment Game on games_game_Entry {
    id: gameId
    siteId
    slug
    typeHandle
    sectionHandle
    allowDemo
    title
    provider
    live
    gameId
    gameTags
  }

  query GetGameTitlesBatch($section: [String], $site: [String]) {
    ${_.reduce(
      gameIds,
      (query, gameId) =>
        query +
        `
        Game${gameId}:entry(section: $section, site: $site, gameId: "${gameId}") {
          ...Game
        }
      `,
      ''
    )}
  }
`

const GET_GAME_LABELS = gql`
  query GetGameLabels($section: [String], $site: [String]) {
    entries(section: $section, site: $site) {
      ... on games_game_Entry {
        id: gameId
        gameLabels
      }
    }
  }
`

export {
  GET_FORM_DATA,
  GET_LOBBY,
  GET_LOBBY_OVERLAYS,
  GET_SLUGS,
  GET_PAGE,
  GET_MESSAGES,
  GET_MESSAGES_MINIMAL,
  GET_MESSAGE_TEMPLATES,
  GET_TRANSLATIONS,
  GET_GAME_OBJECTS,
  GET_CUSTOM_CATEGORIES_ASSETS,
  GET_GAME_LABELS,
  getGameObjectsBatch
}
