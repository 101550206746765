import { useStateValue } from '../../services/state/StateProvider'
import { useState } from 'react'

export const usePost = () => {
  const [{ player }] = useStateValue()
  const [isLoading, setIsLoading] = useState(false)

  const POST = (url, data) => {
    setIsLoading(true)

    return fetch(url, {
      method: 'POST',
      mode: 'cors',
      body: JSON.stringify(data),
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        ApplicationToken: player.socialData.applicationToken,
        Authorization: `Bearer ${player.accessToken.encryptedToken}`
      }
    })
      .then(data => data.json())
      .catch(error => error)
      .finally(() => setIsLoading(false))
  }

  return { POST, isLoading }
}
