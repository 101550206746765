import _ from 'lodash'

import { config } from '../../../src/components/Init/Init'
import { tokenizer } from '../utils'

const openOnboardingPopup = (status, userId, popupType, dispatch) => {
  if (status === config.status.registered && _.get(config, 'onboarding.enabled')) {
    const item = localStorage.getItem(
      tokenizer(config.onboarding.key, {
        '{userId}': userId
      })
    )
    if (item) {
      const onboarding = JSON.parse(item)
      if (onboarding && onboarding.popups && onboarding.popups[popupType] === true) {
        dispatch({
          type: 'setSingleModal',
          modals: {
            popup: {
              display: true,
              data: {
                type: popupType,
                context: {}
              }
            }
          }
        })
        return true
      }
    }
  }
  return false
}

const closeOnboardingPopup = (popupType, userId) => {
    // Check if popup is part of onboarding
    if (
      _.get(config, 'onboarding.enabled') &&
      config.onboarding.popups &&
      config.onboarding.popups[popupType]
    ) {
      const key = tokenizer(config.onboarding.key, {
        '{userId}': userId
      })
      const item = localStorage.getItem(key)
      if (item) {
        const onboarding = JSON.parse(item)
        if (onboarding && onboarding.popups && onboarding.popups[popupType] === true) {
          onboarding.popups[popupType] = false
          localStorage.setItem(key, JSON.stringify(onboarding))
        }
      }
    }
}

const initializeOnboarding = (loggedIn, userId, notices) => {
    // Rely on data flow to initiate onboarding. sweeps_welcome is the welcome popup for new players.
    if (
      loggedIn &&
      _.get(config, 'onboarding.enabled') &&
      _.find(notices, { type: config.onboarding.onboardingTrigger })
    ) {
      const key = tokenizer(config.onboarding.key, {
        '{userId}': userId
      })
      if (!localStorage.getItem(key)) {
        localStorage.setItem(key, JSON.stringify(config.onboarding))
      }
    }
}

export {
  openOnboardingPopup,
  closeOnboardingPopup,
  initializeOnboarding
}