import { config } from '../../components/Init/Init'
import { claimCenterTimeoutReached, claimCenterTimeoutReachedRetry } from '../events'
import {
  convertNextTriggerTimeToMs,
  getCurrencySymbol,
  getFeatureEnabledForPlayer,
  isNotificationTimePending
} from '../../services/utils'

/**
 * @desc Parse the claim center data and start a timer for next trigger time, if we have one
 * @returns {void}
 */
const updateClaimCenterTimer = (claimCenterData, player, dispatch) => {
  let now = Date.now()
  let next = 0
  let nextRetry = 0

  const updateTriggerTimerValues = nextTriggerTime => {
    // When we reach our nextTriggerTime (plus claimCenterTimerReachedDelayMs), send an
    // action code to the server. This will be used to update notifications in Firebase.
    let tmpNext = convertNextTriggerTimeToMs(nextTriggerTime) + config.claimCenterTimerReachedDelayMs
    if ((next <= 0 || tmpNext < next) && tmpNext > now) {
      next = tmpNext
    }

    // In case the client clock is off more than a second or two, send another action
    // code when we reach another claimCenterTimerReachedRetryMs after the above action
    // code. This will likely be hit very rarely, since it resets once Firebase updates.
    if (config.claimCenterTimerReachedRetryMs > 0) {
      let tmpNextRetry = tmpNext + config.claimCenterTimerReachedRetryMs
      if ((nextRetry <= 0 || tmpNextRetry < nextRetry) && tmpNextRetry > now) {
        nextRetry = tmpNextRetry
      }
    }
  }

  if (claimCenterData.pending) {
    if (
      claimCenterData.pending.dailyBonus &&
      claimCenterData.pending.dailyBonus.context &&
      claimCenterData.pending.dailyBonus.context.nextTriggerTime
    ) {
      updateTriggerTimerValues(claimCenterData.pending.dailyBonus.context.nextTriggerTime)
    }
    if (
      claimCenterData.pending.bonusHarvest &&
      claimCenterData.pending.bonusHarvest.context &&
      claimCenterData.pending.bonusHarvest.context.nextTriggerTime
    ) {
      updateTriggerTimerValues(claimCenterData.pending.bonusHarvest.context.nextTriggerTime)
    }
  }
  if (claimCenterData.expired) {
    if (
      claimCenterData.expired.dailyBonus &&
      claimCenterData.expired.dailyBonus.context &&
      claimCenterData.expired.dailyBonus.context.nextTriggerTime
    ) {
      updateTriggerTimerValues(claimCenterData.expired.dailyBonus.context.nextTriggerTime)
    }
    if (
      claimCenterData.expired.bonusHarvest &&
      claimCenterData.expired.bonusHarvest.context &&
      claimCenterData.expired.bonusHarvest.context.nextTriggerTime
    ) {
      updateTriggerTimerValues(claimCenterData.expired.bonusHarvest.context.nextTriggerTime)
    }
  }

  // Handle starting a timer for the next claim (or clear it if there isn't one available)
  if (next > now) {
    dispatch({
      type: 'addTimer',
      timers: [
        {
          name: config.timerNames.nextClaimCenter,
          trigger: next,
          verifyExpired: true,
          repeat: false,
          actions: [
            {
              type: 'callCallback',
              casino: {
                callback: () => {
                  claimCenterTimeoutReached(player)
                }
              }
            }
          ]
        }
      ]
    })
  } else {
    dispatch({
      type: 'removeTimer',
      timers: [],
      name: config.timerNames.nextClaimCenter
    })
  }

  // Handle starting a timer for the retry of the next claim (or clear it if there isn't one available)
  if (nextRetry > now) {
    dispatch({
      type: 'addTimer',
      timers: [
        {
          name: config.timerNames.nextClaimCenterRetry,
          trigger: nextRetry,
          verifyExpired: true,
          repeat: false,
          actions: [
            {
              type: 'callCallback',
              casino: {
                callback: () => {
                  claimCenterTimeoutReachedRetry(player)
                }
              }
            }
          ]
        }
      ]
    })
  } else {
    dispatch({
      type: 'removeTimer',
      timers: [],
      name: config.timerNames.nextClaimCenterRetry
    })
  }
}

const isInboxEnabled = playerId => {
  return getFeatureEnabledForPlayer(config.enableInbox, config.inboxEnabledRolloutPercentage, playerId)
}

const isInboxMessageRead = (inboxMessageStatus, messageId) => {
  if (inboxMessageStatus && inboxMessageStatus[messageId]) {
    return inboxMessageStatus[messageId]['read'] === true
  }
  return false
}

const isInboxMessageClaimed = (inboxMessageStatus, messageId) => {
  if (inboxMessageStatus && inboxMessageStatus[messageId]) {
    return inboxMessageStatus[messageId]['claimed'] === true
  }
  return false
}

const doInboxMessageStatusesExistForOtherMessages = (inboxMessageStatus, messageIds) => {
  for (const key in inboxMessageStatus) {
    if (messageIds.indexOf(key) < 0) {
      return true
    }
  }
  return false
}

const updateInboxV1RefreshTimer = (messages, dispatch, refreshCallback, timers) => {
  // find the next expiration time, we'll refresh then
  let nextExpiration = null
  for (let i = 0; i < messages.length; i++) {
    if (messages[i].expiryDate) {
      let expiration = new Date(messages[i].expiryDate)
      if (!nextExpiration || expiration < nextExpiration) {
        nextExpiration = expiration
      }
    }
  }

  if (nextExpiration) {
    // call the refresh method when the expiration time is reached
    dispatch({
      type: 'addTimer',
      timers: [
        {
          name: config.timerNames.nextInboxV1Expiration,
          trigger: nextExpiration.getTime(),
          verifyExpired: true,
          repeat: false,
          actions: [
            {
              type: 'callCallback',
              casino: {
                callback: refreshCallback
              }
            }
          ]
        }
      ]
    })
  } else {
    // clear the existing timer, if there is one
    if (timers[config.timerNames.nextInboxV1Expiration]) {
      dispatch({
        type: 'removeTimer',
        timers: [],
        name: config.timerNames.nextInboxV1Expiration
      })
    }
  }
}

const updateInboxV2RefreshTimer = (notifications, dispatch, refreshCallback, timers) => {
  // find the next start time (if not yet started) or expiration time, we'll refresh then
  let nextRefresh = null
  if (notifications) {
    for (let i = 0; i < notifications.length; i++) {
      if (notifications[i] && notifications[i].context) {
        if (isNotificationTimePending(notifications[i])) {
          let start = new Date(notifications[i].context.popupStartDate)
          if (!nextRefresh || start < nextRefresh) {
            nextRefresh = start
          }
        }
        if (notifications[i].context.popupEndDate) {
          let expiration = new Date(notifications[i].context.popupEndDate)
          if (!nextRefresh || expiration < nextRefresh) {
            nextRefresh = expiration
          }
        }
      }
    }
  }

  if (nextRefresh) {
    // call the refresh method when the refresh time is reached
    dispatch({
      type: 'addTimer',
      timers: [
        {
          name: config.timerNames.nextInboxV2Expiration,
          trigger: nextRefresh.getTime(),
          verifyExpired: true,
          repeat: false,
          actions: [
            {
              type: 'callCallback',
              casino: {
                callback: refreshCallback
              }
            }
          ]
        }
      ]
    })
  } else {
    // clear the existing timer, if there is one
    if (timers[config.timerNames.nextInboxV2Expiration]) {
      dispatch({
        type: 'removeTimer',
        timers: [],
        name: config.timerNames.nextInboxV2Expiration
      })
    }
  }
}

const getStorePriceDisplayString = (originalPrice, currencyCode) => {
  // add commas
  let price = originalPrice.toLocaleString('en-US')

  // if we happen to have exactly one decimal place, add a zero
  // (if we have zero decimal places, leave it as is)
  let periodIndex = price.indexOf('.')
  if (periodIndex > 0 && periodIndex == price.length - 2) {
    price += '0'
  }

  // get the currency symbol
  let symbol = getCurrencySymbol(currencyCode || '')

  // return the price with currency
  return symbol + price
}

export {
  updateClaimCenterTimer,
  isInboxEnabled,
  isInboxMessageRead,
  isInboxMessageClaimed,
  doInboxMessageStatusesExistForOtherMessages,
  updateInboxV1RefreshTimer,
  updateInboxV2RefreshTimer,
  getStorePriceDisplayString
}
