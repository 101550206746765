import _ from 'lodash'

const ratesActions = {
    setRates: function (state, action) {
      return {
        ...state,
        rates: action.rates
      }
    }
  },
  ratesReducer = (state, action) => {
    if (_.has(ratesActions, action.type)) {
      return ratesActions[action.type](state, action)
    } else {
      return state
    }
  }

export default ratesReducer
