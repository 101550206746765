import { useMemo } from 'react'
import { useStateValue } from '../services/state/StateProvider'
import { isIOS, isSafari, isAndroid } from 'react-device-detect'

export const useConfigValueByCurrentDevice = (config) => {
    const [{ casino }] = useStateValue()

    const deviceConfig = useMemo(() => {
        if (typeof config !== 'object') {
            return config
        }
        if (isIOS && config.hasOwnProperty('ios')) {
            return config.ios
        }
        if (isSafari && config.hasOwnProperty('safari')) {
            return config.safari
        }
        const isWebview = casino.sweepstakesOrigin && casino.sweepstakesOrigin.page === 'webview'
        if (isAndroid && isWebview && config.hasOwnProperty('androidApp')) {
            return config.androidApp
        }
        if (config.hasOwnProperty('default')) {
            return config.default
        }
        return undefined
    }, [casino.sweepstakesOrigin])

    return deviceConfig
}