import firebase from 'firebase/compat/app'
import 'firebase/compat/analytics'

import { config } from '../components/Init/Init'

/**
 * Firebase events are documented here: https://high5games.atlassian.net/wiki/spaces/BD/pages/201687041/Firebase+Events
 *
 * make sure to add any new events/parameters to the wiki
 */

const getAccountType = typeId => {
  switch (typeId) {
    case 1:
      return 'playreal'
    case 2:
      return 'facebook'
    case 3:
      return 'google'
    case 9:
      return 'apple'
    default:
      return ''
  }
}

const logFirebaseEvent = (eventId, parameters) => {
  //make sure firebase is initialized
  if (firebase.apps.length !== 0) {
    console.log('FirebaseAnalytics logFirebaseEvent event:' + eventId + ', params:' + JSON.stringify(parameters))
    firebase.analytics().logEvent(eventId, parameters)
  }
}

const logFirebaseLobbyLoaded = player => {
  logFirebaseEvent(config.firebaseEvents.lobbyLoaded, {})
  if (player.loginUser.newAccount !== undefined && player.loginUser.newAccount === true) {
    logFirebaseEvent(config.firebaseEvents.accountCreated, { method: getAccountType(player.socialData.accountTypeId) })
  }
}

const logFirebaseGameLaunch = (player, gameId) => {
  if (localStorage.getItem('firebaseGameLaunched') !== 'true') {
    logFirebaseEvent(config.firebaseEvents.firstGameLaunch, { game_id: gameId })
    localStorage.setItem('firebaseGameLaunched', 'true')
  }
}

const logFirebaseGameSpin = (player, gameId, currency) => {
  if (currency === 'GC' && localStorage.getItem('firebaseGCSpin') !== 'true') {
    logFirebaseEvent(config.firebaseEvents.firstGCSpin, { game_id: gameId })
    localStorage.setItem('firebaseGCSpin', 'true')
  }
  if (currency === 'SC' && localStorage.getItem('firebaseSCSpin') !== 'true') {
    logFirebaseEvent(config.firebaseEvents.firstSCSpin, { game_id: gameId })
    localStorage.setItem('firebaseSCSpin', 'true')
  }
}

const logFirebaseRewardCollected = player => {
  if (localStorage.getItem('firebaseRewardCollected') !== 'true') {
    logFirebaseEvent(config.firebaseEvents.firstRewardCollected, {})
    localStorage.setItem('firebaseRewardCollected', 'true')
  }
}

const logFirebaseKycAttempted = player => {
  logFirebaseEvent(config.firebaseEvents.kycAttempt, {})
}

const logFirebaseKycPassed = player => {
  logFirebaseEvent(config.firebaseEvents.kycPassed, {})
}

export {
  logFirebaseLobbyLoaded,
  logFirebaseGameLaunch,
  logFirebaseGameSpin,
  logFirebaseRewardCollected,
  logFirebaseKycAttempted,
  logFirebaseKycPassed
}
