import { tokenizer } from '../../../utils'
import { config } from '../../../../components/Init/Init'
import firebase from 'firebase/compat/app'
import { useStateValue } from '../../../state/StateProvider'
import { useCallback } from 'react'

export const useRefreshFirebaseToken = () => {
  const [{ player }, dispatch] = useStateValue()

  const refreshFirebaseToken = useCallback(() => {
    console.log('REFRESHING FIREBASE TOKEN')
    return new Promise(function(resolve, reject) {
      fetch(
        tokenizer(config.apiUrl + config.firebaseAuthUrl, {
          '{userId}': player.loginUser.userId
        }),
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${player.accessToken.encryptedToken}`
          }
        }
      )
        .then(response => {
          response
            .json()
            .then(async obj => {
              dispatch({
                type: 'storeFirebaseToken',
                player: {
                  firebaseToken: obj.firebaseToken
                }
              })

              try {
                await firebase
                  .auth()
                  .setPersistence(firebase.auth.Auth.Persistence.SESSION)
                  .catch(e => {
                    console.log(`[refreshFirebaseToken - ERROR] - setPersistence failed.`, e)
                    reject(e)
                  })

                await firebase
                  .auth()
                  .signInWithCustomToken(obj.firebaseToken)
                  .catch(e => {
                    console.log(`[refreshFirebaseToken - ERROR] - signInWithCustomToken failed.`, e)
                    reject(e)
                  })

                resolve()
              } catch (err) {
                console.log(`[refreshFirebaseToken - ERROR] - Firebase failure.`, err)
                reject(err)
              }
            })
            .catch(err => {
              console.log(`[refreshFirebaseToken - ERROR] `, err)
              reject(err)
            })
        })
        .catch(err => {
          console.log(`[refreshFirebaseToken - ERROR] ${err.code} - ${err.message}`)
          reject(err)
        })
    })
  }, [dispatch, player.accessToken.encryptedToken, player.loginUser.userId])

  return { refreshFirebaseToken }
}
