import { createContext, useCallback, useContext, useState } from 'react'
import firebase from 'firebase/compat/app'
import 'firebase/compat/auth'
import 'firebase/compat/database'
import 'firebase/compat/functions'
import { config } from '../../../components/Init/Init'
import get from 'lodash/get'
import map from 'lodash/map'
import l10n from '../../../components/i18n/I18N'
import { useConfigValueByCurrentDevice } from '../../../hooks/useConfigValueByCurrentDevice'
import { getPlayerStatus } from '../../helpers/playerHelper'
import {
  isNotificationLimit,
  isNotificationPurchaseType,
  isNotificationTimePending,
  isStatusNotification,
  storeHasProduct
} from '../../utils'
import * as Sentry from '@sentry/browser'
import { disconnect, hideStore, logout, reloadPage } from '../../../sweepstakes/messages'
import { isInboxEnabled } from '../../../sweepstakes/SweepsClaimCenter/sweepsClaimCenterUtils'
import { useStateValue } from '../../state/StateProvider'
import { useRefreshFirebaseToken, useRegisterActiveTabId, useUpdateFlashPrizes } from './hooks'
import {
  useCloseNotification,
  useRemoveNotification,
  useSetPendingNotifications,
  useShowNotification
} from '../../../hooks/notificationsHooks'
import { useTimePendingNotifications } from '../../../hooks/casinoHooks'

const FirebaseContext = createContext()

export const useFirebase = () => useContext(FirebaseContext)

export const FirebaseProvider = ({ children }) => {
  const [{ casino, player }, dispatch] = useStateValue()
  const [firebaseMaintenanceMode, setFirebaseMaintenanceMode] = useState(false)
  const enableBigWinsFeed = useConfigValueByCurrentDevice(config.enableBigWinsFeed)
  const [signingIn, setSigningIn] = useState(false)
  const [firebaseSmsInfo, setFirebaseSmsInfo] = useState(null)
  const [sessionIdLogoutShow, setSessionIdLogoutShow] = useState('')
  const [firebaseUserStatus, setFirebaseUserStatus] = useState(null)
  const [purchaseNotifications, setPurchaseNotifications] = useState([])
  const [gameBalanceReload, setGameBalanceReload] = useState(false)
  const { initFlashPrizesFirebaseListening, flashPrizesQueryInterval } = useUpdateFlashPrizes()

  const { refreshFirebaseToken } = useRefreshFirebaseToken()

  const { removeNotification } = useRemoveNotification({ purchaseNotifications, setPurchaseNotifications })
  const { closeNotification } = useCloseNotification(removeNotification)
  const { showNotificationPopup } = useShowNotification(removeNotification)
  const { setTimePendingNotifications } = useTimePendingNotifications(showNotificationPopup)
  const { setPendingNotifications } = useSetPendingNotifications(showNotificationPopup)

  const { registerActiveTabId } = useRegisterActiveTabId({ sessionIdLogoutShow, setSessionIdLogoutShow })

  const moveNotificationToClaimCenter = useCallback(
    notificationId => {
      let moveNotification = firebase.functions().httpsCallable('moveNotificationToClaimCenter')
      return moveNotification({
        playerId: player.loginUser.userId,
        notificationId: notificationId,
        firebaseDb: config.firebaseDb
      })
    },
    [player.loginUser.userId]
  )

  const closeActiveNotificationIfNotInQueue = (notificationArray = []) => {
    const notificationsIds = map(notificationArray, 'id')

    if (casino.activeNotificationId && !notificationsIds.includes(casino.activeNotificationId)) {
      closeNotification()
    }
  }

  if (!window.rmgBase) {
    window.rmgBase = firebase.initializeApp(config.firebaseConfig)
    console.log('[Casino] Firebase initialized...')
    const initCasinoDB = get(config.featuresByLocale[l10n.locale], 'leaderboard.enabled', false) || enableBigWinsFeed
    if (initCasinoDB) {
      window.casinoDB = firebase.initializeApp(config.leaderboardFirebaseConfig, {
        name: 'casinoDB'
      })
      console.log('[Casino] Leaderboards Firebase initialized...')
      window.casinoDBSubscriptions = []
    }
    window.rmgBaseSubscriptions = []
    window.globalSubscriptions = []
  }

  if (window.globalSubscriptions.length === 0) {
    window.globalSubscriptions.push(`maintenanceMode`)
    window.rmgBase
      .database()
      .ref(`maintenanceMode`)
      .on('value', snapshot => {
        setFirebaseMaintenanceMode(snapshot.val() === null ? false : Boolean(snapshot.val()))
      })
  }

  if (getPlayerStatus(player) === 'logged-in' && !casino.newRegistration) {
    if (!signingIn && !window.rmgBase.auth().currentUser) {
      let db = window.rmgBase.database()
      let playerId = player.loginUser.userId
      setSigningIn(true)
      refreshFirebaseToken()
        .then(() => {
          let registerSessionId = firebase.functions().httpsCallable('registerSessionId')
          console.log('[Multisession] Registering ' + player.accountSessionId)
          return registerSessionId({
            sessionId: player.accountSessionId,
            firebaseDb: config.firebaseDb
          })
        })
        .then(data => {
          console.log('[Multisession] Registration result', data)

          registerActiveTabId()
          initFlashPrizesFirebaseListening()

          window.rmgBaseSubscriptions.push(`users/${playerId}/notificationQueue`)

          // listen to messages
          db.ref(`users/${playerId}/notificationQueue`).on('value', snapshot => {
            let notificationArr = []
            if (snapshot.val() !== null) {
              notificationArr = snapshot.val()
            }

            try {
              closeActiveNotificationIfNotInQueue(notificationArr)

              if (config.popupsToBeDeleted) {
                if (config.enableRemoveNotice) {
                  notificationArr
                    .filter(n => config.popupsToBeDeleted.includes(n.type))
                    .map(notification => {
                      removeNotification(notification.id)
                      return notification
                    })
                }
                notificationArr = notificationArr.filter(n => !config.popupsToBeDeleted.includes(n.type))
              }

              if (config.enableSweepstakes && config.enableClaimCenter && notificationArr.length > 0) {
                // move appropriate notifications to the claim center
                // (this won't be used once the claim center is established, but is needed on launch to port pending notifications over)
                for (let i = 0; i < notificationArr.length; i++) {
                  let notification = notificationArr[i]
                  if (Object.values(config.claimCenterNotificationTypes).indexOf(notification.type) >= 0) {
                    // remove the notification from this local array and add it to our claim center data
                    console.log('[Sweeps] Moving claim center notification from notification queue:', notification)
                    moveNotificationToClaimCenter(notification.id)
                    notificationArr.splice(i, 1)
                    i--
                  }
                }
              }

              if (!config.suppressNotificationPopups) {
                if (notificationArr.length > 0) {
                  const timePendingNotificationArr = []
                  for (let i = 0; i < notificationArr.length; i++) {
                    if (isNotificationTimePending(notificationArr[i])) {
                      // remove notification with time pending from the general queue
                      timePendingNotificationArr.push(notificationArr[i])
                      notificationArr.splice(i, 1)
                      i--
                    }
                  }
                  // set notification with time pending to app state
                  setTimePendingNotifications(timePendingNotificationArr)
                }

                //separate the purchase notifications which are not ready from the general queue, and deal with them in useEffect
                let purchaseNotificationArr = []
                for (let i = 0; i < notificationArr.length; i++) {
                  if (
                    isNotificationPurchaseType(notificationArr[i]) &&
                    !storeHasProduct(player, notificationArr[i].context.directPurchaseUUID)
                  ) {
                    purchaseNotificationArr.push(notificationArr[i])
                    notificationArr.splice(i, 1)
                    i--
                  }
                }
                setPurchaseNotifications(purchaseNotificationArr)

                let notifications = JSON.parse(JSON.stringify(notificationArr)) // copy notificationArr before we append pending
                if (snapshot.val() !== null) {
                  if (player.pendingNotifications !== undefined) {
                    notificationArr = player.pendingNotifications.concat(notificationArr)
                  }
                }
                if (notificationArr.length > 0) {
                  if (casino.holdNotifications) {
                    if (notifications.length > 0) {
                      let priorityArr = []
                      let pendingArr = []
                      // assumming we are not suppose to hold off status and limit notifications, then allow those through
                      while (notifications.length > 0) {
                        if (isNotificationLimit(notifications[0]) || isStatusNotification(notifications[0])) {
                          priorityArr.push(notifications.shift())
                        } else {
                          pendingArr.push(notifications.shift())
                        }
                      }
                      showNotificationPopup(priorityArr)
                      if (player.pendingNotifications !== undefined) {
                        setPendingNotifications(pendingArr.concat(player.pendingNotifications))
                      } else {
                        setPendingNotifications(pendingArr)
                      }
                    } else {
                      showNotificationPopup(notifications)
                    }
                  } else {
                    showNotificationPopup(notificationArr)
                    setPendingNotifications([])
                  }
                } else {
                  showNotificationPopup(notificationArr)
                }
              }
            } catch (e) {
              console.log('[onFirebaseNotificationQueueChange] There was an error processing this notification', e)
              if (process.env.REACT_APP_ENABLE_SENTRY === 'true') {
                Sentry.captureException(e)
              }
            }
          })

          window.rmgBaseSubscriptions.push(`users/${playerId}/userState/status`)
          db.ref(`users/${playerId}/userState/status`).on('value', snapshot => {
            // listen to user status change
            let snapshotVal = snapshot.val()
            console.log(`[Casino] Firebase userState/status snapshot: ${snapshotVal}`)
            setFirebaseUserStatus(snapshotVal)
          })

          // sessionKicked is deprecated. sessionEvent is used to log out players now
          window.rmgBaseSubscriptions.push(`users/${playerId}/sessionKicked`)
          db.ref(`users/${playerId}/sessionKicked`).on('value', snapshot => {
            // listen to session kicked
            if (window.sessionKickedInitialResponseCalled) {
              let snapshotVal = snapshot.val()
              if (snapshotVal) {
                console.log(
                  `[Session Kicked] reason Player Limits/Responsible Gaming, session id ${player.accountSessionKicked}, snapshot ${snapshotVal}`
                )
                // logout player
                dispatch({
                  type: 'logout',
                  player: { refresh: true }
                })
                if (config.enableSweepstakes) {
                  logout()
                }
              }
            } else {
              window.sessionKickedInitialResponseCalled = true
            }
          })

          window.rmgBaseSubscriptions.push(`users/${playerId}/sessionData/sessionEvent`)
          db.ref(`users/${playerId}/sessionData/sessionEvent`).on('value', snapshot => {
            // ignore sessionEvent on the initial response. only act on subsequent responses
            if (window.sessionEventInitialResponseCalled) {
              let snapshotVal = snapshot.val()
              if (snapshotVal) {
                switch (snapshotVal.action) {
                  case 'kickout':
                    console.log('[Session Kicked] snapshot', snapshotVal)
                    // logout player
                    dispatch({
                      type: 'logout',
                      player: { refresh: true }
                    })
                    if (config.enableSweepstakes) {
                      logout()
                    }
                    break
                  case 'refresh':
                    console.log('[Session Refresh] sessionEvent, snapshot', snapshotVal)
                    if (config.enableSweepstakes) {
                      reloadPage()
                    }
                    break
                  default:
                    break
                }
              }
            } else {
              window.sessionEventInitialResponseCalled = true
            }
          })

          if (config.enableSweepstakes && config.enableClaimCenter) {
            window.rmgBaseSubscriptions.push(`users/${playerId}/claimCenter`)
            // listen to messages
            db.ref(`users/${playerId}/claimCenter`).on('value', snapshot => {
              let cc = snapshot.val()
              dispatch({
                type: 'setSingleModal',
                modals: {
                  claimCenter: {
                    data: {
                      // we have to send these individually, to make sure the data structure is fully defined
                      pending: {
                        dailyBonus: cc && cc.pending && cc.pending.dailyBonus ? cc.pending.dailyBonus : null,
                        bonusHarvest: cc && cc.pending && cc.pending.bonusHarvest ? cc.pending.bonusHarvest : null
                      },
                      expired: {
                        dailyBonus: cc && cc.expired && cc.expired.dailyBonus ? cc.expired.dailyBonus : null,
                        bonusHarvest: cc && cc.expired && cc.expired.bonusHarvest ? cc.expired.bonusHarvest : null
                      }
                    }
                  }
                }
              })
            })

            if (isInboxEnabled(playerId)) {
              window.rmgBaseSubscriptions.push(`users/${playerId}/inboxMessageStatus`)
              // listen to messages
              db.ref(`users/${playerId}/inboxMessageStatus`).on('value', snapshot => {
                let status = {}
                if (snapshot.val() !== null) {
                  status = snapshot.val()
                }

                dispatch({
                  type: 'setSingleModal',
                  modals: {
                    claimCenter: {
                      data: {
                        inboxMessageStatusSet: true,
                        inboxMessageStatus: status
                      }
                    }
                  }
                })
              })

              if (config.inboxV2Enabled) {
                window.rmgBaseSubscriptions.push(`users/${playerId}/inboxMessageNotifications`)
                // listen to messages
                db.ref(`users/${playerId}/inboxMessageNotifications`).on('value', snapshot => {
                  let notificationArr = []
                  if (snapshot.val() !== null) {
                    notificationArr = snapshot.val()
                  }

                  dispatch({
                    type: 'setSingleModal',
                    modals: {
                      claimCenter: {
                        data: {
                          notificationsSet: true,
                          notifications: notificationArr
                        }
                      }
                    }
                  })
                })
              }
            }
          }

          window.rmgBaseSubscriptions.push(`users/${playerId}/wallets`)
          db.ref(`users/${playerId}/wallets`).on('value', snapshot => {
            // listen to wallets
            let snapshotVal = snapshot.val()
            if (snapshotVal) {
              map(snapshotVal, (wallet, key) => {
                wallet.currencyId = wallet.walletName
                wallet.walletId = parseInt(key)
                return wallet
              })

              dispatch({
                type: 'updateWallets',
                player: {
                  wallets: snapshotVal
                }
              })

              setGameBalanceReload(true)
            }
          })

          if (config.enableSweepstakes) {
            window.rmgBaseSubscriptions.push(`users/${playerId}/kycInfo`)
            db.ref(`users/${playerId}/kycInfo`).on('value', snapshot => {
              let snapshotVal = snapshot.val()
              if (snapshotVal) {
                dispatch({
                  type: 'updateKyc',
                  player: {
                    kycStatus: snapshotVal.verificationStatus,
                    taxInfoStatus: snapshotVal.taxInfoStatus
                  }
                })
              }
            })

            window.rmgBaseSubscriptions.push(`users/${playerId}/freezeInfo`)
            db.ref(`users/${playerId}/freezeInfo`).on('value', snapshot => {
              let snapshotVal = snapshot.val()
              if (snapshotVal) {
                dispatch({
                  type: 'updateFreezeInfo',
                  player: {
                    freezeInfo: snapshotVal
                  }
                })
              }
            })

            window.rmgBaseSubscriptions.push(`users/${playerId}/smsInfo`)
            db.ref(`users/${playerId}/smsInfo`).on('value', snapshot => {
              let snapshotVal = snapshot.val()
              // this is pulled out so that the player state is properly updated
              setFirebaseSmsInfo(snapshotVal)
            })
          }

          if (config.enableCryptoWallet) {
            window.rmgBaseSubscriptions.push('rates')
            db.ref('rates').on('value', snapshot => {
              let snapshotVal = snapshot.val()
              dispatch({
                type: 'setRates',
                rates: snapshotVal.rates
              })
            })
          }

          window.rmgBaseSubscriptions.push(`users/${playerId}/lastPlayedGameIds`)
          db.ref(`users/${playerId}/lastPlayedGameIds`).on('value', snapshot => {
            // listen to last played games
            let snapshotVal = snapshot.val()
            dispatch({
              type: 'updatePreviousGames',
              player: {
                previousGames: snapshotVal || []
              }
            })
          })

          window.rmgBaseSubscriptions.push(`users/${playerId}/sessionId`)
          db.ref(`users/${playerId}/sessionId`).on('value', snapshot => {
            // listen to sessionId: if we're not allowing multi-session
            // and the id differs, log us out and show the popup
            let snapshotVal = snapshot.val()
            if (snapshotVal && snapshotVal !== player.accountSessionId) {
              let reason = ''
              switch (snapshotVal) {
                case config.sessionIdLogoutReasons.timeoutPeriod:
                case config.sessionIdLogoutReasons.exclusionPeriod:
                case config.sessionIdLogoutReasons.genericCatchall:
                  reason = snapshotVal
                  break
                default:
                  if (!config.allowMultiSession) {
                    reason = config.sessionIdLogoutReasons.multiSession
                  }
              }

              console.log(
                `[Multisession] Multisession reason ${reason}, session id ${player.accountSessionId}, snapshot ${snapshotVal}`
              )

              if (reason !== '') {
                // logout player
                dispatch({
                  type: 'logout',
                  player: { refresh: true, allowAutomaticLogin: false }
                })

                // inform player of logout due to multi-session/PAM
                setSessionIdLogoutShow(reason)

                hideStore()

                if (config.enableSweepstakes) {
                  disconnect()
                }
              }
            }
          })
        })
        .catch(err => {
          if (err && err.code) {
            if (typeof err.code === 'string' && err.code.includes('invalid-custom-token')) {
              // logout player - custom token expired - most likely unable to run refresh (pc sleep, etc.)
              console.log('[Casino] - Invalid custom token', err)
              dispatch({
                type: 'setModal',
                modals: {
                  logout: {
                    display: true
                  }
                }
              })
            } else {
              console.error(`[Casino] - Firebase ${err.code} - ${err.message}`, err)
            }
          } else {
            console.error(`[Casino] - Firebase ${err}`)
          }
        })
        .finally(() => {
          setSigningIn(false)
          dispatch({
            type: 'addTimer',
            timers: [
              {
                name: config.timerNames.firebase,
                delta: config.firebaseRefreshInterval,
                repeat: true,
                actions: [
                  {
                    type: 'callCallback',
                    casino: { callback: refreshFirebaseToken }
                  }
                ]
              }
            ]
          })
        })
    }
  }

  return (
    <FirebaseContext.Provider
      value={{
        signingIn,
        firebaseSmsInfo,
        gameBalanceReload,
        closeNotification,
        removeNotification,
        firebaseUserStatus,
        sessionIdLogoutShow,
        setGameBalanceReload,
        purchaseNotifications,
        showNotificationPopup,
        setSessionIdLogoutShow,
        firebaseMaintenanceMode,
        flashPrizesQueryInterval
      }}
    >
      {children}
    </FirebaseContext.Provider>
  )
}
