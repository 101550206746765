import HttpStatus from 'http-status-codes'
import _ from 'lodash'

import { config } from '../components/Init/Init'
import { getWallet, getTimezoneOffsetStringFromPlayer } from '../services/utils'
import { getPlayerStatus } from '../services/helpers/playerHelper'

/**
 * Client action events documented here: https://high5games.atlassian.net/wiki/spaces/BD/pages/2752983/Game+Action+Codes
 *
 * As support is added for sweepstakes, make sure to add any new events/parameters and check the
 * column to mark that they're implemented for sweepstakes.
 */
const sendEvent = (player, eventId, parameters, callback) => {
  const json = {
    event: eventId,
    applicationId: config.applicationId,
    casinoSessionId: { value: player.accountSessionId },
    parameters: parameters
  }

  if (config.enableSweepstakes && getPlayerStatus(player) === 'logged-in' && !_.isEmpty(player.socialData)) {
    fetch(player.socialData.wsBaseUrl + '/' + config.socialLogEventUrl, {
      method: 'POST',
      body: JSON.stringify(json),
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        ApplicationToken: player.socialData.applicationToken
      }
    })
      .then(response => {
        if (response.status === HttpStatus.OK || response.status === HttpStatus.NO_CONTENT) {
          // success, no output needed
        } else {
          response.json().then(err => {
            console.log('[SweepsEvent - ERROR]', err)
          })
        }
        if (callback) {
          callback()
        }
      })
      .catch(e => {
        console.log('[SweepsEvent - ERROR] - request failed. ', e)
        if (callback) {
          callback()
        }
      })
  } else {
    console.log('[SWEEPS] Failed sending client action event. Missing Social data...', eventId)
    if (callback) {
      callback()
    }
  }
}

const ParameterType = {
  Double: 'double',
  Long: 'long',
  String: 'String'
}

const Parameter = {
  Timestamp: { id: 46, type: ParameterType.Long },
  TimezoneOffset: { id: 63, type: ParameterType.String },
  ApplicationId: { id: 100, type: ParameterType.Long },
  GameCoins: { id: 101, type: ParameterType.Double },
  RedeemableSweepsCoins: { id: 102, type: ParameterType.Double },
  UnplayedSweepsCoins: { id: 103, type: ParameterType.Double },
  Diamonds: { id: 104, type: ParameterType.Long },
  SelectedWallet: { id: 105, type: ParameterType.String },
  SweepsWalletDisplay: { id: 110, type: ParameterType.String },
  Currency: { id: 115, type: ParameterType.String },
  CmsId: { id: 120, type: ParameterType.Long },
  CmsSlug: { id: 121, type: ParameterType.String },
  CmsTags: { id: 122, type: ParameterType.String },
  FirebaseId: { id: 130, type: ParameterType.String },
  PhoneNumber: { id: 201, type: ParameterType.String },
  GameId: { id: 210, type: ParameterType.Long },
  PopupType: { id: 250, type: ParameterType.String },
  PopupCTAAction: { id: 251, type: ParameterType.String },
  PopupCTAUrl: { id: 252, type: ParameterType.String },
  CategoryId: { id: 254, type: ParameterType.Long },
  CategoryName: { id: 255, type: ParameterType.String },
  SubcategoryId: { id: 256, type: ParameterType.Long },
  SubcategoryName: { id: 257, type: ParameterType.String },
  SectionName: { id: 258, type: ParameterType.String },
  MailInCode: { id: 259, type: ParameterType.String }
}

const convertValueForParameterType = (value, parameterType) => {
  switch (parameterType) {
    case ParameterType.Double:
      return parseFloat(value)
    case ParameterType.Long:
      return parseInt(value)
    case ParameterType.String:
      return String(value)
    default:
      return value
  }
}

const assignCmsOrFirebaseId = (messageId, parameters) => {
  // if the id is a long, then assume it's the cms id, otherwise assume it's a firebase id
  if (convertValueForParameterType(messageId, Parameter.CmsId.type).toString() === messageId) {
    parameters.push(getParameterObject(Parameter.CmsId, messageId))
  } else {
    parameters.push(getParameterObject(Parameter.FirebaseId, messageId))
  }
}

const getParameterObject = (parameter, value) => {
  return {
    key: parameter.id,
    dataType: parameter.type,
    value: convertValueForParameterType(value, parameter.type)
  }
}

const getDefaultParameters = player => {
  return [
    getParameterObject(Parameter.Timestamp, Date.now()),
    getParameterObject(Parameter.TimezoneOffset, getTimezoneOffsetStringFromPlayer(player)),
    getParameterObject(Parameter.GameCoins, getWallet(player.wallets, config.gcWalletId)),
    getParameterObject(Parameter.RedeemableSweepsCoins, getWallet(player.wallets, config.swtWalletId)),
    getParameterObject(Parameter.UnplayedSweepsCoins, getWallet(player.wallets, config.swcWalletId)),
    getParameterObject(Parameter.Diamonds, getWallet(player.wallets, config.diamondWalletId)),
    getParameterObject(
      Parameter.SelectedWallet,
      player.loginUser && player.loginUser.activeCurrencyId === config.gcWalletId ? 'GC' : 'SC'
    )
  ]
}

const sendEventWithDefaultParameters = (player, eventId, callback) => {
  let parameters = getDefaultParameters(player)
  sendEvent(player, eventId, parameters, callback)
}

const homeIconClick = player => {
  sendEventWithDefaultParameters(player, config.socialClientEvents.homeIconClick)
}

const menuIconClick = player => {
  sendEventWithDefaultParameters(player, config.socialClientEvents.menuIconClick)
}

const activeWalletToggle = player => {
  sendEventWithDefaultParameters(player, config.socialClientEvents.activeWalletToggle)
}

const sweepsWalletDisplayToggle = player => {
  let parameters = getDefaultParameters(player)
  parameters.push(getParameterObject(Parameter.SweepsWalletDisplay, player.sweepsShowRedeemable ? 'split' : 'combined'))
  sendEvent(player, config.socialClientEvents.sweepsWalletDisplayToggle, parameters)
}

const promoStoreCurrencyFilterToggle = (player, walletId, subcategoryName) => {
  let parameters = getDefaultParameters(player)
  parameters.push(getParameterObject(Parameter.Currency, walletId === config.gcWalletId ? 'GC' : 'SC'))
  parameters.push(getParameterObject(Parameter.SubcategoryName, subcategoryName))
  sendEvent(player, config.socialClientEvents.promosCurrencyToggle, parameters)
}

const changePhoneNumber = (player, phoneNumber) => {
  let parameters = getDefaultParameters(player)
  parameters.push(getParameterObject(Parameter.PhoneNumber, phoneNumber))
  sendEvent(player, config.socialClientEvents.phoneNumberUpdate, parameters)
}

const popupCloseButtonClick = (player, popupType) => {
  const parameters = getDefaultParameters(player)
  parameters.push(getParameterObject(Parameter.PopupType, popupType))
  sendEvent(player, config.socialClientEvents.popupCloseButtonClick, parameters)
}

const popupClick = (player, popupType) => {
  const parameters = getDefaultParameters(player)
  parameters.push(getParameterObject(Parameter.PopupType, popupType))
  sendEvent(player, config.socialClientEvents.popupClick, parameters)
}

const referFriendsPopupClick = (player, popupType) => {
  const parameters = getDefaultParameters(player)
  parameters.push(getParameterObject(Parameter.PopupType, popupType))
  sendEvent(player, config.socialClientEvents.referFriendsPopupClick, parameters)
}

const landingPageCTAClick = (player, action, url, slug) => {
  const parameters = getDefaultParameters(player)
  if (action) {
    parameters.push(getParameterObject(Parameter.PopupCTAAction, action))
  }
  if (url) {
    parameters.push(getParameterObject(Parameter.PopupCTAUrl, url))
  }
  if (slug) {
    parameters.push(getParameterObject(Parameter.CmsSlug, slug))
  }
  sendEvent(player, config.socialClientEvents.landingPageCTAClick, parameters)
}

const popupCTAClick = (player, popupType, action, url) => {
  const parameters = getDefaultParameters(player)
  parameters.push(getParameterObject(Parameter.PopupType, popupType))
  if (action) {
    parameters.push(getParameterObject(Parameter.PopupCTAAction, action))
  }
  if (url) {
    parameters.push(getParameterObject(Parameter.PopupCTAUrl, url))
  }
  sendEvent(player, config.socialClientEvents.popupCTAClick, parameters)
}

const bannerCTAClick = (player, action, url) => {
  const parameters = getDefaultParameters(player)
  if (action) {
    parameters.push(getParameterObject(Parameter.PopupCTAAction, action))
  }
  if (url) {
    parameters.push(getParameterObject(Parameter.PopupCTAUrl, url))
  }
  sendEvent(player, config.socialClientEvents.bannerCTAClick, parameters)
}

const inboxCTAClick = (player, action, url, messageId, messageSlug, messageTags) => {
  const parameters = getDefaultParameters(player)
  if (action) {
    parameters.push(getParameterObject(Parameter.PopupCTAAction, action))
  }
  if (url) {
    parameters.push(getParameterObject(Parameter.PopupCTAUrl, url))
  }
  assignCmsOrFirebaseId(messageId, parameters)
  parameters.push(getParameterObject(Parameter.CmsSlug, messageSlug))
  parameters.push(getParameterObject(Parameter.CmsTags, messageTags || ''))
  sendEvent(player, config.socialClientEvents.inboxCTAClick, parameters)
}

const buyButtonClick = player => {
  sendEventWithDefaultParameters(player, config.socialClientEvents.buyButtonClick)
}

const gameIconClick = (player, gameId) => {
  let parameters = getDefaultParameters(player)
  parameters.push(getParameterObject(Parameter.GameId, gameId))
  sendEventWithDefaultParameters(player, config.socialClientEvents.gameIconClick)
}

const h5cButtonClick = player => {
  sendEventWithDefaultParameters(player, config.socialClientEvents.h5cButtonClick)
}

const diamondButtonClick = player => {
  sendEventWithDefaultParameters(player, config.socialClientEvents.diamondButtonClick)
}

const editDetailsIconClick = player => {
  sendEventWithDefaultParameters(player, config.socialClientEvents.editDetailsIconClick)
}

const editDetailsButtonClick = player => {
  sendEventWithDefaultParameters(player, config.socialClientEvents.editDetailsButtonClick)
}

const changePasswordButtonClick = player => {
  sendEventWithDefaultParameters(player, config.socialClientEvents.changePasswordButtonClick)
}

const sideNavDiamondLearnMoreClick = player => {
  sendEventWithDefaultParameters(player, config.socialClientEvents.diamondsLearnMoreClick)
}

const sideNavRedeemButtonClick = player => {
  sendEventWithDefaultParameters(player, config.socialClientEvents.redeemButtonClick)
}

const sideNavDiamondsButtonClick = player => {
  sendEventWithDefaultParameters(player, config.socialClientEvents.sideNavDiamonds)
}

const sideNavLogoutButtonClick = player => {
  sendEventWithDefaultParameters(player, config.socialClientEvents.logOutButton)
}

// resources section events
const sideNavAboutUsClick = player => {
  sendEventWithDefaultParameters(player, config.socialClientEvents.sideNavAboutUs)
}

const sideNavContactUsClick = player => {
  sendEventWithDefaultParameters(player, config.socialClientEvents.sideNavContactUs)
}

const sideNavPrivacyPolicyClick = player => {
  sendEventWithDefaultParameters(player, config.socialClientEvents.sideNavPrivacyPolicy)
}

const sideNavTermsAndConditionsClick = player => {
  sendEventWithDefaultParameters(player, config.socialClientEvents.sideNavTermsAndConditions)
}

const sideNavLegalNoticesClick = player => {
  sendEventWithDefaultParameters(player, config.socialClientEvents.sideNavLegalNotices)
}

const sideNavCookiePolicyClick = player => {
  sendEventWithDefaultParameters(player, config.socialClientEvents.sideNavCookiePolicy)
}

const sideNavResponsibleGamingClick = player => {
  sendEventWithDefaultParameters(player, config.socialClientEvents.sideNavResponsibleGaming)
}

const sideNavCurrencyInfoClick = player => {
  sendEventWithDefaultParameters(player, config.socialClientEvents.sideNavCurrencyInfo)
}

const sideNavHelpClick = player => {
  sendEventWithDefaultParameters(player, config.socialClientEvents.sideNavHelp)
}

// explore items events
const sideNavFeaturedClick = player => {
  sendEventWithDefaultParameters(player, config.socialClientEvents.sideNavFeatured)
}

const sideNavTopRatedClick = player => {
  sendEventWithDefaultParameters(player, config.socialClientEvents.sideNavTopRated)
}

const sideNavNewGamesClick = player => {
  sendEventWithDefaultParameters(player, config.socialClientEvents.sideNavNewGames)
}

const sideNavSlotsClick = player => {
  sendEventWithDefaultParameters(player, config.socialClientEvents.sideNavSlotsGames)
}

const sideNavJackpotsClick = player => {
  sendEventWithDefaultParameters(player, config.socialClientEvents.sideNavJackpotGames)
}

const sideNavPromotionsClick = player => {
  sendEventWithDefaultParameters(player, config.socialClientEvents.sideNavPromotion)
}

// account details
const sideNavMyAccount = player => {
  sendEventWithDefaultParameters(player, config.socialClientEvents.sideNavMyAccount)
}

const sideNavGamingHistory = player => {
  sendEventWithDefaultParameters(player, config.socialClientEvents.sideNavGamingHistory)
}

const sideNavTransactionHistory = player => {
  sendEventWithDefaultParameters(player, config.socialClientEvents.sideNavTransactionHistory)
}

const sideNavPlayerLimits = (player, sectionName) => {
  let parameters = getDefaultParameters(player)
  if (sectionName !== null) {
    parameters.push(getParameterObject(Parameter.SectionName, sectionName))
  }
  sendEvent(player, config.socialClientEvents.playerLimitsClick, parameters)
}

const sideNavAccountVerification = player => {
  sendEventWithDefaultParameters(player, config.socialClientEvents.sideNavAccountVerification)
}

const sideNavAccountVerificationLink = player => {
  sendEventWithDefaultParameters(player, config.socialClientEvents.sideNavAccountVerificationLink)
}

const leaderboardTooltip = player => {
  sendEventWithDefaultParameters(player, config.socialClientEvents.leaderboardTooltip)
}

const promosOnDemandTabClick = player => {
  sendEventWithDefaultParameters(player, config.socialClientEvents.promosOnDemandTabClick)
}

const playerPromosTabClick = player => {
  sendEventWithDefaultParameters(player, config.socialClientEvents.playerPromosTabClick)
}

const homeIconClickFromGame = (player, gameId, callback) => {
  let parameters = getDefaultParameters(player)
  parameters.push(getParameterObject(Parameter.GameId, gameId))
  sendEvent(player, config.socialClientEvents.homeIconClickFromGame, parameters, callback)
}

const gameLoadingStarted = (player, gameId) => {
  let parameters = getDefaultParameters(player)
  parameters.push(getParameterObject(Parameter.GameId, gameId))
  sendEvent(player, config.socialClientEvents.gameLoadingStarted, parameters)
}

const gameLoadingCompleted = (player, gameId) => {
  let parameters = getDefaultParameters(player)
  parameters.push(getParameterObject(Parameter.GameId, gameId))
  sendEvent(player, config.socialClientEvents.gameLoadingCompleted, parameters)
}

const smsEnterPhonePlayClassicClick = player => {
  sendEventWithDefaultParameters(player, config.socialClientEvents.smsEnterPhonePlayClassicClick)
}

const smsVerifyCodePlayClassicClick = player => {
  sendEventWithDefaultParameters(player, config.socialClientEvents.smsVerifyCodePlayClassicClick)
}

const smsLockedPlayClassicClick = player => {
  sendEventWithDefaultParameters(player, config.socialClientEvents.smsLockedPlayClassicClick)
}

const piiPage1PlayClassicClick = player => {
  sendEventWithDefaultParameters(player, config.socialClientEvents.piiPage1PlayClassicClick)
}

const piiPage2PlayClassicClick = player => {
  sendEventWithDefaultParameters(player, config.socialClientEvents.piiPage2PlayClassicClick)
}

const piiPage1CloseClick = player => {
  sendEventWithDefaultParameters(player, config.socialClientEvents.piiPage1CloseClick)
}

const piiPage2CloseClick = player => {
  sendEventWithDefaultParameters(player, config.socialClientEvents.piiPage2CloseClick)
}

const piiPage1NextClickFailure = player => {
  sendEventWithDefaultParameters(player, config.socialClientEvents.piiPage1NextClickFailure)
}

const piiPage1NextClickSuccess = player => {
  sendEventWithDefaultParameters(player, config.socialClientEvents.piiPage1NextClickSuccess)
}

const piiPage1NextClickBypass = player => {
  sendEventWithDefaultParameters(player, config.socialClientEvents.piiPage1NextClickBypass)
}

const piiPage2BackClick = player => {
  sendEventWithDefaultParameters(player, config.socialClientEvents.piiPage2BackClick)
}

const piiPage2SubmitClickFailure = player => {
  sendEventWithDefaultParameters(player, config.socialClientEvents.piiPage2SubmitClickFailure)
}

const piiPage2SubmitClickSuccess = player => {
  sendEventWithDefaultParameters(player, config.socialClientEvents.piiPage2SubmitClickSuccess)
}

const piqCashierLoadStart = player => {
  sendEventWithDefaultParameters(player, config.socialClientEvents.piqCashierLoadStart)
}

const piqCashierLoadComplete = player => {
  sendEventWithDefaultParameters(player, config.socialClientEvents.piqCashierLoadComplete)
}

const piqCashierLoadError = player => {
  sendEventWithDefaultParameters(player, config.socialClientEvents.piqCashierLoadError)
}

const claimCenterOpened = player => {
  sendEventWithDefaultParameters(player, config.socialClientEvents.claimCenterOpened)
}

const claimCenterTimeoutReached = player => {
  sendEventWithDefaultParameters(player, config.socialClientEvents.claimCenterTimeoutReached)
}

const claimCenterTimeoutReachedRetry = player => {
  sendEventWithDefaultParameters(player, config.socialClientEvents.claimCenterTimeoutReachedRetry)
}

const claimCenterButtonClick = player => {
  sendEventWithDefaultParameters(player, config.socialClientEvents.claimCenterButtonClick)
}

const claimCenterDailyBonusClick = player => {
  sendEventWithDefaultParameters(player, config.socialClientEvents.claimCenterDailyBonusClick)
}

const claimCenterBonusHarvestClick = player => {
  sendEventWithDefaultParameters(player, config.socialClientEvents.claimCenterBonusHarvestClick)
}

const aboutUsVisit = player => {
  sendEventWithDefaultParameters(player, config.socialClientEvents.aboutUsRoute)
}

const categoryClicked = (player, categoryId, categoryName) => {
  let parameters = getDefaultParameters(player)
  parameters.push(getParameterObject(Parameter.CategoryId, categoryId))
  parameters.push(getParameterObject(Parameter.CategoryName, categoryName))
  sendEvent(player, config.socialClientEvents.categoryClick, parameters)
}

const subcategoryClicked = (player, subcategoryId, subcategoryName, categoryId, categoryName) => {
  let parameters = getDefaultParameters(player)
  parameters.push(getParameterObject(Parameter.SubcategoryId, subcategoryId))
  parameters.push(getParameterObject(Parameter.SubcategoryName, subcategoryName))
  parameters.push(getParameterObject(Parameter.CategoryId, categoryId))
  parameters.push(getParameterObject(Parameter.CategoryName, categoryName))
  sendEvent(player, config.socialClientEvents.subcategoryClick, parameters)
}

const inboxMessageClicked = (player, messageId, messageSlug, messageTags) => {
  let parameters = getDefaultParameters(player)
  assignCmsOrFirebaseId(messageId, parameters)
  parameters.push(getParameterObject(Parameter.CmsSlug, messageSlug))
  parameters.push(getParameterObject(Parameter.CmsTags, messageTags || ''))
  sendEvent(player, config.socialClientEvents.inboxMessageClicked, parameters)
}

const accountTaxIdInfoTooltipClick = player => {
  sendEventWithDefaultParameters(player, config.socialClientEvents.accountTaxIdInfoTooltipClick)
}

const mailInCodeRequestClick = player => {
  sendEventWithDefaultParameters(player, config.socialClientEvents.mailInCodeRequestClick)
}

const mailInCodeResponseFromClick = (player, mailInCode) => {
  let parameters = getDefaultParameters(player)
  parameters.push(getParameterObject(Parameter.MailInCode, mailInCode))
  sendEvent(player, config.socialClientEvents.mailInCodeResponse, parameters)
}

export {
  sendEventWithDefaultParameters,
  homeIconClick,
  menuIconClick,
  activeWalletToggle,
  sweepsWalletDisplayToggle,
  promoStoreCurrencyFilterToggle,
  buyButtonClick,
  gameIconClick,
  h5cButtonClick,
  diamondButtonClick,
  editDetailsIconClick,
  editDetailsButtonClick,
  changePasswordButtonClick,
  sideNavDiamondLearnMoreClick,
  sideNavRedeemButtonClick,
  sideNavDiamondsButtonClick,
  sideNavLogoutButtonClick,
  sideNavAboutUsClick,
  sideNavCurrencyInfoClick,
  sideNavContactUsClick,
  sideNavCookiePolicyClick,
  sideNavPrivacyPolicyClick,
  sideNavTermsAndConditionsClick,
  sideNavLegalNoticesClick,
  sideNavResponsibleGamingClick,
  sideNavHelpClick,
  sideNavFeaturedClick,
  sideNavTopRatedClick,
  sideNavNewGamesClick,
  sideNavSlotsClick,
  sideNavJackpotsClick,
  sideNavPromotionsClick,
  sideNavMyAccount,
  sideNavGamingHistory,
  sideNavTransactionHistory,
  sideNavPlayerLimits,
  sideNavAccountVerification,
  sideNavAccountVerificationLink,
  changePhoneNumber,
  leaderboardTooltip,
  promosOnDemandTabClick,
  playerPromosTabClick,
  homeIconClickFromGame,
  popupCloseButtonClick,
  popupClick,
  referFriendsPopupClick,
  landingPageCTAClick,
  popupCTAClick,
  bannerCTAClick,
  inboxCTAClick,
  gameLoadingStarted,
  gameLoadingCompleted,
  smsEnterPhonePlayClassicClick,
  smsVerifyCodePlayClassicClick,
  smsLockedPlayClassicClick,
  piiPage1PlayClassicClick,
  piiPage2PlayClassicClick,
  piiPage1CloseClick,
  piiPage2CloseClick,
  piiPage1NextClickFailure,
  piiPage1NextClickSuccess,
  piiPage1NextClickBypass,
  piiPage2BackClick,
  piiPage2SubmitClickFailure,
  piiPage2SubmitClickSuccess,
  piqCashierLoadStart,
  piqCashierLoadComplete,
  piqCashierLoadError,
  aboutUsVisit,
  claimCenterOpened,
  claimCenterTimeoutReached,
  claimCenterTimeoutReachedRetry,
  claimCenterButtonClick,
  claimCenterDailyBonusClick,
  claimCenterBonusHarvestClick,
  categoryClicked,
  subcategoryClicked,
  inboxMessageClicked,
  accountTaxIdInfoTooltipClick,
  mailInCodeRequestClick,
  mailInCodeResponseFromClick
}
