import { useCallback } from 'react'
import { config } from '../../../../components/Init/Init'
import { useActiveBrowserTab } from '../../../../hooks/useActiveBrowserTab'

export const useRegisterActiveTabId = ({ sessionIdLogoutShow, setSessionIdLogoutShow }) => {
  const activeBrowserTabCallback = useCallback(() => {
    if (!sessionIdLogoutShow) {
      setSessionIdLogoutShow(config.sessionIdLogoutReasons.tabDuplication)
    }
  }, [sessionIdLogoutShow, setSessionIdLogoutShow])
  const { registerActiveTabId } = useActiveBrowserTab(activeBrowserTabCallback)

  return { registerActiveTabId }
}
