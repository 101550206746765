import firebase from 'firebase/compat/app'
import { config } from '../../components/Init/Init'
import * as Sentry from '@sentry/browser'
import { useStateValue } from '../../services/state/StateProvider'
import { useCallback } from 'react'

export const useRemoveClaimCenter = () => {
  const [{ player }] = useStateValue()

  const removeClaimCenter = useCallback(
    notificationId => {
      let removeClaimCenter = firebase.functions().httpsCallable('removeClaimCenter')
      return removeClaimCenter({
        playerId: player.loginUser.userId,
        notificationId: notificationId,
        firebaseDb: config.firebaseDb
      }).catch(err => {
        console.log('[Sweeps] Error while removing claim center notification: ', err)
        if (process.env.REACT_APP_ENABLE_SENTRY === 'true') {
          Sentry.captureException(err)
        }
      })
    },
    [player.loginUser.userId]
  )

  return { removeClaimCenter }
}
