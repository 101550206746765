import React from 'react'
import Spinner from '../Spinner/Spinner'

import './LoadingPage.scss'

function LoadingPage() {
  return (
    <div className="loading-page">
      <Spinner />
    </div>
  )
}

export default LoadingPage
