import { config } from './../Init/Init'
import HttpStatus from 'http-status-codes'
import { setPiiCountryCode } from '../../sweepstakes/messages'

const fetchAccountDetails = (player, dispatch, successCallback, failCallback) => {
  if (player.status === config.status.partial || player.status === config.status.registered) {
    const detailsPath = String(config.getAccountDetailsUrl).replace('{id}', player.loginUser.userId)
    fetch(config.apiUrl + detailsPath, {
      method: 'GET',
      mode: 'cors',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${player.accessToken.encryptedToken}`
      }
    })
      .then(response => {
        if (response.status === HttpStatus.OK) {
          response.json().then(details => {
            if (config.enableSweepstakes) {
              // any time we grab the details, we want to make sure the coin store currency is updated if needed
              setPiiCountryCode(details.country)
            }

            if (player.country !== details.country || player.subdivision !== details.subdivision) {
              // assign the country and subdivision as it's used in a bunch of places and can be wrong in the session where pii is entered
              dispatch({
                type: 'updatePlayer',
                player: undefined,
                newValues: {
                  country: details.country,
                  subdivision: details.subdivision
                }
              })
            }

            successCallback(details)
          })
        } else {
          response.json().then(err => {
            failCallback(err.errorReason)
          })
        }
      })
      .catch(err => {
        failCallback(err)
      })
  } else {
    failCallback('Player is not registered')
  }
}

export { fetchAccountDetails }
