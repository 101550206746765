import { config } from '../../../src/components/Init/Init'

/**
 * @name getPlayerStatus
 * @description Receives a player obj and return player current login state
 * @param {object} player
 * @return {string} - "logged-in" or "logged-out"
 */
export function getPlayerStatus(player) {
  if (player) {
    return getPlayerStatusFromStatus(player.status)
  } else {
    return 'logged-out'
  }
}

/**
 * @name getPlayerStatusFromStatus
 * @description Receives a player status and return player current login state
 * @param {string} status
 * @return {string} - "logged-in" or "logged-out"
 */
export function getPlayerStatusFromStatus(status) {
  let isLoggedIn = 'logged-out'

  if (status) {
    let registeredPlayer = status === config.status.registered
    if (!registeredPlayer && config.enableSweepstakes) {
      // in sweepstakes, partially registered players should appear the
      // same as registered, they just need to do their SMS verification
      registeredPlayer = status === config.status.partial
    }
    isLoggedIn = registeredPlayer ? 'logged-in' : 'logged-out'
  }

  return isLoggedIn
}
