import React, { useState, useEffect } from 'react'
import HttpStatus from 'http-status-codes'
import Cookies from 'js-cookie'
import _ from 'lodash'
import moment from 'moment'
import { v4 as uuidv4 } from 'uuid'
import {
  ApolloClient,
  InMemoryCache,
  ApolloProvider,
  defaultDataIdFromObject,
  ApolloLink,
  from,
  HttpLink
} from '@apollo/client'

import { StateProvider } from '../../services/state/StateProvider'
import playerReducer from '../../services/state/reducers/player-actions'
import casinoReducer from '../../services/state/reducers/casino-actions'
import timerReducer from '../../services/state/reducers/timer-actions'
import formReducer from '../../services/state/reducers/form-actions'
import walletReducer from '../../services/state/reducers/wallet-actions'
import builderReducer from '../../services/state/reducers/builder-actions'
import ratesReducer from '../../services/state/reducers/rates-actions'
import { GET_TRANSLATIONS } from '../../services/queries'
import parseTranslations from '../../services/content/parseTranslations'

import l10n from '../i18n/I18N'
import { handleCasinoMode, isBrowserTabDuplicated, tokenizer } from '../../services/utils'
import { anonymousPlayer } from '../../services/state/initialStates'
import packageJson from '../../../package.json'

import './Init.scss'
import 'bootstrap/dist/css/bootstrap.min.css'
import queryString from 'query-string'
import * as Sentry from '@sentry/browser'
import LoadingPage from '../LoadingPage/LoadingPage'
import { ImageExistProvider } from '../../services/providers/imageExistProvider'
import { FirebaseProvider } from '../../services/providers/FirebaseProvider'
import { PlayerPIIProvider } from '../../services/providers/PlayerPIIProvider'

let configuration = {}

function Init(props) {
  const { initCasinoMode } = handleCasinoMode()
  const [isReady, setIsReady] = useState(false),
    [localizationReady, setLocalizationReady] = useState(false),
    [constantsLoaded, setConstantsLoaded] = useState(false),
    [constants, setConstants] = useState({}),
    [shouldAddGTM, setShouldAddGTM] = useState(true),
    [apolloClient, setApolloClient] = useState(null),
    [showWelcome, setShowWelcome] = useState(true),
    gtmJS = `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src='https://www.googletagmanager.com/gtm.js?id='+i+dl{auth};f.parentNode.insertBefore(j,f);})(window,document,'script','dataLayer','{containerId}');`,
    reducer = (state, action) => {
      const reducers = {
          casino: casinoReducer,
          modals: casinoReducer,
          player: playerReducer,
          timers: timerReducer,
          forms: formReducer,
          wallet: walletReducer,
          rates: ratesReducer
        },
        missingInAction = function(reducer, action) {
          console.log(`[Init] The action ${action.type} doesn't exist in the ${reducer} reducer.`)
        }

      if (process.env.REACT_APP_FLOOR_MANAGER_COMPONENT_PATH === 'FloorManager/FloorManager') {
        reducers.builder = builderReducer
      }
      // select the reducer to use based on action, actions indicate which
      // reducer to use by assigning the requested state to a key that maps
      // to the reducer
      const updateModel = _.intersection(_.keys(reducers), _.keys(action))[0]

      if (typeof reducers[updateModel] === 'function') {
        return reducers[updateModel](state, action)
      } else return missingInAction(updateModel, action)
    },
    setLocaleFromUrl = () => {
      const lRegex = /\/[a-z]{2}-[a-z]{2,3}\//,
        localeCandidate = window.location.pathname.match(lRegex)
          ? _.replace(window.location.pathname.match(lRegex)[0], /\//g, '')
          : ''

      // get first directory if it might have a locale
      if (localeCandidate && localeCandidate !== process.env.REACT_APP_DEFAULT_LOCALE) {
        l10n.setLocale({
          isoLocale: localeCandidate,
          locales: constants.locales,
          generalLanguages: constants.generalLanguages
        })
      } else {
        // set a default locale
        l10n.setLocale({
          isoLocale: '',
          locales: ['n/a'],
          generalLanguages: { en: 'en-row' }
        })
      }
    },
    setLocaleFromState = () => {
      if (_.has(constants, 'appToken') && _.has(constants, 'idleTime')) {
        const key = constants.appToken,
          idleTime = constants.enableSweepstakes
            ? constants.socialTimeToLive * constants.socialTimeToLiveCoefficient * constants.socialTimeToLiveCoefficient
            : constants.idleTime
        let state = localStorage.getItem(key)

        if (typeof state === 'string' && key && idleTime) {
          state = JSON.parse(state)

          if (
            _.has(state, 'player.timestamp') &&
            Date.now() - state.player.timestamp < idleTime &&
            state.player.country
          ) {
            // set locale based on player preferences
            l10n.setLocale({
              isoLocale: `${state.player.loginUser.preferredLanguageCode}-${state.player.country}`,
              locales: constants.locales,
              isLoggedIn: state.player.status === constants.status.registered,
              generalLanguages: constants.generalLanguages
            })
          }
        }
      }
    },
    setLocaleFromGeolocation = async () => {
      return fetch(constants.apiUrl + constants.getPlayerCountryCode)
        .then(response => response.json())
        .then(data => {
          l10n.setLocale({
            isoLocale: `${window.navigator.language.substring(0, 2)}-${data.countryCode}`,
            locales: constants.locales,
            generalLanguages: constants.generalLanguages
          })
        })
        .catch(err => {
          console.log('[Init] error inferring locale.', err)
          l10n.setLocale({
            isoLocale: '',
            locales: ['n/a'],
            generalLanguages: { en: 'en-row' }
          })
        })
    },
    updateHistoryBasedOnLocale = () => {
      const afterLocaleRegex = /\/[a-z]{2}-[a-z]{2,3}\/(.*)/
      let path = ''
      path = `${window.origin}/${l10n.locale}/${
        window.location.pathname.match(afterLocaleRegex)
          ? window.location.pathname.match(afterLocaleRegex)[1]
          : 'casino/lobby'
      }${window.location.search}${window.location.hash}`
      window.history.replaceState('', '', path)
    },
    setInitialState = () => {
      let key, idleTime, state, newState

      if (process.env.REACT_APP_FLOOR_MANAGER_COMPONENT_PATH === 'FloorManager/FloorManager') {
        anonymousPlayer.builder = {
          tout: {
            id: 0,
            selectedObjectId: '',
            data: []
          },
          lobby: {
            id: 0,
            selectedObjectId: '',
            data: []
          },
          sellsheet: {
            id: 0,
            selectedObjectId: '',
            data: []
          }
        }
      }

      if (_.has(constants, 'appToken') && _.has(constants, 'idleTime')) {
        key = constants.appToken
        idleTime = constants.enableSweepstakes
          ? constants.socialTimeToLive * constants.socialTimeToLiveCoefficient
          : constants.idleTime
        state = localStorage.getItem(key)

        // update form defaults
        anonymousPlayer.forms.step3.days = _.range(1, constants.numDays.long + 1)
        anonymousPlayer.forms.step3.months = constants.months
        anonymousPlayer.forms.step3.years = _.range(
          parseInt(
            moment()
              .subtract(constants.minimumAge, 'years')
              .format('YYYY'),
            10
          ),
          constants.firstYear
        )
        anonymousPlayer.forms.step3.birthMonth = parseInt(
          moment()
            .subtract(constants.minimumAge, 'years')
            .format('MM'),
          10
        )
        anonymousPlayer.forms.step3.birthDay = parseInt(
          moment()
            .subtract(constants.minimumAge, 'years')
            .format('DD'),
          10
        )

        newState = _.cloneDeep(anonymousPlayer)

        if (typeof state === 'string' && key && idleTime) {
          state = JSON.parse(state)
          state.timers = {} // flush timers
          if (state.casino) {
            state.casino.gameRoundPending = false // reset default
            state.casino.holdNotifications = false // reset default
          }

          const playerId = _.get(state, 'player.userId')
          const playerStatus = _.get(state, 'player.status')
          const playerPreferredLanguage = _.get(state, 'player.loginUser.preferredLanguageCode')
          const playerTimestamp = _.get(state, 'player.timestamp')
          const playerCurrency = _.get(state, 'player.currency')

          // if we're using sentry and we have a user id already, update sentry user
          if (process.env.REACT_APP_ENABLE_SENTRY === 'true' && playerId) {
            Sentry.setUser({ id: playerId })
          }

          if (playerStatus !== constants.status.registered && playerStatus !== constants.status.partial) {
            state = anonymousPlayer
          }

          // update initial state with player's preferred language
          anonymousPlayer.player.loginUser.preferredLanguageCode = playerPreferredLanguage

          if (playerTimestamp && Date.now() - playerTimestamp < idleTime) {
            // TODO: DRY out currency handling (B2CRMG-2581)
            l10n.setCurrency(playerCurrency)

            newState = state
          }

          // if the new claimCenter modal doesn't exist on the old state, add it (to prevent errors when claim center is first released)
          if (newState.modals.claimCenter === undefined) {
            newState.modals.claimCenter = {
              display: false,
              data: {
                pending: {
                  dailyBonus: null,
                  bonusHarvest: null
                },
                expired: {
                  dailyBonus: null,
                  bonusHarvest: null
                },
                inboxMessageStatusSet: false,
                inboxMessageStatus: {},
                notificationsSet: false
              }
            }
          }
        }

        if (!constants.allowTabDuplication) {
          let activeTabId = sessionStorage.getItem('activeTabId')

          if (!activeTabId || isBrowserTabDuplicated()) {
            activeTabId = uuidv4()
          }

          sessionStorage.setItem('activeTabId', activeTabId)
          newState.player.activeTabId = activeTabId
        }

        initCasinoMode()

        return newState
      }
    }

  // prep Google Tag Manager elements
  let gtmHead = document.createElement('script'),
    gtmBody = document.createElement('noscript'),
    params = queryString.parse(window.location.search)

  const loadConstants = async () => {
    return fetch(`${process.env.PUBLIC_URL}/settings/constants.json`, {
      headers: {
        'Content-Type': 'application/json'
      }
    })
      .then(response => response.json())
      .catch(e => console.log('[buildConfig] - No constants.json found!', e))
  }

  const loadAppSettings = async () => {
    if (process.env.REACT_APP_LOCAL_CONFIG) {
      return {}
    }
    return fetch(
      `${process.env.PUBLIC_URL}/config/static/${process.env.REACT_APP_SETTINGS_VERSION}/app-settings.json`,
      {
        headers: {
          'Content-Type': 'application/json'
        }
      }
    )
      .then(response => response.json())
      .catch(e => console.log('[buildConfig] - No app-settings.json found!', e))
  }

  const loadLocalConfig = async () => {
    if (!process.env.REACT_APP_LOCAL_CONFIG) {
      return {}
    }
    return fetch(`${process.env.PUBLIC_URL}/settings/static/${process.env.REACT_APP_LOCAL_CONFIG}`, {
      headers: {
        'Content-Type': 'application/json'
      }
    })
      .then(response => response.json())
      .catch(e => console.log('[buildConfig] - No local config found!', e))
  }

  const assembleConfig = configs => {
    return _.reduce(
      _.filter(configs, c => !_.isEmpty(c)),
      _.extend
    )
  }

  // load configurations
  useEffect(() => {
    const loadConfiguration = async () => {
      const configs = await Promise.all([loadConstants(), loadAppSettings(), loadLocalConfig()])

      if (!_.has(constants, 'appToken')) {
        setConstants(assembleConfig(configs))
        setConstantsLoaded(true)
      }
    }

    loadConfiguration()
  }, [])

  // load operating countries
  useEffect(() => {
    if (constantsLoaded) {
      fetch(`${process.env.PUBLIC_URL}/content/i18n/countries.json`, {
        headers: {
          'Content-Type': 'application/json'
        }
      }).then(response => {
        response.json().then(countries => {
          constants.countries = countries
          l10n.setCountries(countries)
        })
      })
    }
  }, [constants, constantsLoaded])

  useEffect(() => {
    if (constantsLoaded) {
      const httpLink = new HttpLink({ uri: constants.craftGraphQLEndpoint, fetch })
      const cacheHintMiddleware = new ApolloLink((operation, forward) => {
        operation.setContext(({ headers = {} }) => ({
          headers: {
            ...headers,
            'X-Operation-Name': operation.operationName
          }
        }))

        return forward(operation)
      })

      const client = new ApolloClient({
        cache: new InMemoryCache({
          typePolicies: {
            categoryAssortments_categoryAssortment_Entry: {
              fields: {
                lobbyGameTouts: {
                  keyArgs: false,
                  merge(existing = [], incoming = [], { readField }) {
                    return _.unionWith(existing, incoming, (game1, game2) => {
                      const game1ToutRef = readField('game', game1)
                      const game2ToutRef = readField('game', game2)
                      let result = false
                      if (game1ToutRef && game2ToutRef) {
                        let game1Id
                        let game2Id
                        if (game1ToutRef[0]) {
                          game1Id = readField('gameId', game1ToutRef[0])
                        }
                        if (game2ToutRef[0]) {
                          game2Id = readField('gameId', game2ToutRef[0])
                        }
                        result = game1Id && game2Id && game1Id === game2Id
                      }
                      return result
                    })
                  }
                }
              }
            }
          },
          dataIdFromObject(responseObject) {
            const { sectionHandle, typeHandle, siteId, slug, id, groupHandle } = responseObject

            if (sectionHandle && typeHandle && siteId && slug) {
              // Generate the cache ID for CMS entries.
              return `site:${siteId}-${sectionHandle}-${typeHandle}-${slug}`
            } else if (groupHandle && siteId && slug) {
              // Generate the cache ID for CMS categories.
              return `site:${siteId}-${groupHandle}-${slug}`
            } else if (typeHandle && siteId && id) {
              // Generate the cache ID for CMS BlockType fields.
              return `site:${siteId}-${typeHandle}-${id}`
            }

            return defaultDataIdFromObject(responseObject)
          }
        }),
        link: from([cacheHintMiddleware, httpLink])
      })
      setApolloClient(client)
    }
  }, [constants, constantsLoaded])

  // load localization settings
  useEffect(() => {
    const loadLocalization = async () => {
      const i18nCfgUrl = constants.enableI18n
        ? constants.apiUrl + constants.configUrl
        : `${process.env.PUBLIC_URL}/content/i18n/i18n-cfg.json`

      fetch(
        tokenizer(i18nCfgUrl, {
          '{appId}': constants.applicationId
        }),
        {
          headers: {
            'Content-Type': 'application/json'
          }
        }
      )
        .then(response => {
          if (response.status === HttpStatus.OK) {
            return response.json()
          }
        })
        .then(async cfg => {
          constants.locales = cfg.availableLocales
          constants.languages = _.uniq(
            _.map(cfg.availableLocales, locale => {
              return locale.substr(0, 2)
            })
          )
          constants.defaultLanguage = cfg.defaultLanguage
          constants.generalLanguages = cfg.generalLanguages

          // set currencies
          l10n.allowedCurrencies = cfg.allowedCurrencies
          if (constants.defaultCurrency) {
            l10n.currency = constants.defaultCurrency
          }

          // set country calling codes
          l10n.countryCallingCodes = cfg.countryCallingCodes
          l10n.defaultCountryCallingCode = cfg.defaultCountryCallingCode

          // set language features
          l10n.features = cfg.featuresByLocale[cfg.featuresByLocale.defaultLocale]
          constants.featuresByLocale = cfg.featuresByLocale
          constants.paymentMethodIcons = cfg.paymentMethodIcons

          setLocaleFromUrl()
          if (constants.enableLocaleByGeolocation) {
            await setLocaleFromGeolocation()
          }
          setLocaleFromState()
          updateHistoryBasedOnLocale()

          const result = await apolloClient.query({
            query: GET_TRANSLATIONS,
            variables: {
              section: 'translationBundles',
              locale: l10n.site,
              slug: 'general-bundle'
            }
          })

          if (result && result.data && result.data.entry) {
            l10n.setTranslations(parseTranslations(result))
            if (process.env.REACT_APP_ENABLE_SENTRY === 'true') {
              Sentry.addBreadcrumb({
                message: 'CMS Translations ready',
                level: 'info'
              })
            }
          } else {
            console.error('[Init] Failed to get translations')
          }

          return fetch(`${process.env.PUBLIC_URL}/content/promos/templates-${l10n.locale}.json`)
        })
        .then(response => {
          return response.json()
        })
        .then(templates => {
          l10n.setPromoTemplates(templates)
          console.log('[Init] Localization settings ready.', l10n)
          setLocalizationReady(true)
        })
        .catch(err => {
          // No locale promo templates found. Loading general language template.
          console.log('[Init] - Failed initialising the localisation configuration!', JSON.stringify(err))
          if (process.env.REACT_APP_ENABLE_SENTRY === 'true') {
            Sentry.captureException(err)
          }
        })

      // set Income Access cookies
      // per B2CRMG-10069 this is a functional (necessary) cookie
      if (params.click_id) {
        let iaCookie = Cookies.get('click_id')

        if (iaCookie) {
          // ignore new referrals
        } else {
          Cookies.set('click_id', params.click_id, {
            expires: constants.demoCookieTime
              ? new Date(new Date().getTime() + constants.demoCookieTime)
              : constants.iaCookieExpiration
          })
        }
      }
    }

    if (constantsLoaded && apolloClient && !localizationReady) {
      loadLocalization()
    }
  }, [constants, constantsLoaded, apolloClient, localizationReady])

  useEffect(() => {
    if (constantsLoaded && localizationReady) {
      setIsReady(true)
      setConstantsLoaded(false)
    }
  }, [constants, constantsLoaded, localizationReady])

  // add Google Tag Manager Scripts
  useEffect(() => {
    if (shouldAddGTM && constantsLoaded && constants.gtm && constants.gtm.enabled) {
      const head = tokenizer(gtmJS, {
        '{auth}': constants.gtm.auth,
        '{containerId}': constants.gtm.containerId
      })

      gtmHead.appendChild(document.createTextNode(head))
      gtmBody.appendChild(document.createTextNode(constants.gtm.iframe))

      document.getElementsByTagName('head')[0].appendChild(gtmHead)
      document.getElementsByTagName('body')[0].appendChild(gtmBody)

      setShouldAddGTM(false)
    }
  }, [shouldAddGTM, constants, constantsLoaded])

  // If we haven't yet loaded the config, show either a "splash" component
  // provided via a `loading` props or return nothing.
  if (!isReady) {
    return <LoadingPage />
  }

  // make config available to other components
  constants.version = packageJson.version
  configuration = constants

  // display welcome
  if (showWelcome) {
    console.log(
      `Welcome to the casino! [Casino: ${process.env.REACT_APP_CASINO_NAME} env: ${constants.environment ||
        'local'} - ${packageJson.version}]`
    )
    setShowWelcome(false)
  }

  // if we're using sentry, re-initialize sentry with loaded properties,
  // we don't yet have these the first time we initialize sentry
  if (process.env.REACT_APP_ENABLE_SENTRY === 'true') {
    Sentry.init({
      dsn: process.env.REACT_APP_SENTRY_DSN,
      release: packageJson.version,
      environment: constants.environment || 'local',
      ignoreErrors: ['ResizeObserver', 'chrome-extension', 'safari-web-extension', 'moz-extension']
    })
  }

  // The config is loaded so show the component set on the `ready()` props
  return (
    <StateProvider initialState={setInitialState()} reducer={reducer}>
      <FirebaseProvider>
        <PlayerPIIProvider>
          <ImageExistProvider
            showNonExistentImagesSrc={configuration.showNonExistentImagesSrc}
            showNonExistentImagesSrcDelayMs={configuration.showNonExistentImagesSrcDelayMs}
          >
            <ApolloProvider client={apolloClient}>{props.ready(constants)}</ApolloProvider>
          </ImageExistProvider>
        </PlayerPIIProvider>
      </FirebaseProvider>
    </StateProvider>
  )
}

export { Init, configuration as config }
